<template>
    <BaseModal size="small" status="success">
        <div class="text-left">
            <div class="block w-full">
                <h3 class="text-green text-xl font-bold">{{ $t('Product aanpassen') }}</h3>
            </div>
            
            <div class="block items-center justify-center" v-if="stock.product_tmpl_id">
                <img :src="url + stock.product_tmpl_id[0] +'.png'" class="w-full w-auto m-auto">
            </div>
            <div class="w-full block my-2">
                <label for="model" class="block text-gray-400 w-full mb-2">Model <sup>*</sup></label>
                <select name="extenddepot" id="model" class="border-b-2 border-gray mb-2 pb-1 text-green w-full">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <div class="w-full block my-2">
                <label for="length" class="block text-gray-400 w-full mb-2">Inwendige lengte <sup>*</sup></label>
                <select name="extenddepot" id="length" class="border-b-2 border-gray mb-2 pb-1 text-green w-full">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <div class="w-full block my-2">
                <label for="width" class="block text-gray-400 w-full mb-2">Inwendige breedte <sup>*</sup></label>
                <select name="extenddepot" id="width" class="border-b-2 border-gray mb-2 pb-1 text-green w-full">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <div class="w-full block my-2">
                <label for="interior" class="block text-gray-400 w-full mb-2">Binnenbekleding <sup>*</sup></label>
                <select name="extenddepot" id="interior" class="border-b-2 border-gray mb-2 pb-1 text-green w-full">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
            <div class="w-full block">
                <label for="company" class="block text-gray-400 w-full mb-2">Ondernemer <sup>*</sup></label>
                <input type="text" name="company" id="company" class="border-b-2 border-gray mb-2 pb-1 text-green w-full focus:border-green">
            </div>
            <div class="w-full block">
                <label for="deceased" class="block text-gray-400 w-full mb-2">Overledene <sup>*</sup></label>
                <input type="text" name="deceased" id="deceased" class="border-b-2 border-gray mb-2 pb-1 text-green w-full focus:border-green">
            </div>
            <div class="w-full block">
                <label for="deceased" class="block text-gray-400 w-full mb-2">Dossiernummer</label>
                <input type="text" name="deceased" id="deceased" class="border-b-2 border-gray mb-2 pb-1 text-green w-full focus:border-green">
            </div>
            <div class="w-full flex justify-between my-4">
                <div class="flex items-center">
                    <button type="button" @click="updateQuantity(-1)" :class="isInactive" :disabled="minQuantity" id="minusButton" class="h-4 w-4 text-white rounded-full p-4 flex justify-center items-center cursor-pointer text-xl">-</button>
                    <input v-model="quantity" type="number" id="quantity" name="quantity" min="1" max="10" class="border border-2 border-green rounded-md flex p-2 mx-4 text-center">
                    <button type="button" @click="updateQuantity(1)" id="plusButton" class="h-4 w-4 bg-green text-white rounded-full p-4 flex justify-center items-center cursor-pointer text-lg">+</button>
                </div>
                <div class="flex items-center">
                    <span class="futura-bold">€ 199,<sup>99</sup></span>
                </div>
                <div class="items-center flex">
                    <button type="submit" class="bg-green text-white rounded-md px-8 py-2">Aanvragen <span class="pl-4">+</span></button>
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
export default {
  name: 'OrderRetour',
  components: {BaseModal},
  data: ()=>({
    url: process.env.VUE_APP_STORAGE_URL + '/storage/products/'
  }),
  methods: {
      updateQuantity (quantity) {
          this.quantity += quantity;
      },
      isInactive () {
          return (this.quantity<=1)?'inactive':'bg-green';
      }
  },
  computed: {
    stock(){
      return this.$store.getters.modalData;
    }
  }
}
</script>
