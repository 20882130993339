<template>
  <BaseModal size="small">
      <div class="block items-center justify-center">
        <img :src="url + detail.requestable.product_data_array.product_tmpl_id[0] +'.png'" alt="" class="w-full max-h-36 m-auto object-contain">
      </div>
      <div class="block w-full pb-2 text-left">
        <div class="mt-2 mb-4">
          <p class="text-vw-lightgray pb-1">{{ $t('Unieknummer') }}: {{ detail.requestable.product_data_array.lot_id[1] }}</p>
          <p class="text-vw-green pb-1">{{ detail.requestable.product_data_array.product_tmpl_id[1] }}</p>
          <p class="text-vw-lightgray pb-1">{{ detail.requestable.product_attributes.interior.name }}</p>
        </div>
        <div class="my-2">
          <div class="grid grid-cols-2 gap-2 pb-2">
            <div class="futura-bold">{{ $t('Inwendige lengte:') }}</div>
            <div class="text-vw-green">{{ detail.requestable.product_attributes.length.name }}</div>
          </div>
          <div class="grid grid-cols-2 gap-2">
            <div class="futura-bold">{{ $t('Inwendige breedte:') }}</div>
            <div class="text-vw-green">{{ detail.requestable.product_attributes.width.name }}</div>
          </div>
        </div>
      </div>
      <div class="my-2 py-4 border-t-2 text-left border-vw-bordergray">
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Datum') }}</p>
          <p class="text-vw-green">{{ detail.date }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Klant') }}</p>
          <p class="text-vw-green">{{ detail.requestable.undertaker }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Overledene') }}</p>
          <p class="text-vw-green">{{ detail.requestable.deceased }}</p>
        </div>
        <div class="pb-2">
          <p class="futura-bold mb-1">{{ $t('Dossiernummer') }}</p>
          <p class="text-vw-green">{{ detail.requestable.file_number }}</p>
        </div>
      </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
  name: 'HistoryDetailModal',
  components: { BaseModal },
  data: () => ({
    url: process.env.VUE_APP_STORAGE_URL + '/storage/products/',
  }),
  computed: {
    detail(){
      return this.$store.state.modalStore.data;
    }
  }
}
</script>
