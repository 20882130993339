<template>
  <BaseModal size="small">
    <div class="flex justify-center flex-col items-center">
      <i class="fa-regular fa-circle-check text-vw-green text-4xl mb-4"></i>
      <h3 class="text-xl text-vw-green mb-2 futura-bold">{{ $t('Succesvol') }}</h3>
      <p class="my-2 text-vw-gray">{{ $t('Deze toevoeging is met succes uitgevoerd')}}.</p>
    </div>

  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import {MODAL_ADD_ADDRESS_REQUEST} from "@/constants";

export default {
  name: 'AcceptAddressRequestModal',
  components: {BaseModal},
}
</script>
