<template>
    <input type="checkbox" v-on:change="updateValue($event.target.checked)" :value="value" :checked="checked"
        true-value="true" false-value="false">
</template>

<script>
export default {
    name: 'CheckboxField',
    props: {
        checked: String,
        icon: String,
        value: null,
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
        }
    }
}
</script>
