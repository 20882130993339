<template>
  <BaseModal size="medium">
    <form @submit.prevent="submit">
      <div class="text-xs">
        <div class="block w-full mb-2">
          <h3 class="text-vw-green text-left mb-4 text-2xl futura-bold">
            {{ $t("Afmelden") }}
          </h3>
        </div>

        <div class="block my-4">
          <img
            :src="url + stock.product_tmpl_id[0] + '.png'"
            class="w-1/3 max-h-48 m-auto object-contain"
          />
        </div>

        <div class="block my-4" v-if="referenceProductId">
          <img
            :src="url + referenceProductId + '.png'"
            class="w-1/3 max-h-48 m-auto object-contain"
          />
        </div>

        <div class="grid grid-cols-1 text-left">
          <ProductStockFormRefactor
            ref="product"
            type="deregister"
            @change="status"
          ></ProductStockFormRefactor>
          <!--<ProductStockFormPartV2 ref="product" @change="status"></ProductStockFormPartV2>-->

          <div class="my-1">
            <label for="interior" class="block text-vw-lightgray w-full mb-2">{{
              $t("Ondernemer")
            }}</label>
            <v-select
              v-model="undertaker"
              :options="entrepreneurs"
              :filter="customFilter"
              :getOptionLabel="(entrepreneur) => entrepreneur.option_label"
              @input="setSelected"
              class="text-vw-gray focus:text-vw-green border-b-2 border-vw-modalgray focus:border-vw-green p-1"
            >
            </v-select>
            <p v-if="customerAdded">
              {{ customerAdded }} is toegevoegd en geselecteerd
            </p>
          </div>
          <div class="my-1">
            <label for="interior" class="block text-gray-400 w-full mb-2"
              >{{ $t("Overledene") }} <sup>*</sup></label
            >
            <InputField
              v-model="deceased"
              class="text-vw-gray focus:text-vw-green border-b-2 border-vw-modalgray focus:border-vw-green p-1"
            ></InputField>
          </div>

          <div class="my-1">
            <label for="interior" class="block text-gray-400 w-full mb-2">{{
              $t("Dossiernummer")
            }}</label>
            <InputField
              v-model="file_number"
              class="text-vw-gray focus:text-vw-green border-b-2 border-vw-modalgray focus:border-vw-green p-1"
            ></InputField>
          </div>
        </div>

        <div class="mt-8">
          <button
            type="submit"
            class="bg-vw-green text-white futura-bold rounded-lg px-4 py-2 w-full"
          >
            {{ $t("Afmelden") }}
          </button>
        </div>
      </div>
    </form>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ProductStockFormPart from "@/components/modals/parts/ProductStockFormPart";
import InputField from "@/components/form/InputField";
import ProductStockFormPartV2 from "@/components/modals/parts/ProductStockFormPartV2.vue";
import ProductStockFormRefactor from "@/components/modals/parts/ProductStockFormRefactor.vue";
import {
  LOAD_CLIENTS,
  LOAD_PORTAL_CLIENTS,
  MODAL_ADD_CLIENT,
  MODAL_ADD_CLIENT_REQUEST_,
  STOCK,
  MODAL_DEREGISTER_SUBMITTED,
  OPEN_MODAL,
  OPEN_ADD_CUSTOMER_MODAL,
  REQUEST_DEREGISTER,
  CLIENT_RESET,
} from "@/constants";
export default {
  name: "DeregisterDepot",
  components: {
    InputField,
    ProductStockFormPart,
    BaseModal,
    ProductStockFormPartV2,
    ProductStockFormRefactor,
  },
  data: () => ({
    quantity: 1,
    undertaker: "",
    deceased: "",
    entrepreneur: "",
    file_number: "",
    loaded: false,
    reason: "",
    referenceProductId: null,
    url: process.env.VUE_APP_STORAGE_URL + "/storage/products/",
    defaultOpt: {
      id: "0",
      name: "Voeg een klant toe",
      option_label: "Voeg een klant toe",
      odoo: { display_name: "Voeg een klant toe" },
    },
  }),
  mounted() {
    this.$store.dispatch(LOAD_PORTAL_CLIENTS);
  },
  methods: {
    customFilter(option, query) {
      const filtered = option.filter((i) =>
        i.name.toLowerCase().includes(query.toLowerCase())
      );
      filtered.push(this.defaultOpt);
      return filtered;
    },
    addClientUser() {
      // this.$store.dispatch(OPEN_MODAL, { modal: MODAL_ADD_CLIENT_REQUEST, data: this.$store.getters.modalData });
    },
    addClientAdmin() {
      this.$store.dispatch(OPEN_MODAL, {
        modal: MODAL_ADD_CLIENT,
        data: this.$store.getters.modalData,
      });
    },
    setSelected(value) {
      if (value) {
        if (value.id == 0) {
          this.addClient();
        }
      }
    },
    addClient() {
      this.$store.dispatch(OPEN_ADD_CUSTOMER_MODAL, {
        modal: MODAL_ADD_CLIENT_REQUEST_,
        data: {},
      });
    },
    updateQuantity(quantity) {
      this.quantity += quantity;
    },
    status(state) {
      this.loaded = state;
    },
    submit() {
      if (this.undertaker.id == 0) {
        this.undertaker = this.$store.getters.customerCreatedData;
      }
      console.log('This ref product data values', this.$refs.product);
      let formData = this.$refs.product.selectedProductToDeregisterOrReturn;
      let size =
        this.$store.state.productStore.product_variant_attributes[
          formData.product_id[0]
        ].size;
      formData.size = size;
      let location = formData.location_id[0];
      this.$store.dispatch(REQUEST_DEREGISTER, {
        ...this.$data,
        product: this.$refs.product.selectedProductToDeregisterOrReturn, // stock item
      });
      setTimeout(() => {
        this.$store.dispatch(STOCK, { id: location });
      }, 800);
    },
  },
  computed: {
    stock() {
      console.log("$store getters modalData", this.$store.getters.modalData)
      return this.$store.getters.modalData;
    },
    isInactive() {
      return this.quantity <= 1 ? "inactive" : "bg-vw-green";
    },
    minQuantity() {
      return this.quantity <= 1;
    },
    canSubmit() {
      return this.loaded && this.deceased.length > 3;
    },
    entrepreneurs() {
      var data = this.$store.getters.getPortalClients;
      data.push(this.defaultOpt);
      return Object.values(data);
    },
    customerAdded() {
      this.undertaker = null;
      let clientName = this.$store.getters.customerCreatedData?.name || "";
      this.$store.dispatch(CLIENT_RESET)
      return clientName;
    },
  },
};
</script>
