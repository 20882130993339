<template>
    <BaseModal size="medium" type="stock">
        <form @submit.prevent="submit">
            <div class="text-xs">
                <div class="block w-full mb-2">
                    <h3 class="text-vw-green text-left mb-4 text-2xl futura-bold">{{ $t('Retour melding') }}</h3>
                </div>

                <div class="block my-4" v-if="stock">
                    <img :src="url + stock.product_tmpl_id[0] + '.png'" class="w-full max-h-36 m-auto object-contain">
                </div>

                <div class="block my-4" v-if="referenceProductId">
                    <img :src="url + referenceProductId + '.png'" class="w-full max-h-36 m-auto object-contain">
                </div>

                <div class="grid grid-cols-1 text-left">
                    <ProductStockFormRefactor type="return" ref="product" @change="status"></ProductStockFormRefactor>
                    <!--<ProductStockFormPartV2 ref="product" @change="status"></ProductStockFormPartV2>-->

                    <div class="my-2 text-sm">
                        <label for="reason" class="block text-vw-lightgray w-full mb-2">{{ $t('Reden') }}
                            <sup>*</sup></label>
                        <textarea v-model="reason" name="extenddepot" id="reason" cols="30" rows="3"
                            class="placeholder:text-vw-lightgray text-sm p-0 w-full border-0 border-b-2 border-vw-modalgray"
                            placeholder="Typ hier jouw opmerkingen"></textarea>
                    </div>

                    <div class="my-2 text-sm">
                        <label for="uploads" class="block text-vw-lightgray w-full mb-2">{{ $t('Foto mee sturen') }}</label>
                        <input type="file" multiple @change="uploadFile" ref="file" class="hidden">
                        <div class="grid grid-cols-2 gap-x-4 mt-4 mb-4">
                            <div>
                                <a class="block text-center border border-vw-green text-vw-green bg-[#F4F4F4] px-2 py-3 rounded-lg cursor-pointer"
                                    @click="upload">{{ $t('Bijlage uploaden') }}</a>
                            </div>
                            <div v-if="files" class="text-[#ADACAE] text-left ml-2 flex items-center justify-start">
                                <span>{{ files.length }} {{ $t('bestand(en) geselecteerd') }}</span>
                            </div>
                            <span v-else>{{ $t('Geen bestand gekozen') }}</span>
                        </div>
                    </div>
                </div>

                <div class="my-1">
                    <button type="submit" class="bg-vw-green futura-bold text-white rounded-md px-4 py-2 w-full">
                        {{ $t('Retour melden') }}
                    </button>
                </div>
            </div>
        </form>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ProductStockFormPartV2 from "@/components/modals/parts/ProductStockFormPartV2.vue";
import ProductStockFormRefactor from "@/components/modals/parts/ProductStockFormRefactor.vue";
import {
    CLOSE_MODAL,
    MODAL_RETURN_DEPOT_SUCCESS,
    OPEN_MODAL,
    POST_REQUEST_DEPOT_RETURN,
} from "@/constants";

export default {
    name: 'ReturnDepot',
    components: { BaseModal, ProductStockFormPartV2, ProductStockFormRefactor },
    data: () => ({
        quantity: 1,
        undertaker: '',
        deceased: '',
        file_number: '',
        files: [],
        reason: '',
        referenceProductId: null,
        loaded: false,
        url: process.env.VUE_APP_STORAGE_URL + '/storage/products/',
    }),
    methods: {
        upload() {
            this.$refs.file.click();
        },
        uploadFile() {
            this.files = this.$refs.file.files;
        },
        updateQuantity(quantity) {
            this.quantity += quantity;
        },
        status(state) {
            this.referenceProductId = this.$refs.product.productSelected.id;
            this.loaded = state;
        },
        submit() {
            console.log(this.$refs.product);
            this.$refs.product.selectedProductToDeregisterOrReturn.selectedLength = this.$refs.product.selectedLength;
            this.$refs.product.selectedProductToDeregisterOrReturn.selectedWidth = this.$refs.product.selectedWidth;
            this.$refs.product.selectedProductToDeregisterOrReturn.selectedInterior = this.$refs.product.selectedInterior;
            // return false;
            this.$store.dispatch(POST_REQUEST_DEPOT_RETURN, {
                files: this.files,
                product_id: this.$refs.product.selectedProductToDeregisterOrReturn.id, // stock id
                product_data: JSON.stringify(this.$refs.product.selectedProductToDeregisterOrReturn),
                reason: this.reason
            }).then((res) => {
                this.closeModal();
            });
        },
        closeModal() {
            this.$store.dispatch(CLOSE_MODAL);
            this.$store.dispatch(OPEN_MODAL, MODAL_RETURN_DEPOT_SUCCESS);
        },
    },
    computed: {
        stock() {
            return this.$store.getters.modalData;
        },
        isInactive() {
            return (this.quantity <= 1) ? 'inactive' : 'bg-green';
        },
        minQuantity() {
            return this.quantity <= 1;
        }
    }
}
</script>
