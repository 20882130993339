import axios from "axios";
import {LOAD_CLIENTS} from "@/constants";

const state = {
  clients: [],
};
const getters = {
  getClients: state => state.clients,
};
const actions = {
  [LOAD_CLIENTS]({commit}, payload){
    commit(LOAD_CLIENTS, payload);
  }
};
const mutations = {
  [LOAD_CLIENTS](state, payload){
    axios.get('/clients/all').then((res)=>{
      state.clients = res.data;
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
