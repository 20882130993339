<template>
  <div
    class="z-50 border-4 border-vw-green items-left justify-center rounded-xl shadow-xl text-left px-4 py-2 text-xs absolute top-14 right-0 bg-white max-h-[60vh] overflow-y-scroll">
    <div class="flex flex-row gap-x-2 items-center justify-end">
      <div class="cursor-pointer text-vw-red text-xs mt-2" @click="$emit('clearfilters')">{{ $t('Reset filters') }}</div>
    </div>
    <div class="block text-vw-gray text-sm">
      <form action="">
        <div class="flex items-center justify-between mt-4">
          <span class="futura-bold mr-2">{{ $t('Datum') }}</span>
          <input v-model="startDate" type="date" name="filter" id="startdate" class="border border-[#ADACAE] py-1 px-2">
          <span class="text-gray futura-bold mx-2">-</span>
          <input v-model="endDate" type="date" name="filter" id="enddate" class="border border-[#ADACAE] py-1 px-2">
        </div>

        <div class="block mt-2">
          <label for="depot" class="futura-bold">{{ $t('Depot') }}</label>
          <div class="border border-[#ADACAE] my-2 rounded-sm" id="group">
            <div class="flex flex-row gap-2 items-center justify-start border-b border-[#ADACAE] px-2 py-1">
              <i class="fa-solid fa-magnifying-glass text-vw-green"></i>
              <input type="text" name="depot" id="depot" class="w-full border-none p-0 text-sm py-1">
            </div>
            <div class="border-dashed border-b border-[#ADACAE] px-2 py-1 min-h-[36px]">
              <template v-for="depot in selected_depot">
                <div class="inline-flex px-2 py-1 even:mx-1 rounded-lg gap-2 items-center bg-vw-green text-white">
                  <p>{{ depot }}</p>
                  <i class="fa-solid fa-xmark text-white cursor-pointer"></i>
                </div>
              </template>
            </div>
            <div class="h-20 overflow-y-scroll flex flex-col gap-1 p-2">
              <div v-if="depots.length > 0">
                <div class="flex flex-row gap-2  items-center justify-start" v-if="depots" v-for="depot in depots">
                  <input type="checkbox" v-model="selected_depot[depot]" name="depot" id="depot"
                         class="checked:bg-vw-green border  border-[#ADACAE]">{{ depot.company_name }}
                </div>
              </div>

              <div v-else>
                <div class="flex items-center justify-start">
                  <span>{{ $t('Er zijn geen items om op te filteren')}}.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block mt-2">
          <label for="product" class="futura-bold">{{ $t('Type uitvaartkist') }}</label>
          <div class="border border-[#ADACAE] my-2 rounded-sm" id="group">
            <div class="flex flex-row gap-2 items-center justify-start border-b border-[#ADACAE] px-2 py-1">
              <i class="fa-solid fa-magnifying-glass text-vw-green"></i>
              <input type="text" name="" id="" class="w-full border-none p-0 text-sm py-1">
            </div>
            <div class="border-dashed border-b border-[#ADACAE] px-2 py-1 min-h-[36px]">
              <template v-for="product in selected_product">
                <div class="inline-flex px-2 py-1 even:mx-1 rounded-lg gap-2 items-center bg-vw-green text-white">
                  <p>{{ product }}</p>
                  <i class="fa-solid fa-xmark text-white cursor-pointer"></i>
                </div>
              </template>
            </div>
            <div class="h-20 overflow-y-scroll flex flex-col gap-1 p-2">
              <div v-if="types.length > 0">
                <div class="flex flex-row gap-2  items-center justify-start" v-for="product in types">
                  <input type="checkbox" v-model="selected_product" name="product" id="product" :value="product"
                         class="checked:bg-vw-green border  border-[#ADACAE]">
                  <label :for="product">{{ product }}</label>
                </div>
              </div>

              <div v-else>
                <div class="flex items-center justify-start">
                  <span>{{ $t('Er zijn geen items om op te filteren')}}.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block mt-2">
          <label for="client" class="futura-bold">{{ $t('Klant') }}</label>
          <div class="border border-[#ADACAE] my-2 rounded-sm" id="group">
            <div class="flex flex-row gap-2 items-center justify-start border-b border-[#ADACAE] px-2 py-1">
              <i class="fa-solid fa-magnifying-glass text-vw-green"></i>
              <input type="text" name="" id="" class="w-full border-none p-0 text-sm py-1">
            </div>
            <div class="border-dashed border-b border-[#ADACAE] px-2 py-1 min-h-[36px]">
              <template v-for="client in selected_client">
                <div class="inline-flex px-2 py-1 even:mx-1 rounded-lg gap-2 items-center bg-vw-green text-white">
                  <p>{{ client }}</p>
                  <i class="fa-solid fa-xmark text-white cursor-pointer"></i>
                </div>
              </template>
            </div>
            <div class="h-20 overflow-y-scroll flex flex-col gap-1 p-2">
              <div v-if="clients.length > 0">
                <div class="flex flex-row gap-2  items-center justify-start" v-if="clients" v-for="client in clients">
                  <input type="checkbox" v-model="selected_client[client]" name="client" id="client"
                         class="checked:bg-vw-green border  border-[#ADACAE]">{{ client }}
                </div>
              </div>


              <div v-else>
                <div class="flex items-center justify-start">
                  <span>{{ $t('Er zijn geen items om op te filteren')}}.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {FILTER_INTERIORS, FILTER_LINES} from "@/constants";

export default {
  name: 'FilterDeregister',
  data: () => ({
    startDate: '',
    endDate: '',
    selected_depot: [],
    selected_product: [],
    selected_client: []
  }),
  computed: {
    depots() {
      return this.$store.getters.paginationDepots;
    },
    types() {
      return [];
    },
    clients() {
      return [];
    },
    lines: {
      get() {
        return this.$store.state.productStore.filters.lines;
      },
      set(value) {
        this.$store.dispatch(FILTER_LINES, value);
      }
    },
    interiors: {
      get() {
        return this.$store.state.productStore.filters.interiors;
      },
      set(value) {
        this.$store.dispatch(FILTER_INTERIORS, value);
      }
    },
  }
}
</script>
