<template>
  <BaseModal status="error" size="small">

    <div class="flex justify-center flex-col items-center">
            <div class="border-4 border-vw-red rounded-full h-16 w-16 flex items-center justify-center mb-4">
                <i class="fa-solid fa-xmark text-vw-red text-4xl p-4"></i>
            </div>

            <h3 class="text-xl text-vw-red mb-2 futura-bold">{{ $t('Klant') }}</h3>
            <p class="my-2 text-vw-gray">{{ $t('Weet u zeker dat u deze klant wil verwijderen?')}}</p>
            <a @click="confirm" class="cursor-pointer bg-vw-red border-2 border-vw-red text-white px-8 py-2 rounded-md mt-4">{{ $t('Verwijderen') }}</a>
        </div>

  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import {MODAL_CONFIRM_REMOVE_ADDRESS, MODAL_REMOVE_CLIENT, MODAL_REMOVE_CLIENT_CONFIRM, OPEN_MODAL} from "@/constants";

export default {
  name: 'RemoveClient',
  components: {InputField, BaseModal},
  methods: {
    confirm(){
      this.$store.dispatch(MODAL_REMOVE_CLIENT_CONFIRM);
    }
  }
}
</script>
