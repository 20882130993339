<template>
  <div class="loading__banner z-50 text-2xl text-white fixed opacity-50 bg-vw-green h-screen w-screen flex justify-center items-center" v-show="isLoading">
    <div class="text-center">
      <img src="../assets/img/nav-logo.png" alt="" width="130">
      <br>
      Loading...
    </div>
  </div>
</template>

<script>
export default {
  title: 'Aanvragen',
  computed: {
    isLoading() {
      return this.$store.state.appStore.isLoading;
    },
  }
}
</script>

<style></style>
