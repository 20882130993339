<template>
  <BaseModal size="small">
    <div class="flex justify-center flex-col items-center">
      <i class="fa-regular fa-circle-check text-vw-green text-4xl mb-4"></i>
      <h3 class="text-xl text-vw-green mb-2 futura-bold">{{ $t('Succesvol') }}</h3>
      <p class="my-2 text-vw-gray">{{ $t('Uw aanvraag is succesvol')}}<br>
        {{ $t('Na controle zal het adres in uw adressenlijst verschijnen') }}</p>
      <a :href="'/settings'" class="bg-vw-green px-8 py-2 text-white cursor-pointer mt-4 rounded-lg futura-bold" @click="closeModal">{{ $t('Terug naar instellingen') }}</a>
    </div>

  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import {MODAL_CONFIRM_REMOVE_ADDRESS, OPEN_MODAL, CLOSE_MODAL} from "@/constants";

export default {
  name: 'AddAddressRequestCompleteModal',
  components: {BaseModal},
  methods: {
    closeModal(){
      this.$store.dispatch(CLOSE_MODAL);
    }
  }
}
</script>
