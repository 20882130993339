<template>
    <BaseModal size="account">
        <div class="text-left">
            <div class="text-center">
                <div class="block w-full mb-2">
                    <h3 class="text-vw-green text-left mb-4 text-2xl futura-bold">{{ $t('Reset wachtwoord e -
                                            mailbevestiging') }}
                    </h3>
                </div>
                <div class="flex mt-2">
                    <p class="my-2 text-vw-gray">{{ $t('Weet u zeker dat u e-mail wilt verzenden?') }}</p>
                </div>
                <div class="items-center flex justify-end my-1">


                    <button
                        class="bg-vw-btngray text-vw-green rounded-full flex justify-center items-center px-8 py-2 uppercase futura-bold mr-3"
                        @click="closeModal2">
                        <i class="fa-sharp fa-solid text-vw-green"></i> {{ $t('Nee') }}
                    </button><br>
                    <button
                        class="bg-vw-btngray text-vw-green rounded-full flex justify-center items-center px-8 py-2 uppercase futura-bold"
                        @click="sendReset">
                        <i class="fa-sharp fa-solid text-vw-green"></i> {{ $t('Ja') }}
                    </button>
                </div>
            </div>

        </div>
    </BaseModal>
</template>
  
<script>
import { CLOSE_MODAL, PASSWORD_RESET_INTERNAL, MODAL_EDIT_ACCOUNT, OPEN_MODAL } from "@/constants";
import BaseModal from "@/components/modals/BaseModal";

export default {
    name: 'ConfirmationModal',
    components: { BaseModal },
    props: {
        data: null,
    },
    methods: {
        closeModal2() {
            this.$store.dispatch(CLOSE_MODAL);
            setTimeout(() => {
                this.openPreviousModal();
            }, 500);
        },
        sendReset() {
            let email = localStorage.getItem('email');
            this.$store.dispatch(PASSWORD_RESET_INTERNAL, email);
            this.closeModal2();
        },
        openPreviousModal() {
            let account = localStorage.getItem('account');
            this.$store.dispatch(OPEN_MODAL, { modal: MODAL_EDIT_ACCOUNT, data: account });
        }
    }
}
</script>
  