import Vue from 'vue'
import Vuex from 'vuex'
import appStore from "@/store/modules/appStore";
import userStore from "@/store/modules/userStore";
import requestStore from "@/store/modules/requestStore";
import companyStore from "@/store/modules/companyStore";
import modalStore from "@/store/modules/modalStore";
import productStore from "@/store/modules/productStore";
import stockStore from "@/store/modules/stockStore";
import cartStore from "@/store/modules/cartStore";
import clientStore from "@/store/modules/clientStore";
import VuexPersistence from "vuex-persist";
import extensionRequestStore from "@/store/modules/extensionRequestStore";
import returnRequestStore from "@/store/modules/returnRequestStore";
import orderStore from "@/store/modules/orderStore";
import ticketStore from "@/store/modules/ticketStore";
import paginationStore from "@/store/modules/paginationStore";
import depotStore from "@/store/modules/depotStore";
import portalClientStore from "@/store/modules/portalClientStore";

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersistence({
  key: 'vanwijk-cart', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  modules: ['cartStore'],
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    appStore,
    userStore,
    requestStore,
    companyStore,
    clientStore,
    modalStore,
    ticketStore,
    productStore,
    stockStore,
    cartStore,
    extensionRequestStore,
    returnRequestStore,
    orderStore,
    paginationStore,
    depotStore,
    portalClientStore
  }
})
