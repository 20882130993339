import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './mixins/titleMixin'
import companyInformation from './mixins/companyInformation'
import LoginNav from './components/LoginNav'
import './assets/css/index.css'
import 'vue-select/dist/vue-select.css';
import i18n from './i18n'
import vSelect from 'vue-select'
import axios from "axios";
import { IS_ERROR, IS_LOADING, LOGOUT_USER, REFRESH_USER } from "@/constants";
import permissions from "@/mixins/permissions";
import filterSizes from "@/mixins/filterSizes";
import formatNumber from "@/mixins/formatNumber";
import 'flowbite';

Vue.config.productionTip = false

/** @TODO: On refresh user terug invullen in userstore **/

Vue.mixin(permissions)
Vue.mixin(titleMixin)
Vue.mixin(filterSizes)
Vue.mixin(formatNumber)
Vue.use(companyInformation)
Vue.component('LoginNav', LoginNav)
Vue.component('v-select', vSelect)

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

new Vue({
    i18n,
    router,
    store,
    data: () => ({
        numberOfAjaxCAllPending: 0,
    }),
    mounted() {
        this.enableInterceptor();
    },
    created() {
        if (localStorage.getItem('vanwijk-jwt')) {
            this.$store.dispatch(REFRESH_USER);
        }
        if (localStorage.getItem('vanwijk-jwt-admin')) {
            this.$store.state.userStore.logged_in_as = true;
        }
    },
    methods: {
        enableInterceptor() {
            this.axiosInterceptor = axios.interceptors.request.use((config) => {
                this.numberOfAjaxCAllPending++;
                store.dispatch('appStore/' + IS_LOADING, true, { root: true });
                return config
            }, (error) => {
                this.numberOfAjaxCAllPending--;
                return Promise.reject(error)
            })

            axios.interceptors.response.use((response) => {

                this.numberOfAjaxCAllPending--;

                if (this.numberOfAjaxCAllPending == 0) {
                    store.dispatch('appStore/' + IS_LOADING, false, { root: true });
                }

                return response
            }, (error) => {

                this.numberOfAjaxCAllPending--;

                if (this.numberOfAjaxCAllPending == 0) {
                    store.dispatch('appStore/' + IS_LOADING, false, { root: true });
                }

                // If we have a 401
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }

                if (error.response?.status === 421) {

                    store.dispatch('appStore/' + IS_ERROR, error.response.data, { root: true });
                    store.dispatch('appStore/' + IS_LOADING, false, { root: true });
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                          this.$router.push('/login');
                      }).catch(()=> {
                            this.$router.push('/login');
                        });
                      }
                }

                if (error.response?.status === 422) {
                    store.dispatch('appStore/' + IS_ERROR, error.response.data, { root: true });
                    store.dispatch('appStore/' + IS_LOADING, false, { root: true });
                }

                return Promise.reject(error)

            })
        },

        disableInterceptor() {
            axios.interceptors.request.eject(this.axiosInterceptor)
        },
    },
    render: h => h(App)
}).$mount('#app')
