<template>
  <BaseModal size="small" status="success">
    <div class="flex justify-center flex-col items-center">
      <i class="fa-regular fa-circle-check text-vw-green text-4xl mb-4"></i>
      <h3 class="text-xl text-vw-green mb-2 futura-bold">{{ $t('Succesvol') }}</h3>
      <p class="my-2 text-vw-gray">{{ $t('Uw afmelding is succesvol')}}</p>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import {CLOSE_MODAL, MODAL_CONFIRM_REMOVE_ADDRESS, OPEN_MODAL} from "@/constants";

export default {
  name: 'DeregisterSubmitted',
  components: {BaseModal},
  methods: {
    closeModal(){
      this.$store.dispatch(CLOSE_MODAL);
    }
  }
}
</script>
