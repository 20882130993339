export default {
  methods: {
    filterSizes(variants){
      if( ! variants ) return;

      let grouped_variants = [];
      variants.forEach((variant)=>{
        if( typeof  this.sizes[variant.id] !="undefined" ) {

          if( typeof grouped_variants[this.sizes[variant.id].value.key] == 'undefined' ) {
            grouped_variants[this.sizes[variant.id].value.key] = [];
          }

          grouped_variants[this.sizes[variant.id].value.key].push( {
            'size': this.sizes[variant.id],
            'variant': variant
          } );
        }
      });

      return grouped_variants;
    }
  }
}
