const COMPANY_INFORMATION = {
  COMPANY_TITLE: "Van Wijk Uitvaartkisten",
  COMPANY_ADDRESS_LINE_1: 'Poolvosweg 107',
  COMPANY_ADDRESS_LINE_2: '2901 BM, Capelle aan den IJssel',
  COMPANY_PHONE: '010- 450 2333',
  COMPANY_EMAIL: 'info@vanwijkuitvaartkisten.nl',
  STORAGE_URL: 'http://apiodoo.test.crebos.online/storage',
  STORAGE_LINK: 'http://apiodoo.test.crebos.online/storage',
}

let CompanyInformation = {};

CompanyInformation.install = function (Vue, options) {
  Vue.prototype.$getConst = (key) => {
    return COMPANY_INFORMATION[key]
  }
}

export default CompanyInformation
