import axios from "axios";
import { FILTER_SET_PAGE_DEREGISTER, PAGINATION_SET_PAGE, PAGINATION_SET_STORE, PAGINATION_SET_DISPLAY_COUNT } from "@/constants";

// Deregister Requests
const _filterData = (state, getters) => {
    if (getters.requestDeregisters && state.store === 'deregister') { // Request Deregisters
        return getters.requestDeregisters;
    }
    if (getters.deregistersHistory && state.store === 'history') { // Request Deregisters
        return getters.deregistersHistory;
    }
    if (getters.requestOrders && state.store === 'requestOrders') { // Request Orders
        return getters.requestOrders;
    }
    if (getters.returnRequest && state.store === 'requestReturn') { // Request Returns
        return getters.returnRequest;
    }
    if (getters.extensionRequest && state.store === 'extension') { // Request Extensions
        return getters.extensionRequest;
    }
    if (getters.requestClients && state.store === 'connections') { // Request Clients
        return getters.requestClients;
    }
    if (getters.getClients && state.store === 'clients') { // Clients List
        return getters.getClients;
    }
    if (getters.tickets && state.store === 'tickets') { // Clients List
        return getters.tickets;
    }
    if (getters.getAdmins && state.store === 'users') { // Clients List
        return getters.getAdmins;
    }
    if (getters.getDepots && state.store === 'depots') {
        return getters.getDepots;
    }
    if (getters.getStockListByTemplate && state.store === 'stockList') {
        //return getters.getStockList;
        return getters.getStockListByTemplate;
    }
}

const state = {
    store: null,
    filters: {
        search: '',
        query: '',
        sort: 'asc',
        cat_ids: [],
        lines: [],
        display: 20,
        page: 1,
    }
};
const getters = {
    store: state => state.store,
    filterData: (state, getters) => {
        state.result = _filterData(state, getters, state.store);
        return state.result;
    },
    displayData: (state, getters) => {

        if (_filterData(state, getters)) {
            return _filterData(state, getters).filter((data, index) => {
                let from = (state.filters.page - 1) * state.filters.display;
                let until = (state.filters.page) * state.filters.display - 1;

                if ((index) >= from && (index) <= until) {
                    return true;
                }
            });
        }

        return [];
    },
    paginationTypes: (state, getters) => {
    },
    paginationClients: (state, getters) => {
    },
    paginationDepots: (state, getters) => {
        let depots = [];
        if (getters.filterData) {
            getters.filterData.forEach((request) => {
                depots.push(request.user); // depot
            });
        }

        // return unique depots
        return depots.filter((depot, index, self) => {
            return self.findIndex((t) => {
                if (typeof t === 'undefined') return false;
                if (typeof depot === 'undefined') return false;
                return t.id === depot.id
            }) === index;
        });
    },
};
const actions = {
    [PAGINATION_SET_PAGE]({ commit }, payload) {
        commit(PAGINATION_SET_PAGE, payload);
    },
    [PAGINATION_SET_STORE]({ commit }, payload) {
        commit(PAGINATION_SET_STORE, payload);
    },
    [PAGINATION_SET_DISPLAY_COUNT]({ commit }, payload) {
        commit(PAGINATION_SET_DISPLAY_COUNT, payload);
    }
};
const mutations = {
    [PAGINATION_SET_PAGE](state, payload) {
        state.filters.page = payload.page;
    },
    [PAGINATION_SET_STORE](state, payload) {
        state.store = null;
        state.store = payload.store;
        console.log(state.store, 'setStore');
    },
    [PAGINATION_SET_DISPLAY_COUNT](state, payload) {
        state.filters.display = payload.displayCount;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
