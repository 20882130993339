<template>
  <BaseModal size="medium">
    <div class="text-left">
      <div class="grid md:flex justify-between flex-row mb-4">
        <h2 class="text-2xl text-vw-gray futura-bold mt-6">{{ $t('Adres toevoegen') }}</h2>

        <div class="flex flex-row mt-6">
          <span class="text-vw-gray text-sm mr-4">{{ $t('Adres opslaan in lijst') }}</span>
          <span>
            <label class="toggle" for="saveAddress">
              <input type="checkbox" name="saveAddress" id="saveAddress" checked>
              <span class="slider"></span>
            </label>
          </span>
        </div>
      </div>

      <div v-if="errors">
        <p class="text-vw-red">{{$t('Er ging iets mis bij het verwerken van de ingevulde informatie. Contacteer de systeembeheerder en probeer later opnieuw.')}}</p>
      </div>

      <form @submit.prevent="add">
        <div class="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-9 xl:grid-cols-12 md:gap-2 xl:gap-6 mt-8 text-sm">
          <div class="col-span-6 md:col-span-3 lg:col-span-5 xl:col-span-6 my-2">
            <label class="text-vw-lightgray text-sm mb-2">{{ $t('Adresnaam') }}</label>
            <InputField v-model="address.name"
              class="w-full border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></InputField>
          </div>

          <div class="col-span-6 md:col-span-3 lg:col-span-4 xl:col-span-6 my-2">
            <label class="text-vw-lightgray text-sm mb-2">{{ $t('Straatnaam + huisnummer') }}</label>
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-4">
                <InputField v-model="address.address_line"
                  class="w-full border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></InputField>
              </div>
              <div class="col-span-2">
                <InputField v-model="address.number"
                  class="w-full border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></InputField>
              </div>
            </div>
          </div>

          <div class="col-span-4 md:col-span-2 lg:col-span-5 xl:col-span-6 my-2">
            <label class="text-vw-lightgray text-sm mb-2">{{ $t('Plaats') }}</label>
            <InputField v-model="address.city"
                        class="w-full border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></InputField>
          </div>

          <div class="col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-3 my-2">
            <label class="text-vw-lightgray text-sm mb-2">{{ $t('Postcode') }}</label>
            <InputField v-model="address.postcode"
                        class="w-full border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></InputField>
          </div>
          <div class="col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-3 my-2 flex">
            <button type="submit" class="rounded-xl bg-vw-green text-white py-4 futura-bold w-full">{{
                $t('Toevoegen') }} <i class="fa-solid fa-plus text-white ml-2"></i></button>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-6 gap-0 md:gap-2 mt-8 mb-4 text-sm">


        </div>
      </form>
    </div>

  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import { MODAL_ADD_ADDRESS_REQUEST } from "@/constants";

export default {
  name: 'AddAddressRequestModal',
  components: { InputField, BaseModal },
  data: () => ({
    errors: null,
    address: {
      company_id: null,
      name: null,
      address_line: null,
      number: null,
      city: null,
      postcode: null,
    }
  }),
  computed: {
    userId(){
      if( this.$route.params.id ){
        return this.$route.params.id;
      }
      if( this.$store.state.userStore.user ){
        return this.$store.state.userStore.user.id;
      }

      return null;
    }
  },
  methods: {
    add() {
      this.$store.dispatch(MODAL_ADD_ADDRESS_REQUEST, {...this.address, company_id: this.userId }).then(()=>{
        // this.$store.dispatch('LOAD_SETTINGS', { userId: this.userId });
      }).catch((err) => {
        this.errors = err.response.data;
        setTimeout(() => { this.errors = null }, 3000);
      });
    }
  }
}
</script>
