<template>
  <BaseModal size="medium">
    <div class="text-left">
      <h2 class="text-2xl futura-bold">{{ $t('Aanvraag door') }}</h2>

      <div class="grid md:grid-cols-2 gap-2 md:gap-4 my-4">
        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">Onderneming</label>
          <InputField :value="addressRequest.user.company_name" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>
        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">Naam</label>
          <InputField :value="addressRequest.user.first_name + ' ' + addressRequest.user.last_name"
            class="text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>

        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">E-mail</label>
          <InputField :value="addressRequest.user.email" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>
        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">Telefoonnummer</label>
          <InputField :value="addressRequest.user.phone" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>
      </div>

      <h2 class="text-2xl futura-bold">{{ $t('Adres toevoegen') }}</h2>

      <div class="grid md:grid-cols-4 gap-2 md:gap-4 my-4">
        <div class="my-1 md:col-span-2">
          <label for="" class="text-vw-lightgray mb-1">{{ $t('Adresnaam') }}</label>
          <InputField :value="addressRequest.requestable.name" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>
        <div class="my-1 md:col-span-2">
          <label for="" class="text-vw-lightgray mb-1">{{ $t('Straatnaam + huisnummer') }}</label>
          <div class="grid grid-cols-4 gap-2 md:gap-4">
            <InputField class="col-span-3 text-vw-green border-b-2 border-vw-bordergray" :value="addressRequest.requestable.address_line"></InputField>
            <InputField class= "text-vw-green border-b-2 border-vw-bordergray" :value="addressRequest.requestable.number"></InputField>
          </div>
        </div>

        <div class="my-1 md:col-span-2">
          <label for="" class="text-vw-lightgray mb-1">{{ $t('Plaats') }}</label>
          <InputField :value="addressRequest.requestable.city" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>

        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">{{ $t('Postcode') }}</label>
          <InputField :value="addressRequest.requestable.postcode" class= "text-vw-green border-b-2 border-vw-bordergray"></InputField>
        </div>
      </div>

      <div class="grid md:grid-cols-2 gap-2 md:gap-4 my-4">
        <div class="my-1">
          <label for="" class="text-vw-lightgray mb-1">{{ $t('Odoo contactnaam' )}}</label>
          <v-select v-if="odoo.addresses" v-model="odoo_select_address" label="name" :options="odoo.addresses" class=""></v-select>

        <!--  <div class="flex justify-between my-2 text-vw-lightgray">
            <a class="hover:text-vw-green cursor-pointer underline" @click="refresh">{{ $t('Vernieuw de lijst') }}</a>
            <a :href="odoo_create_url+'/web#cids=1&menu_id=292&action=442&model=res.partner&view_type=form'"
              class="hover:text-vw-green cursor-pointer underline" target="_blank">{{ $t('Maak een nieuw contact aan') }}</a>
          </div> -->
        </div>

        <div class="flex justify-end flex-col w-full">
          <div class="grid grid-cols-2 gap-2 lg:gap-4 xl:gap-6 justify-between items-end">
            <button class="text-vw-red border-2 border-vw-red py-2 rounded-lg" @click.stop="refuse(addressRequest)">
              {{ $t('Weigeren') }}
              <i class="fa-regular fa-trash-can text-vw-red ml-2"></i>
            </button>
            <button class="bg-vw-green border-2 border-vw-green text-white py-2 rounded-lg" @click.stop="accept(addressRequest)">
              {{ $t('Toevoegen') }}
              <i class="fa-regular fa-plus text-white ml-2"></i>
            </button>
          </div>
        </div>
      </div>

    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import axios from "axios";
import { MODAL_REFUSE_ADDRESS_REQUEST, MODAL_ACCEPT_ADDRESS_REQUEST, OPEN_MODAL, IS_ERROR } from "@/constants";

export default {
  name: 'HandleAddressRequest',
  components: { InputField, BaseModal },
  data: () => ({
    odoo_select_address: null,
    odoo: {
      addresses: null,
    },
    odoo_create_url: process.env.VUE_APP_ODOO_URL
  }),
  mounted() {
    this.loadAddresses();
  },
  methods: {
    loadAddresses(refresh = false) {
      axios.post('/odoo/company/addresses' + ((refresh) ? '?fresh' : '')).then((res) => {
        this.odoo.addresses = Object.values(res.data);
      }).catch((error) => {
        if (error.response?.status === 401) {
          if (this.$route.path !== '/login') {
            store.dispatch(LOGOUT_USER, {}).then(() => {
                this.$router.push('/login');
            });
          }
        }
      });
    },
    refresh() {
      this.loadAddresses(true);
    },
    refuse(address) {
      this.$store.dispatch(OPEN_MODAL, { modal: MODAL_REFUSE_ADDRESS_REQUEST, data: address })
    },
    accept(address) {
      if (this.odoo_select_address == null) {
        //alert( this.$t('modal.please_select_contact_address') );
        this.$store.dispatch('appStore/' + IS_ERROR, {
          error: {
            bag: [],
            message: this.$t('Gelieve een gekoppeld Odoo adres aan te duiden.')
          }
        });
      } else {
        this.$store.dispatch(MODAL_ACCEPT_ADDRESS_REQUEST, { data: address, address_id: this.odoo_select_address.id });
      }
    }
  },
  computed: {
    addressRequest() {
      return this.$store.getters.modalData;
    }
  }
}
</script>
