import {
  CART_ADD, CART_ORDER,
  CART_REMOVE, CART_REORDER, CART_SET_NOTE,
  CART_UPDATE, IS_LOADING, ORDER_LOAD, RESET_SHIPPING,
  SHIPPING_OPTIONS,
  SHIPPING_SELECT_DELIVERY,
  SHIPPING_SELECT_DELIVERY_ADDRESS,
  SHIPPING_SELECT_PICKUP_DATE,
  SHIPPING_SELECT_PICKUP_HOUR,
  SHIPPING_SELECT_PICKUP_LOCATION
} from "@/constants";
import axios from "axios";

let calculate_totals = function(state, getters){
  let total = 0;
  let vat = 0;
  state.items.map((item)=>{
    total += item.quantity * item.priceSingle;
    if( getters.taxes ){
      let tax = getters.taxes.filter((tax) => {
        return tax.id == item.variant.taxes_id[0];
      });

      tax = tax[0];

      if( tax.amount > 0 ){
        let _tax = (tax.amount / 100);
        vat += total * _tax;
      }
    }
  });

  state.shipping_cost = 0;

  if( state.delivery_selected ){
    if( state.delivery_selected.cost ){
      state.shipping_cost = state.delivery_selected.cost;
    }
  }
  if( state.pickup_hour_selected ){
    if( state.pickup_hour_selected.price > 0 ){
      state.shipping_cost = state.pickup_hour_selected.price;
    }
  }

  state.total = total;
  state.vat = vat;
  state.totalVat = state.total + state.vat + state.shipping_cost;
}

let resetShipping = function(state){
  state.pickup = null;
  state.pickup_selected = null;
  state.pickup_location_selected = null;
  state.pickup_date_selected = null;
  state.pickup_hour_selected = null;
  state.delivery_address_selected = null;
  state.delivery_selected = null;
  state.delivery_hour_selected = null;
  state.shipping_cost = 0;
  state.totalVat = 0;
  state.notes='';
}

const state = {
  items: [],
  discount: null,
  shipping: null,
  shipping_cost: 0,
  delivery: null,
  delivery_address_selected: null,
  delivery_selected: null,
  pickup: null,
  pickup_selected: null,
  pickup_location_selected: null,
  pickup_date_selected: null,
  pickup_hour_selected: null,
  delivery_hour_selected: null,
  total: null, // Without vat?
  totalVat: null,
  vat: null,
  notes: '',
};
const getters = {
  items: state => (state.items) ? state.items : null,
  productIds: (state) => {
    let product_ids = [];
    if( state.items ){
      state.items.map((item) => {
        product_ids.push( item.variant.id );
      });
    }
    return product_ids;
  },
  totalVat: state => (state.totalVat) ? state.totalVat: 0,
  vat: state => (state.vat) ? state.vat: 0,
  total: state => (state.total) ? state.total: 0,
  hasShipping: (state) => {
    if( state.delivery_selected && state.delivery_address_selected ){
      return 'delivery';
    }
    if( state.pickup_hour_selected && state.pickup_date_selected && state.pickup_location_selected ){
      return 'pickup';
    }

    return false;
  }
};

const actions = {
  [CART_SET_NOTE]({commit}, payload){
    commit(CART_SET_NOTE, payload);
  },
  [CART_ADD]({commit, getters}, payload){
    commit(CART_ADD, {payload, getters});
  },
  [CART_REMOVE]({commit, getters}, payload){
    commit(CART_REMOVE, {payload, getters});
  },
  [CART_UPDATE]({commit, getters}, payload){
    commit(CART_UPDATE, {payload, getters});
  },
  [CART_REORDER]({commit, getters}, payload){
    commit(CART_REORDER, {payload, getters});
  },
  [SHIPPING_OPTIONS]({commit, getters}, payload){
    axios.post('/odoo/shipping/' + payload, {
      product_ids: getters.productIds,
      total_quantity: getters.items.length,
    }).then((res)=>{
      commit(SHIPPING_OPTIONS, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [SHIPPING_SELECT_DELIVERY]({commit, getters}, payload){
    commit(SHIPPING_SELECT_DELIVERY, {payload, getters});
  },
  [SHIPPING_SELECT_DELIVERY_ADDRESS]({commit, getters}, payload){
    commit(SHIPPING_SELECT_DELIVERY_ADDRESS, {payload, getters});
  },
  [SHIPPING_SELECT_PICKUP_HOUR]({commit, getters}, payload){
    commit(SHIPPING_SELECT_PICKUP_HOUR, {payload, getters});
  },
  [SHIPPING_SELECT_PICKUP_LOCATION]({commit, getters}, payload){
    commit(SHIPPING_SELECT_PICKUP_LOCATION, {payload, getters});
  },
  [SHIPPING_SELECT_PICKUP_DATE]({commit, getters}, payload){
    commit(SHIPPING_SELECT_PICKUP_DATE, {payload, getters});
  },
  [CART_ORDER]({commit, state, dispatch}){
    axios.post('shop/order', {
      order: {
        items: state.items,
      },
      shipping: {
          delivery: {
            location: state.delivery_address_selected,
            option: state.delivery_selected,
          },
          pickup: {
            location: state.pickup_location_selected,
            date: state.pickup_date_selected,
            hour: state.pickup_hour_selected
          }
      },
      note: state.notes
    }).then((res)=>{
      dispatch(ORDER_LOAD, res.data.requestable_id);
      commit(CART_ORDER);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [RESET_SHIPPING]({commit}){
    commit(RESET_SHIPPING);
  }
};
const mutations = {
  [CART_SET_NOTE](state, payload){
    state.notes = payload;
  },
  [CART_ADD](state, {payload, getters}){
    state.items.push(payload);

    calculate_totals(state, getters);
  },
  [CART_UPDATE](state, {payload, getters}){
    state.items[payload.key].quantity += payload.quantity;

    calculate_totals(state, getters);
  },
  [CART_REMOVE](state, {payload, getters}){
    state.items.splice(payload,1);
    resetShipping(state);
    calculate_totals(state, getters);
  },
  [CART_REORDER](state, {payload, getters}){
    state.items = payload;
    resetShipping(state);
    calculate_totals(state, getters);
  },
  [SHIPPING_OPTIONS](state, payload){
    state.shipping = payload;
  },
  [SHIPPING_SELECT_DELIVERY](state, {payload, getters}){
    state.delivery_selected = payload;
    calculate_totals(state, getters);
  },
  [SHIPPING_SELECT_DELIVERY_ADDRESS](state, {payload, getters}){
    state.delivery_address_selected = payload;
    calculate_totals(state, getters);
  },
  [SHIPPING_SELECT_PICKUP_HOUR](state, {payload, getters}){
    state.pickup_hour_selected = payload;
    calculate_totals(state, getters);
  },
  [SHIPPING_SELECT_PICKUP_LOCATION](state, {payload, getters}){
    state.pickup_location_selected = payload;
    calculate_totals(state, getters);
  },
  [SHIPPING_SELECT_PICKUP_DATE](state, {payload, getters}){
    state.pickup_date_selected = payload;
    calculate_totals(state, getters);
  },
  [CART_ORDER](state){
    state.items = [];
    resetShipping(state);
    calculate_totals(state, getters);
  },
  [RESET_SHIPPING](state){
    resetShipping(state);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
