<template>
    <div class="grid grid-cols-1 text-left">

        <div class="my-1" v-if="!this.productcatalog">
            <label for="model" class="block text-vw-lightgray w-full mb-2">Model <sup>*</sup> <span
                    class="text-red-600 cursor-pointer" @click="resetSelector">({{ $t('Selecteer ander
                                        product')}})</span></label>
            <select :disabled="fieldLock" @change="reloadProductAttributes(product)" name="product" id="product"
                v-model="product"
                class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-sm text-vw-green w-full p-1">
                <option v-for="product in filteredProducts" :value="product">{{ product.product_id[1] }}</option>
            </select>
        </div>

        <div class="my-1" v-if="this.productcatalog">
            <label for="model" class="block text-vw-lightgray w-full mb-2">Model <sup>*</sup> <span
                    class="text-red-600 cursor-pointer" @click="resetSelector">({{ $t('Selecteer ander
                                        product')}})</span></label>
            <select :disabled="fieldLock" @change="reloadTemplateProductAttributes(templateProduct)" name="product"
                id="product" v-model="templateProduct"
                class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-sm text-vw-green w-full p-1">
                <option v-for="product in filteredTemplateProductsVariants" :value="product">{{ product.partner_ref }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="attributesLength && !fieldLock">
            <label for="length" class="block text-vw-lightgray w-full mb-2">Inwendige lengte <sup>*</sup></label>
            <select v-if="attributesLength" name="length" id="length" v-model="length"
                class="border-0 border-b-2 border-vw-modalgray text-sm mb-2 p-1 text-vw-green w-full">
                <option v-for="length, index in attributesLength" :value="length" :selected="index == 0">
                    {{
                        length.display_name
                    }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="fieldLock">
            <label for="length" class="block text-vw-lightgray w-full mb-2">{{ $t('Inwendige lengte') }}<sup>*</sup></label>
            <select :disabled="fieldLock" v-if="attributes" name="length" id="length"
                class="border-0 border-b-2 border-vw-modalgray text-sm mb-2 p-1 text-vw-green w-full">
                <option v-for="length, index in attributes[PRODUCT_ATTRIBUTE_LENGTH_ID]" :value="length"
                    :selected="length.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1">
                    {{
                        length.display_name
                    }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="attributesWidth && !fieldLock">
            <label for="width" class="block text-vw-lightgray w-full mb-2">{{ $t('Inwendige breedte') }}
                <sup>*</sup></label>
            <select v-if="attributesWidth" name="width" id="width" v-model="width"
                class="border-0 border-b-2 border-vw-modalgray mb-2 p-1 text-sm text-vw-green w-full">
                <option v-for="width in attributesWidth" :value="width">{{
                    width.display_name
                }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="fieldLock">
            <label for="width" class="block text-vw-lightgray w-full mb-2">{{ $t('Inwendige breedte') }}<sup>*</sup></label>
            <select :disabled="fieldLock" v-if="attributes" name="width" id="width"
                class="border-0 border-b-2 border-vw-modalgray text-sm mb-2 p-1 text-vw-green w-full">
                <option v-for="width, index in attributes[PRODUCT_ATTRIBUTE_WIDTH_ID]" :value="width"
                    :selected="width.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1">
                    {{
                        width.display_name
                    }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="attributesInterior && !fieldLock">
            <label for="interior" class="block text-vw-lightgray w-full mb-2">{{ $t('Binnenbekleding') }}
                <sup>*</sup></label>
            <select v-if="attributesInterior" name="interior" id="interior" v-model="interior"
                class="border-0 border-b-2 border-vw-modalgray mb-2 text-sm p-1 text-vw-green w-full">
                <option v-for="interior in attributesInterior" :value="interior">
                    {{ interior.display_name }}
                </option>
            </select>
        </div>

        <div class="my-1" v-if="fieldLock">
            <label for="interior" class="block text-vw-lightgray w-full mb-2">{{ $t('Binnenbekleding')
            }}<sup>*</sup></label>
            <!-- v-model="interior" -->
            <select :disabled="fieldLock" v-if="attributes" name="interior" id="interior"
                class="border-0 border-b-2 border-vw-modalgray text-sm mb-2 p-1 text-vw-green w-full">
                <option v-for="interior, index in attributes[PRODUCT_ATTRIBUTE_INTERIOR_ID]" :value="interior"
                    :selected="interior.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1">
                    {{
                        interior.display_name
                    }}
                </option>
            </select>
        </div>

        <div class="my-1" v-show="this.$props.lotid != 'false'">
            <label for="model" class="block text-vw-lightgray w-full mb-2">{{ $t('Unieknummer') }} <sup>*</sup></label>
            <input v-model="lot_id" readonly="true"
                class="border-0 border-b-2 border-vw-modalgray mb-2 p-1 text-sm text-vw-green w-full" />
        </div>
    </div>
</template>

<script>
import {
    PRODUCT_ATTRIBUTE_INTERIOR_ID,
    PRODUCT_LOAD,
    PRODUCT_VARIANT_ATTRIBUTES,
    PRODUCT_ATTRIBUTE_LENGTH_ID,
    PRODUCT_ATTRIBUTE_WIDTH_ID,
    OPEN_MODAL,
    MODAL_DEREGISTER_DEPOT,
    MODAL_RETURN_DEPOT,
    CLOSE_MODAL,
    CHANGE_MODAL_DATA,
    PRODUCTS_LOAD
} from "@/constants";
import InputField from "@/components/form/InputField";

export default {
    name: 'ProductStockFormPartV2',
    components: { InputField },
    prop: [
        'product',
        'width',
        'length',
        'interior',
        'lotid',
    ],
    props: {
        lotid: String,
        productcatalog: Boolean,
    },
    data: () => ({
        product: null,
        templateProduct: null,
        PRODUCT_ATTRIBUTE_WIDTH_ID: PRODUCT_ATTRIBUTE_WIDTH_ID,
        PRODUCT_ATTRIBUTE_LENGTH_ID: PRODUCT_ATTRIBUTE_LENGTH_ID,
        PRODUCT_ATTRIBUTE_INTERIOR_ID: PRODUCT_ATTRIBUTE_INTERIOR_ID,
        length: null,
        width: null,
        interior: null,
        lot_id: null,
        loaded: false,
        fieldLock: false,
    }),
    mounted() {
        this.fieldLock = false;
        this.loadProduct();
        if (this.productcatalog) {
            this.$store.dispatch(PRODUCT_VARIANT_ATTRIBUTES, {
                variants: this.$store.getters.product_variant_ids
            });
        } else {
            this.$store.dispatch(PRODUCT_VARIANT_ATTRIBUTES, {
                variants: this.variants
            });
        }

    },
    methods: {
        resetSelector() {
            let currentModal = this.$store.getters.activeModal;
            this.$store.dispatch(CLOSE_MODAL);
            setTimeout(() => {
                this.$store.dispatch(OPEN_MODAL, currentModal);
            }, 500);
        },
        async loadProduct() {

            if (this.selectedProduct) {
                this.fieldLock = true;

                this.product = this.selectedProduct;
                if (this.product.lot_id !== undefined) {
                    this.lot_id = this.product.lot_id[1];
                } else {
                    this.lot_id = '';
                }

                this.$store.dispatch(PRODUCT_LOAD, this.product.product_tmpl_id[0]).then((res) => {
                });
            }
        },
        reloadTemplateProductAttributes(product) {
        },
        reloadProductAttributes(product) {
            this.fieldLock = true;

            this.$store.dispatch(CHANGE_MODAL_DATA, { product });
            // this.loadProduct();
            this.product = product;

            if (this.product.lot_id !== undefined) {
                this.lot_id = this.product.lot_id[1];
            } else {
                this.lot_id = '';
            }

            this.$store.dispatch(PRODUCT_LOAD, this.product.product_tmpl_id[0]).then((res) => {
                //this.$forceUpdate();
            });
        },
        loadAttributes() {
            if (typeof this.variant[0] == "undefined") {
                return false;
            }

            let attributes_of_product = this.variant[0].product_template_attribute_value_ids;

            let findWidth = this.attributes[PRODUCT_ATTRIBUTE_WIDTH_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findWidth[0]) {
                this.width = findWidth[0];
            }

            let findLength = this.attributes[PRODUCT_ATTRIBUTE_LENGTH_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findLength[0]) {
                this.length = findLength[0];
            }

            let findInterior = this.attributes[PRODUCT_ATTRIBUTE_INTERIOR_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findInterior[0]) {
                this.interior = findInterior[0];
            }

            this.$emit('change', true);
        }
    },
    computed: {
        filteredProducts() {
            let products = this.products;

            products = this.products.filter((product) => {
                let count = 0;
                let hits = 0;
                if (this.width) {
                    if (this.width.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1) {
                        hits++;
                    }
                    count++;
                }
                if (this.interior) {
                    if (this.interior.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1) {
                        hits++;
                    }
                    count++;
                }
                if (this.length) {
                    if (this.length.ptav_product_variant_ids.indexOf(product.product_id[0]) > -1) {
                        hits++;
                    }
                    count++;
                }

                if (count == hits) {
                    return true;
                }
            });

            return products;
        },
        filteredTemplateProductsVariants() {
            let products = this.templateProductsVariants;
            products = products.filter((product) => {
                let count = 0;
                let hits = 0;
                if (this.width) {
                    if (this.width.ptav_product_variant_ids.indexOf(product.id) > -1) {
                        hits++;
                    }
                    count++;
                }
                if (this.interior) {
                    if (this.interior.ptav_product_variant_ids.indexOf(product.id) > -1) {
                        hits++;
                    }
                    count++;
                }
                if (this.length) {
                    if (this.length.ptav_product_variant_ids.indexOf(product.id) > -1) {
                        hits++;
                    }
                    count++;
                }

                if (count == hits) {
                    return true;
                }
            });


            return products;
        },
        variants() {
            return this.$store.state.stockStore.stock.map((stock) => {
                return stock.product_id[0];
            });
        },
        products() {
            return this.$store.state.stockStore.stock;
        },
        templateProductsVariants() {
            let products = this.$store.state.productStore.all_product_variants;
            return Object.values(products);
        },
        selectedProduct() {
            return this.$store.state.modalStore.data;
        },
        attributes() {
            return (this.$store.state.productStore.product_variants != null) ? this.$store.state.productStore.product_variants.attributes : null;
        },
        attributesLength() {
            console.log('attributesLength')
            if (this.attributesFromCurrentStockStore) {
                return this.attributesFromCurrentStockStore.filter((attribute) => {
                    return attribute.attribute_id[0] == this.PRODUCT_ATTRIBUTE_LENGTH_ID;
                });
            }
            return null;
        },
        attributesWidth() {
            if (this.attributesFromCurrentStockStore) {
                return this.attributesFromCurrentStockStore.filter((attribute) => {
                    return attribute.attribute_id[0] == this.PRODUCT_ATTRIBUTE_WIDTH_ID;
                });
            }
            return null;
        },
        attributesInterior() {
            if (this.attributesFromCurrentStockStore) {
                return this.attributesFromCurrentStockStore.filter((attribute) => {
                    return attribute.attribute_id[0] == this.PRODUCT_ATTRIBUTE_INTERIOR_ID;
                });
            }
            return null;
        },
        attributesFromCurrentStockStore() {
            let attributes = (this.$store.state.productStore.product_variant_attributes != null) ? this.$store.state.productStore.product_variant_attributes : null;
            if (attributes) {
                let _attributes = [];
                Object.values(attributes).forEach((attribute) => {

                    let interior_found = false;
                    let width_found = false;
                    let length_found = false;

                    _attributes.forEach((attr) => {
                        if (attr !== null && attribute.attribute.interior !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.interior.product_attribute_value_id[0]) {
                                interior_found = true;
                            }
                        }
                        if (attr !== null && attribute.attribute.length !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.length.product_attribute_value_id[0]) {
                                length_found = true;
                            }
                        }
                        if (attr !== null && attribute.attribute.width !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.width.product_attribute_value_id[0]) {
                                width_found = true;
                            }
                        }
                    });

                    if (!interior_found) {
                        _attributes.push(attribute.attribute.interior);
                    }
                    if (!width_found) {
                        _attributes.push(attribute.attribute.width);
                    }
                    if (!length_found) {
                        _attributes.push(attribute.attribute.length);
                    }

                });

                return _attributes.filter((attribute) => {
                    if (attribute != null) {
                        return attribute;
                    }
                });

            }

            return null;
        },
        attributesFromTemplateProducts() {
            let attributes = (this.$store.state.productStore.product_variant_attributes != null) ? this.$store.state.productStore.product_variant_attributes : null;
            if (attributes) {

                let _attributes = [];
                Object.values(attributes).forEach((attribute) => {

                    let interior_found = false;
                    let width_found = false;
                    let length_found = false;

                    _attributes.forEach((attr) => {
                        if (attr !== null && attribute.attribute.interior !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.interior.product_attribute_value_id[0]) {
                                interior_found = true;
                            }
                        }
                        if (attr !== null && attribute.attribute.length !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.length.product_attribute_value_id[0]) {
                                length_found = true;
                            }
                        }
                        if (attr !== null && attribute.attribute.width !== null) {
                            if (attr.product_attribute_value_id[0] == attribute.attribute.width.product_attribute_value_id[0]) {
                                width_found = true;
                            }
                        }
                    });

                    if (!interior_found) {
                        _attributes.push(attribute.attribute.interior);
                    }
                    if (!width_found) {
                        _attributes.push(attribute.attribute.width);
                    }
                    if (!length_found) {
                        _attributes.push(attribute.attribute.length);
                    }

                });

                return _attributes.filter((attribute) => {
                    if (attribute != null) {
                        return attribute;
                    }
                });

            }

            return null;
        },
        variant() {
            if (this.$store.state.productStore.product_variants != null) {
                return this.$store.state.productStore.product_variants.variants.filter((variant) => {
                    return variant.id == this.selectedProduct.product_id[0];
                });
            }
        }
    },
    watch: {
        product(_new, _old) {
        },
        variant(_new, _old) {
            this.loadAttributes();
        }
    }
    /*methods: {
      handleInput (e) {
        this.$emit('input', this.content)
      }
    }*/
}
</script>
