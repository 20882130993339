<template>
  <div class="flex items-center w-full border-vw-modalgray focus:border-vw-green">
    <i :class="`mr-4 text-gray fa fa-` + icon" v-if="icon"></i>
    <input :value="value" v-on:change="updateValue($event.target.value)" v-on:input="updateValue($event.target.value)"
      :type="type" :placeholder="placeholder" class="w-full border-0 input focus:outline-none text-sm p-1">
  </div>
</template>
<script>
export default {
  name: 'InputField',
  props: {
    type: String,
    placeholder: String,
    icon: String,
    value: String,
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    }
  }
}
</script>
