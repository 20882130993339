<template>
  <BaseModal status="error" size="small">
    <div class="flex justify-center items-center flex-col">
      <div class="h-16 w-16 border-4 border-vw-red p-2 rounded-full flex items-center justify-center mb-4">
        <i class="fa-solid fa-xmark text-vw-red text-4xl m-2"></i>
      </div>
      <h3 class="text-xl text-vw-red mb-2 futura-bold">{{ $t('Bezorgadres') }}</h3>
      <p class="my-2 text-vw-gray">{{ $t('Weet u zeker dat je dit adres  uit de standaard bezorg adressen van dit contact wil verwijderen?')}}</p>
      <a class="bg-vw-red px-8 py-2 text-white cursor-pointer mt-4 rounded futura-bold" @click="refuse()">{{ $t('Verwijderen') }}</a>
    </div>

  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import { MODAL_ADD_ADDRESS_REQUEST, MODAL_REFUSE_ADDRESS_REQUEST } from "@/constants";

export default {
  name: 'RefuseAddressRequestModal',
  components: { BaseModal },
  methods: {
    refuse() {
      this.$store.dispatch(MODAL_REFUSE_ADDRESS_REQUEST);
    }
  }
}
</script>
