import {
  IS_LOADING,
  GET_REQUEST_DEPOT_EXTENSION,
  POST_REQUEST_DEPOT_EXTENSION,
  CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION,
  GET_HISTORY_DEPOT_EXTENSION,
  OPEN_MODAL, MODAL_EXTEND_DEPOT_ACCEPT, MODAL_EXTEND_DEPOT_REFUSE
} from "@/constants";
import axios from "axios";

const state = {
  extensionReq:null,
  extensionHistoryState:null,
};
const getters = {
  pageTitle: state => state.pageTitle,
  extensionRequest: state => state.extensionReq ? state.extensionReq.data : null,
  extensionHistory: state => state.extensionHistoryState ? state.extensionHistoryState.data : null,
};
const actions = {
  [GET_REQUEST_DEPOT_EXTENSION]({commit}){
    return axios.get('/request/depotExtension').then((res) => {
      commit(GET_REQUEST_DEPOT_EXTENSION, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [POST_REQUEST_DEPOT_EXTENSION]({commit}, payload){
    return axios.post('/request/depot/extension', payload).then((res)=>{
        commit(POST_REQUEST_DEPOT_EXTENSION, res.data);
        return res;
      }
    ).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
      throw error;
    });
  },
  [CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION]({commit, dispatch}, payload){
    axios.post('/request/confirmOrReject', payload).then((res)=>{
        commit(CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION, res.data);

        if( payload.accepted == 0){
          dispatch(OPEN_MODAL, MODAL_EXTEND_DEPOT_REFUSE);
          dispatch(GET_REQUEST_DEPOT_EXTENSION);
        }
        if( payload.accepted == 1){
          dispatch(OPEN_MODAL, MODAL_EXTEND_DEPOT_ACCEPT);
          dispatch(GET_REQUEST_DEPOT_EXTENSION);
        }
      }
    ).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [GET_HISTORY_DEPOT_EXTENSION]({commit}){
    return axios.get('/request/history').then((res) => {
      commit(GET_HISTORY_DEPOT_EXTENSION, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
};
const mutations = {
  [GET_REQUEST_DEPOT_EXTENSION](state, payload){
    state.extensionReq = payload;
  },
  [POST_REQUEST_DEPOT_EXTENSION](state, payload){
    state.POST_REQUEST_DEPOT_EXTENSION = payload;
  },
  [CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION](state, payload){
    state.CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION = payload;
  },
  [GET_HISTORY_DEPOT_EXTENSION](state, payload){
    state.extensionHistoryState = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
