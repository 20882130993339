export default {
  methods: {
    $can(permissionName, item=null) {

      if (item == 'Gebruikers'){
        if (this.$store.getters.checkAccountType.indexOf('super-admin') !== -1 && this.$store.getters.user.all_permissions.indexOf(permissionName[0]) !== -1){
          return true;
        }else{
          return false;
        }
      }

      /** Allow super admins **/
      if( (this.$store.getters.checkAccountType.indexOf('super-admin') !== -1 || this.$store.getters.checkAccountType.indexOf('superadmin') !== -1) ){
        return true;
      }
      if( typeof permissionName == "undefined" ){
        return true;
      }
      if( typeof permissionName == "object" ){
        let allow = 0;
        Object.values(permissionName).map((permission) => {
          if( this.$store.getters.getPermissions.indexOf(permission) !== -1 || this.$store.getters.checkAccountType.indexOf(permission) !== -1){
            allow++;
          }
        });
        return allow;
      }
      return this.$store.getters.getPermissions.indexOf(permissionName) !== -1 || this.$store.getters.checkAccountType.indexOf(permissionName) !== -1;
    },
  },
};
