<template>
    <BaseModal size="medium">
        <form @submit.prevent="submit">
            <div class="text-xs">
                <div class="block w-full mb-2">
                    <h3 class="text-vw-green text-left mb-4 text-2xl futura-bold">{{ $t('Product toevoegen') }}</h3>
                </div>

                <div class="block my-4" v-if="stock">
                    <img :src="url + stock.product_tmpl_id[0] + '.png'" class="h-36 w-auto m-auto">
                </div>

                <div class="block my-4" v-if="referenceProductId">
                    <img :src="url + referenceProductId + '.png'" class="h-36 w-auto m-auto">
                </div>

                <div class="grid grid-cols-1 text-left">
                    <ProductStockFormRefactor type="extendorder" ref="product" @change="status"></ProductStockFormRefactor>
                </div>

                <div class="w-full grid grid-cols-2 my-4">
                    <div class="flex items-center">
                        <button type="button" @click="updateQuantity(-1)" :class="isInactive" :disabled="minQuantity"
                            id="minusButton"
                            class="h-4 w-4 text-white rounded-full p-4 flex justify-center items-center cursor-pointer text-xl">-</button>
                        <input v-model="quantity" type="number" id="quantity" name="quantity" min="1" max="10"
                            class="border-2 border-vw-green rounded-md flex p-2 mx-4 text-center">
                        <button type="button" @click="updateQuantity(1)" id="plusButton" :class="isInactiveMax"
                            :disabled="maxQuantity"
                            class="h-4 w-4 bg-vw-green text-white rounded-full p-4 flex justify-center items-center cursor-pointer text-lg">+</button>
                    </div>

                    <div class="items-center flex justify-end my-1"> <!-- v-show="canSubmit" -->
                        <button type="submit" class="bg-vw-green text-white rounded-md px-4 py-2 w-full text-sm">
                            {{ $t('Toevoegen') }}
                            <i class="fa-solid fa-plus text-sm text-white ml-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ProductStockFormPart from "@/components/modals/parts/ProductStockFormPart";
import {
    CLOSE_MODAL,
    MODAL_EXTEND_DEPOT_SUCCESS,
    PRODUCT_VARIANTS,
    OPEN_MODAL,
    POST_REQUEST_DEPOT_EXTENSION,
    PRODUCTS_LOAD, STOCK_LOCATIONS
} from "@/constants";
import ProductStockFormPartV2 from "@/components/modals/parts/ProductStockFormPartV2.vue";
import ProductStockFormRefactor from "@/components/modals/parts/ProductStockFormRefactor.vue";
export default {
    name: 'ExtendOrder',
    components: { ProductStockFormPart, ProductStockFormPartV2, BaseModal, ProductStockFormRefactor },
    data: () => ({
        quantity: 1,
        undertaker: '',
        deceased: '',
        file_number: '',
        loaded: false,
        reason: '',
        referenceProductId: null,
        url: process.env.VUE_APP_STORAGE_URL + '/storage/products/',
    }),
    mounted() {
    },
    methods: {
        updateQuantity(quantity) {
            if (quantity == 1 && this.quantity < 10) {
                this.quantity++;
            } else {
                this.quantity--;
            }
        },
        submit() {

            let prod_id = null;
            if (this.$refs.product.possibleVariants) {
                if (!this.$refs.product.possibleVariants.length) {
                    alert(this.$t('Kies een geldig product met alle attributen.'));
                    return;
                } else {
                    prod_id = this.$refs.product.possibleVariants[0].id;
                }
            }

            this.$store.dispatch(POST_REQUEST_DEPOT_EXTENSION, {
                product_id: prod_id,
                product_data: this.stock,
                reason: this.reason,
                quantity: this.quantity
            }).then((res) => {
                this.closeModal();
            });
        },
        closeModal() {
            this.$store.dispatch(CLOSE_MODAL);
            this.$store.dispatch(OPEN_MODAL, MODAL_EXTEND_DEPOT_SUCCESS);
        },
        status(state) {
            this.referenceProductId = this.$refs.product.productSelected.id;
            this.loaded = state;
        }
    },
    computed: {
        stock() {
            return this.$store.getters.modalData;
        },
        isInactive() {
            return (this.quantity <= 1) ? 'bg-vw-gray' : 'bg-vw-green';
        },
        isInactiveMax() {
            return (this.quantity >= 10) ? 'bg-vw-gray' : 'bg-vw-green';
        },
        minQuantity() {
            return this.quantity <= 1;
        },
        maxQuantity() {
            return this.quantity >= 10;
        }
    }
}
</script>
