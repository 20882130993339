<template>
    <div id="app">
        <Loader></Loader>
        <!--<nav v-if="$route.name != 'login' && $route.name != 'register'">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>-->
        <!--<nav v-if="$store.getters.getUserType == 'depot'">
    </nav>
    <nav v-if="$store.getters.getUserType == 'superadmin'">
    </nav>-->
        <router-view />

        <AddAddressRequestModal v-if="activeModal == 'AddAddressRequest'"></AddAddressRequestModal>
        <AddAddressRequestCompleteModal v-if="activeModal == 'AddAddressRequestComplete'"></AddAddressRequestCompleteModal>
        <HandleAddressRequest v-if="activeModal == 'HandleAddressRequest'"></HandleAddressRequest>
        <RemoveAddressModal v-if="activeModal == 'RemoveAddress'"></RemoveAddressModal>
        <RemoveClient v-if="activeModal == 'RemoveClient'"></RemoveClient>
        <RefuseAddressRequestModal v-if="activeModal == 'RefuseAddressRequest'"></RefuseAddressRequestModal>
        <AcceptAddressRequestModal v-if="activeModal == 'AcceptAddressRequest'"></AcceptAddressRequestModal>
        <AddAddress v-if="activeModal == 'AddAddress'"></AddAddress>
        <AddAccount v-if="activeModal == 'AddAccount'"></AddAccount>
        <AddUser v-if="activeModal == 'AddUser'"></AddUser>
        <ConfirmationModal v-if="activeModal == 'ConfirmationModal'"></ConfirmationModal>
        <EditAccount v-if="activeModal == 'EditAccount'"></EditAccount>
        <ExtendOrder v-if="activeModal == 'ExtendOrder'"></ExtendOrder>
        <ExtendDepot v-if="activeModal == 'ExtendDepot'"></ExtendDepot>
        <ExtendDepotSuccess v-if="activeModal == 'ExtendDepotSuccess'"></ExtendDepotSuccess>
        <ExtendDepotAccept v-if="activeModal == 'ExtendDepotAccept'"></ExtendDepotAccept>
        <ExtendDepotRefuse v-if="activeModal == 'ExtendDepotRefuse'"></ExtendDepotRefuse>
        <DeregisterDepot v-if="activeModal == 'DeregisterDepot'"></DeregisterDepot>
        <DeregisterSubmitted v-if="activeModal == 'DeregisterSubmitted'"></DeregisterSubmitted>
        <ReturnDepot v-if="activeModal == 'ReturnDepot'"></ReturnDepot>
        <ReturnDepotSuccess v-if="activeModal == 'ReturnDepotSuccess'"></ReturnDepotSuccess>
        <DeregisterDelete v-if="activeModal == 'DeregisterDelete'"></DeregisterDelete>
        <DeregisterAccept v-if="activeModal == 'DeregisterAccept'"></DeregisterAccept>
        <AccountCreated v-if="activeModal == 'AccountCreated'"></AccountCreated>
        <OrderRetour v-if="activeModal == 'OrderRetour'"></OrderRetour>
        <OrderDeliveryModal v-if="activeModal == 'OrderDeliveryModal'"></OrderDeliveryModal>
        <HistoryDetail v-if="activeModal == 'HistoryDetail'"></HistoryDetail>
        <OrderSignOff v-if="activeModal == 'OrderSignOff'"></OrderSignOff>
        <SignupInfo v-if="activeModal == 'SignupInfo'"></SignupInfo>
        <SignupAccept v-if="activeModal == 'SignupAccept'"></SignupAccept>
        <SignupDelete v-if="activeModal == 'SignupDelete'"></SignupDelete>
        <AddClient v-if="activeModal == 'AddClient'"></AddClient>
        <AddClient v-if="activeCustomerModal == 'AddClient_'"></AddClient>
        <AddClientAdmin v-if="activeModal == 'AddClientAdmin'"></AddClientAdmin>
        <FilterDeregister v-if="activeModal == 'FilterDeregister'"></FilterDeregister>
        <FilterTickets v-if="activeModal == 'FilterTickets'"></FilterTickets>
        <ReturnAccept v-if="activeModal == 'ReturnAccept'"></ReturnAccept>
        <ReturnDelete v-if="activeModal == 'ReturnDelete'"></ReturnDelete>
        <ReturnDeleteSuccess v-if="activeModal == 'ReturnDeleteSuccess'"></ReturnDeleteSuccess>

        <div v-show="this.$store.state.appStore.isError"
            class="fixed bottom-0 w-full bg-vw-red text-white z-100 text-left p-4 flex justify-center">
            <div class="w-full lg:w-2/3">
                {{ $t('Er is iets misgegaan bij uw aanvraag:') }}<br>
                <li v-if="this.$store.state.appStore.error_message">
                    {{ $t('Niet alle velden zijn correct.') }}
                </li>
                <div v-if="this.$store.state.appStore.errors">
                    <li v-for="(err, key) in this.$store.state.appStore.errors">
                        {{ err[0] }}
                    </li>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css";

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #666666;
}

nav {
    padding: 30px;

    a {
        font-weight: normal;
    }
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}
.ns-not-allowed {
    cursor: not-allowed;
    background: #f4f4f4;
}
</style>
<script>
import AddAddressRequestModal from "@/components/modals/AddAddressRequest";
import HandleAddressRequest from "@/components/modals/HandleAddressRequest";
import RemoveAddressModal from "@/components/modals/RemoveAddress";
import RemoveClient from "@/components/modals/RemoveClient";
import AddAddressRequestCompleteModal from "@/components/modals/AddAddressRequestComplete";
import RefuseAddressRequestModal from "@/components/modals/RefuseAddressRequest";
import AcceptAddressRequestModal from "@/components/modals/AcceptAddressRequest";
import AddAddress from "@/components/modals/AddAddress";
import AddAccount from "@/components/modals/AddAccount";
import AddUser from "@/components/modals/AddUser";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import EditAccount from "@/components/modals/EditAccount";
import ExtendOrder from "@/components/modals/ExtendOrder.vue";
import ExtendDepot from "@/components/modals/ExtendDepot";
import ExtendDepotSuccess from "@/components/modals/ExtendDepotSuccess";
import DeregisterDepot from "@/components/modals/DeregisterDepot";
import ReturnDepot from "@/components/modals/ReturnDepot";
import ReturnDepotSuccess from "@/components/modals/ReturnDepotSuccess";
import DeregisterSubmitted from "@/components/modals/DeregisterSubmitted";
import DeregisterDelete from "./components/modals/DeregisterDelete.vue";
import DeregisterAccept from "./components/modals/DeregisterAccept.vue";
import AccountCreated from "./components/modals/AccountCreated.vue";
import OrderRetour from "./components/modals/OrderRetour.vue";
import OrderDeliveryModal from "./components/modals/OrderDelivery.vue";
import SignupInfo from "./components/modals/SignupInfo.vue";
import SignupAccept from "./components/modals/SignupAccept.vue";
import SignupDelete from "./components/modals/SignupDelete.vue";
import Loader from "@/components/Loader";
import HistoryDetail from "./components/modals/HistoryDetail.vue";
import AddClient from "./components/modals/AddClient.vue";
import AddClientSuccess from "./components/modals/AddClientAdmin.vue";
import AddClientAdmin from "./components/modals/AddClientAdmin.vue";
import OrderSignOff from "./components/modals/OrderSignOff.vue";
import FilterDeregister from "./components/modals/FilterDeregister.vue";
import FilterTickets from "./components/modals/FilterTickets.vue";
import ReturnAccept from "@/components/modals/ReturnAccept";
import ReturnDelete from "@/components/modals/ReturnDelete";
import ExtendDepotAccept from "@/components/modals/ExtendDepotAccept.vue";
import ExtendDepotRefuse from "@/components/modals/ExtendDepotRefuse.vue";
import ReturnDeleteSuccess from "@/components/modals/ReturnDeleteSuccess.vue";

export default {
    components: {
        ExtendDepotAccept,
        EditAccount,
        AddAccount,
        AddUser,
        ConfirmationModal,
        AddAddress,
        AcceptAddressRequestModal,
        RefuseAddressRequestModal,
        RemoveClient,
        ExtendOrder,
        ExtendDepot,
        ExtendDepotSuccess,
        ExtendDepotRefuse,
        DeregisterDepot,
        ReturnDepot,
        ReturnDepotSuccess,
        AddAddressRequestCompleteModal,
        RemoveAddressModal,
        HandleAddressRequest,
        AddAddressRequestModal,
        DeregisterSubmitted,
        DeregisterDelete,
        DeregisterAccept,
        AccountCreated,
        OrderRetour,
        OrderDeliveryModal,
        SignupInfo,
        SignupAccept,
        SignupDelete,
        Loader,
        HistoryDetail,
        AddClient,
        AddClientSuccess,
        AddClientAdmin,
        OrderSignOff,
        FilterDeregister,
        FilterTickets,
        ReturnAccept,
        ReturnDelete,
        ReturnDeleteSuccess,
    },
    data: () => ({
        redirect_since: false,
    }),
    computed: {
        redirectBack() {
            return this.$store.state.appStore.redirect_back
        },
        activeModal() {
            return this.$store.getters.activeModal;
        },
        activeCustomerModal() {
            return this.$store.getters.activeCustomerModal;
        }
    },
    watch: {
        redirectBack() {
            if (this.redirect_since == false) {
                this.redirect_since = true;
                this.$router.back();
                setTimeout(() => {
                    this.redirect_since = false;
                }, 5000);
            }
        }
    }
}
</script>
<!-- 
<style scoped>
>>> {
    --vs-controls-color: #2c3e50;
    --vs-border-color: #F7F7F7;

    --vs-dropdown-bg: #FFF;
    --vs-dropdown-color: #2c3e50;
    --vs-dropdown-option-color: #2c3e50;

    --vs-selected-bg: #2c3e50;
    --vs-selected-color: #2c3e50;

    --vs-search-input-color: #2c3e50;

    --vs-dropdown-option--active-bg: #93C593;
    --vs-dropdown-option--active-color: #FFF;
}
</style> -->
