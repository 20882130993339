import {
    IS_LOADING,
    LOGIN_FAILED,
    LOGIN_USER,
    REQUEST_LOAD,
    REQUEST_SAVE,
    LOAD_ADDRESSES,
    MODAL_DEREGISTER_DEPOT,
    MODAL_DEREGISTER_SUBMITTED,
    REQUEST_DEREGISTER,
    REQUEST_LOAD_ALL,
    REQUEST_ORDERS,
    REQUEST_CLIENTS,
    REQUEST_CLIENT_REMOVE,
    REQUEST_CLIENT_ACCEPT,
    IS_ERROR,
    OPEN_MODAL,
    REQUEST_DEREGISTERS,
    REQUEST_DEREGISTER_ACCEPT,
    REQUEST_DEREGISTER_DECLINE,
    REQUEST_DEREGISTER_HISTORY,
    FILTER_SET_PAGE_STOCK,
    FILTER_SET_PAGE_DEREGISTER
} from "@/constants";
import axios from "axios";

const state = {
    requests: null,
    request: null,
    addresses: null,
    orders: null,
    deregisters: null,
    deregistersHistory: null,
    clients: null,
};
const getters = {
    requests: state => state.requests ? state.requests : null,
    request: state => state.request ? state.request : null,
    addresses: state => state.addresses ? state.addresses : null,
    totalRequests: (state) => {
        let total = 0;
        if (state.requests) {
            Object.values(state.requests).map((requests) => {
                total += requests;
            });
        }
        return total;
    },
    requestOrders: state => state.orders ? state.orders : null,
    requestOrder: (state) => (id) => {
        if (state.orders) {
            let filter = state.orders.filter((order) => {

                return order.id == id;
            });

            return filter[0];
        }
    },
    deregistersHistory: (state) => state.deregistersHistory ? state.deregistersHistory : null,
    requestDeregisters: (state) => state.deregisters ? state.deregisters : null,
    requestClients: (state) => state.clients ? state.clients : null,
    requestClient: (state) => (id) => {
        if (state.clients) {
            let filter = state.clients.filter((client) => {
                return client.id == id;
            });

            return filter[0];
        }
    }
};
const actions = {
    [REQUEST_LOAD_ALL]({ commit, dispatch }, payload) {
        axios.post('/request/all').then((res) => {
            commit(REQUEST_LOAD_ALL, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_LOAD]({ commit }, payload) {
        axios.get('/request/signup/' + payload).then((res) => {
            commit(REQUEST_LOAD, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_ORDERS]({ commit }, payload) {
        axios.get('/request/orders').then((res) => {
            commit(REQUEST_ORDERS, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_CLIENTS]({ commit }, payload) {
        axios.get('/request/clients').then((res) => {
            commit(REQUEST_CLIENTS, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_CLIENT_ACCEPT]({ commit, dispatch }, payload) {
        return axios.post('/request/clients/accept', payload).then((res) => {
            return res;
        }).catch((err) => {
            dispatch('appStore/' + IS_ERROR, err.response.data, { root: true });
            dispatch('appStore/' + IS_LOADING, false, { root: true });
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
            throw err;
        });
    },
    [REQUEST_CLIENT_REMOVE]({ commit, dispatch }, payload) {
        axios.post('/request/clients/decline', { id: payload }).then((res) => {
            dispatch(REQUEST_CLIENTS);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_SAVE]({ commit }, payload) {
        return axios.post('/request/signup/' + payload.id, payload.data).then((res) => {
            // Do something with result?
            // Commit
            return res;
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
            throw error;
        });
    },
    [REQUEST_DEREGISTERS]({ commit }, payload) {
        axios.get('/request/deregisters').then((res) => {
            commit(REQUEST_DEREGISTERS, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_DEREGISTER_HISTORY]({ commit }, payload) {
        axios.get('/request/deregister/history').then((res) => {

            console.log(res.data, 'before histories');
            console.log(payload, 'payload');
            let histories = res.data.filter(history => {
              if(!history.requestable) return false;
                if (history.requestable.product_data_array['location_id'][0] == payload.id){
                    return history;
                }
            });

            console.log(histories, 'histories');

            commit(REQUEST_DEREGISTER_HISTORY, histories);

        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_DEREGISTER]({ commit, dispatch }, payload) {
        axios.post('/request/stock/deregister', payload).then((res) => {
            //commit(REQUEST_DEREGISTER, res.data);
            dispatch(OPEN_MODAL, MODAL_DEREGISTER_SUBMITTED);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [REQUEST_DEREGISTER_ACCEPT]({ commit }, payload) {
        return axios.post('/request/deregister/accept', payload).then((res) => {
            return res;
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
            throw error;
        });
    },
    [REQUEST_DEREGISTER_DECLINE]({ commit }, payload) {
        return axios.post('/request/deregister/decline', payload).then((res) => {
            return res;
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
            throw error;
        });
    },
    [LOAD_ADDRESSES]({ commit }, payload) {
        axios.get('/request/addresses').then((res) => {
            commit(LOAD_ADDRESSES, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    }
};
const mutations = {
    [REQUEST_LOAD_ALL](state, payload) {
        state.requests = payload;
    },
    [REQUEST_LOAD](state, payload) {
        state.request = payload;
    },
    [LOAD_ADDRESSES](state, payload) {
        state.addresses = payload;
    },
    [REQUEST_CLIENTS](state, payload) {
        state.clients = payload;
    },
    [REQUEST_ORDERS](state, payload) {
        state.orders = payload;
    },
    [REQUEST_DEREGISTERS](state, payload) {
        state.deregisters = payload.filter(i => (i.requestable && i.user && i.requestable.product_data_array));
    },
    [REQUEST_DEREGISTER_HISTORY](state, payload) {
        state.deregistersHistory = payload;
    }
    /*[REQUEST_DEREGISTER](state, payload){

    }*/
};

export default {
    state,
    getters,
    mutations,
    actions
};
