<template>
    <BaseModal size="account">
        <div class="text-left">

            <form @submit.prevent="save">
                <UserForm type="add" :user="userData"></UserForm>

                <button class="btn w-full bg-vw-green text-white py-2 mt-6 rounded-lg" type="submit">{{ $t('Opslaan')
                }}</button>
            </form>

        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import UserForm from "@/components/modals/parts/UserForm";
import { ADD_USER,CLOSE_MODAL,MODAL_ADD_USER,EDIT_USER } from "@/constants";

export default {
    name: 'AddUser',
    components: { BaseModal, UserForm },
    data: () => ({
        user: {
            id:null,
            first_name: '',
            last_name: '',
            function: '',
            company_name: ' ',
            active: 0,
            email: '',
            phone: '',
            editUser:false,
            date_of_birth: '',
            all_permissions: [],
        }
    }),
    computed:{
        userData(){
            let editUsers = this.$store.getters.modalData;
            if(editUsers.id){
                this.user.id = editUsers.id
                this.user.first_name = editUsers.first_name
                this.user.last_name = editUsers.last_name
                this.user.function = editUsers.function
                this.user.phone = editUsers.phone
                this.user.active = editUsers.active
                this.user.email = editUsers.email
                this.user.date_of_birth = editUsers.date_of_birth
                this.user.editUser = true
                this.user.all_permissions = editUsers.all_permissions
            }
            return this.user;
        }
    },
    methods: {
        save() {
            var cl = console.log;
            if(this.user.editUser){
                this.$store.dispatch(EDIT_USER, {...this.user }).then((rs) => {
                });
            }else{
                this.$store.dispatch(ADD_USER, {...this.user }).then((rs) => {
                });
            }
        }
    }
}
</script>
