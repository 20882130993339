<template>
    <BaseModal size="account">
        <div class="text-left">

            <form @submit.prevent="save">
                <AccountForm type="add" :account="account"></AccountForm>

                <button class="btn w-full bg-vw-green text-white py-2 mt-6 rounded-lg" type="submit">{{ $t('Opslaan')
                }}</button>
            </form>

        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import AccountForm from "@/components/modals/parts/AccountForm";
import { COMPANY_ADD_ACCOUNT, COMPANY_UPDATE_ACCOUNT } from "@/constants";

export default {
    name: 'AddAccount',
    components: { BaseModal, AccountForm },
    data: () => ({
        account: {
            first_name: '',
            last_name: '',
            function: '',
            active: 0,
            email: '',
            phone: '',
            date_of_birth: '',
            all_permissions: [],
        }
    }),
    methods: {
        save() {
            var cl = console.log;
            let company_id = this.$route.params.id || this.$store.getters.user.id
            this.$store.dispatch(COMPANY_ADD_ACCOUNT, { company_id: company_id, ...this.account });
        }
    }
}
</script>
