import axios from "axios";
import { IS_ERROR, IS_LOADING, USER, PAGE_TITLE } from "@/constants";

const state = {
    isLoading: false,
    isError: false,
    errors: [],
    error_message: '',
    redirect_back: false,
    isMenuOpen: false,
    pageTitle: '',
    users: [],
};

const getters = {
    pageTitle: state => state.pageTitle,
    isLoading: state => state.isLoading,
};

const actions = {
    [IS_LOADING]({ commit }, payload) {
        commit(IS_LOADING, payload);
    },
    [IS_ERROR]({ commit }, payload) {
        commit(IS_ERROR, payload);
    },
    [USER]({ commit }, payload) {
        axios.get('/users').then((res) => {
            commit(USER, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [PAGE_TITLE]({ commit }, payload) {
        commit(PAGE_TITLE, payload);
    }
};

const mutations = {
    [IS_LOADING](state, payload) {
        state.isLoading = payload;
    },
    [IS_ERROR](state, payload) {
        state.isError = true;
        if (payload.errors) {
            if (typeof payload.error.bag !== 'undefined' && typeof payload.error.bag.return !== 'undefined') {
                delete payload.error.bag.return;
                state.redirect_back = true;
            }
        }

        state.errors = payload.error.bag;
        state.error_message = payload.error.message;

        setTimeout(() => {
            state.isError = false;
            state.errors = [];
            state.redirect_back = false;
        }, 5000);
    },
    [USER](state, payload) {
        state.users = payload;
    },
    [PAGE_TITLE](state, payload) {
        state.pageTitle = payload;
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
};
