<template>
    <BaseModal size="small" status="error">
        <div class="flex justify-center flex-col items-center">
            <div class="border-4 border-vw-red rounded-full h-16 w-16 flex items-center justify-center mb-4">
                <i class="fa-solid fa-trash text-vw-red text-4xl p-4"></i>
            </div>

            <h3 class="text-xl text-vw-red mb-2 futura-bold">{{ $t('Weigeren') }}</h3>
            <p class="my-2">{{ $t('Weigeren van deze aanvraag verwijdert de aanvraag direct')}}.</p>
            <button @click="remove()" class="bg-vw-red border-2 border-vw-red text-white px-8 py-2 rounded-md mt-4 cursor-pointer">{{ $t('Verwijderen') }}</button>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import {CLOSE_MODAL, REQUEST_CLIENT_REMOVE} from "@/constants";

export default {
    name: 'SignupDelete',
    components: { BaseModal },
  methods: {
      remove(){
        this.$store.dispatch(REQUEST_CLIENT_REMOVE, this.signup.id);
        this.$store.dispatch(CLOSE_MODAL);
      }
  },
  computed: {
    signup(){
      return this.$store.state.modalStore.data;
    }
  }
}
</script>
