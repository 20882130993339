import axios from "axios";
import { LOAD_DEPOTS } from "@/constants";

const state = {
    depots: [],
};
const getters = {
    getDepots: state => state.depots,
    getDepot: (state) => (id) => {
        return state.depots.filter((depot) => {
            return depot.id == id;
        });
    }
};
const actions = {
    [LOAD_DEPOTS]({ commit }, payload) {
        commit(LOAD_DEPOTS, payload);
    }
};
const mutations = {
    [LOAD_DEPOTS](state, payload) {
        if (!payload) {
            payload = '';
        }
        axios.get('/depots/all' + payload).then((res) => {
            console.log(res.data)
            state.depots = res.data.data;
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
