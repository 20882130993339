import {ORDER_LOAD, ORDERS} from "@/constants";
import axios from "axios";

const state = {
  order: null,
  orders: null,
};
const getters = {
};
const actions = {
  [ORDERS]({commit}, payload){
    axios.get('/orders').then((res)=>{
      commit(ORDERS, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [ORDER_LOAD]({commit}, payload){
    axios.get('/orders/' + payload).then((res)=>{
      commit(ORDER_LOAD, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  }
};
const mutations = {
  [ORDERS](state, payload){
    state.orders = payload;
  },
  [ORDER_LOAD](state, payload){
    state.order = payload;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
