<template>
  <div class="flex md:flex-col">
    <router-link to="/login" class="futura text-right text-lg md:text-2xl uppercase p-4 md:p-6" :class="activeRoute('login')">{{ $t('Inloggen' ) }}</router-link>
    <router-link to="/register" class="futura text-right text-lg md:text-2xl uppercase p-4 md:p-6" :class="activeRoute('register')">
      {{  $t('Registreren') }}</router-link>
  </div>
</template>
<script>
export default {
  name: 'LoginNav',
  methods: {
    activeRoute(route) {
      return {
        'text-vw-gray rounded-t-xl md:rounded-l-full bg-white': this.$route.name.indexOf(route) > -1,
        'text-white' : this.$route.name.indexOf(route) == -1,
      }
    }
  }
}
</script>
