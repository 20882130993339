<template>
    <BaseModal size="small" status="success">
        <div class="text-left grid grid-cols-1 gap-2 md:gap-4 overflow-y-scroll">
            <h2 class="text-2xl text-vw-green futura-bold my-2">Afmelden</h2>
            <img src="../../assets/img/Model_140.png" class="m-auto h-36">
            <div>
                <label for="model" class="text-xs text-vw-lightgray">{{$t('Model')}} <sup>*</sup></label>
                <v-select vlabel="model" class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></v-select>
            </div>
            <div>
                <label for="length" class="text-xs text-vw-lightgray">{{$t('Inwendige lengte')}} <sup>*</sup></label>
                <v-select vlabel="length" class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></v-select>
                <span class="text-xs text-vw-lightgray">{{$t('Extra informatie hier')}}</span>
            </div>
            <div>
                <label for="width" class="text-xs text-vw-lightgray">{{$t('Inwendige breedte')}} <sup>*</sup></label>
                <v-select vlabel="width" class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></v-select>
                <span class="text-xs text-vw-lightgray">{{$t('Extra informatie hier')}}</span>
            </div>
            <div>
                <label for="interior" class="text-xs text-vw-lightgray">{{$t('Binnenbekleding')}} <sup>*</sup></label>
                <v-select vlabel="interior" class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></v-select>
            </div>
            <div>
                <label for="undertaker" class="text-xs text-vw-lightgray">{{$t('Ondernemer')}} <sup>*</sup></label>
                <input type="text" name="undertaker" id="undertaker" class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green">
            </div>
            <div>
                <label for="deceased" class="text-xs text-vw-lightgray">{{$t('Overledene')}} <sup>*</sup></label>
                <input type="text" name="deceased" id="deceased" class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green">
            </div>
            <div>
                <label for="file" class="text-xs text-vw-lightgray">{{$t('Dossiernummer')}}</label>
                <input type="text" name="file" id="file" class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green">
            </div>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
                <div class="flex justify-center items-center">
                    <button type="button" @click="updateQuantity(-1)" :class="isInactive" :disabled="minQuantity"
                    id="minusButton"
                    class="h-4 w-4 text-white rounded-full p-3 flex justify-center items-center cursor-pointer">
                    <i class="fa-solid fa-minus text-white"></i>
                    </button>
                    <input v-model="quantity" type="number" id="quantity" name="quantity" min="1" max="10"
                    class="border-2 border-green rounded-md flex mx-4 items-center justify-center text-center">
                    <button type="button" @click="updateQuantity(1)" id="plusButton"
                    class="h-4 w-4 bg-vw-green text-white rounded-full p-3 flex justify-center items-center cursor-pointer">
                    <i class="fa-solid fa-plus text-white"></i>
                    </button>
                </div>
                <div>
                    <span v-html="formatNumber( item.quantity * item.priceSingle * 1.21 )"></span>
                </div>
                <div class="col-span-2 md:col-span-1">
                    <button type="submit" class="bg-vw-green text-white px-8 py-3 rounded futura-bold w-full">{{ $t('Voeg toe') }} <i class="fa-solid fa-plus text-white ml-2"></i></button>
                </div>
            </div>
            <div>
                <button type="submit" class="bg-vw-green text-white px-8 py-3 rounded futura-bold w-full">{{ $t('Afmelden') }}</button>
            </div>

        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
    name: 'OrderSignOff',
    components: { BaseModal },
}
</script>
