<template>
  <BaseModal size="medium" status="success">
    <div class="text-left grid grid-cols-1 gap-2 md:gap-4">
      <h2 class="text-2xl text-vw-gray futura-bold my-2">{{ $t('Klant toevoegen') }}</h2>
      <div class="">
        <v-select v-if="odoo.clients" v-model="odoo_select_client" label="name" :options="odoo.clients"
                  class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"></v-select>
      </div>

      <div class="flex mt-2">
        <a href="#" class="text-sm mr-4 underline hover:text-vw-green" @click="refresh">{{ $t('Lijst vernieuwen') }}</a>
        <a :href="odoo_create_url+'/web#cids=1&menu_id=292&action=442&model=res.partner&view_type=form'"
           class="hover:text-vw-green cursor-pointer underline" target="_blank">{{ $t('Maak een nieuwe klant aan') }}</a>
      </div>
      <div class="flex justify-start md:justify-end">
        <button @click.stop="add()" type="submit" class="bg-vw-green text-white px-8 py-3 rounded-lg futura-bold w-full md:w-fit">{{
            $t('Toevoegen')
          }} <i class="fa-solid fa-plus text-white ml-2"></i></button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import InputField from "@/components/form/InputField";
import axios from "axios";
import {MODAL_ADD_ADDRESS, MODAL_ADD_CLIENT,LOAD_SETTINGS,MODAL_DEREGISTER_DEPOT} from "@/constants";

export default {
  name: 'AddClientAdmin',
  components: {InputField, BaseModal},
  data: () => ({
    odoo_select_client: null,
    odoo: {
      clients: null,
    },
    odoo_create_url: process.env.VUE_APP_ODOO_URL
  }),
  mounted() {
    this.loadClients();
    this.$emit('openDepotModal',{data:this.$store.getters.modalData,modal:MODAL_DEREGISTER_DEPOT});
  },
  methods: {
    loadClients(refresh=false){
      axios.post('/odoo/company/clients'+((refresh)?'?fresh':'')).then((res) => {
        this.odoo.clients = Object.values(res.data);
      }).catch((error) => {
        if (error.response?.status === 401) {
          if (this.$route.path !== '/login') {
            store.dispatch(LOGOUT_USER, {}).then(() => {
                this.$router.push('/login');
            });
          }
        }
      });
    },
    refresh() {
      this.loadClients(true);
    },
    add() {
      this.$store.dispatch(MODAL_ADD_CLIENT, {
        id: this.odoo_select_client.id,
        user_id: parseInt(this.$route.params.id),
        stock:this.$store.getters.modalData
      }).then( (data) => {
        // console.log(data);
        this.$store.dispatch(LOAD_SETTINGS,{userId:parseInt(this.$route.params.id)})
      });
    }
  }
}
</script>
