<template>
  <BaseModal status="success" class="overflow-y-scroll" size="medium">
    <div class="">
      <div class="block my-4 text-left">
        <span class="text-2xl futura-bold">{{ $t('Aanvraag door') }}</span>
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 my-8">
          <div class="flex flex-col w-full">
            <label for="company" class="text-vw-lightgray mb-2">{{ ('Onderneming') }}</label>
            <input v-model="signup.user.company_name" type="text" name="company" id="company"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="name" class="text-vw-lightgray mb-2">{{ ('Naam') }}</label>
            <input :value="signup.user.first_name + ' ' + signup.user.last_name" type="text" name="name" id="name"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="email" class="text-vw-lightgray mb-2">{{ ('E-mail') }}</label>
            <input v-model="signup.user.email" type="email" name="email" id="email"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="phone" class="text-vw-lightgray mb-2">{{ ('Telefoonnummer') }}</label>
            <input v-model="signup.user.phone" type="tel" name="phone" id="phone"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
        </div>
      </div>

      <div class="block my-4 text-left">
        <span class="text-xl futura-bold">{{ $t('Klantgegevens') }}</span>
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 my-8">
          <div class="flex flex-col w-full">
            <label for="addressname" class="text-vw-lightgray mb-2">{{ ('Adresnaam') }}</label>
            <input v-model="signup.requestable.name" type="text" name="addressname" id="addressname"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="website" class="text-vw-lightgray mb-2">{{ ('Website') }}</label>
            <input v-model="signup.requestable.website" type="url" name="website" id="website"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="city" class="text-vw-lightgray mb-2">{{ ('Plaats') }}</label>
            <input v-model="signup.requestable.city" type="text" name="city" id="city"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="postal" class="text-vw-lightgray mb-2">{{ ('Postcode') }}</label>
            <input v-model="signup.requestable.zip" type="text" name="postal" id="postal"
                   class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green">
          </div>
          <div class="flex flex-col w-full">
            <label for="odoo_contact" class="text-vw-lightgray mb-2">{{ ('Odoo contact naam') }}</label>

            <div class="">
              <v-select v-if="odoo.clients" v-model="odoo_select_client" label="name" :options="odoo.clients"
                        class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-green"></v-select>
            </div>

            <!-- <div class="flex mt-2">
              <a href="#" class="text-sm mr-1 underline hover:text-vw-green" @click="refresh">{{
                  $t('Lijst vernieuwen')
                }}</a>
              <a :href="odoo_create_url+'/web#cids=1&menu_id=292&action=442&model=res.partner&view_type=form'"
                 class="hover:text-vw-green cursor-pointer underline" target="_blank">{{
                  $t('Maak een nieuwe klant aan')
                }}</a>
            </div> -->
          </div>
          <div class="flex justify-end flex-col w-full">
            <div class="grid grid-cols-2 gap-2 lg:gap-4 xl:gap-6 justify-between items-end">
              <button class="text-vw-red border-2 border-vw-red py-2 rounded-lg" @click.stop="signupDelete">
                {{ $t('Weigeren') }}
                <i class="fa-regular fa-trash-can text-vw-red ml-2"></i>
              </button>
              <button class="bg-vw-green border-2 border-vw-green text-white py-2 rounded-lg" @click.stop="signupSuccess">
                {{ $t('Toevoegen') }}
                <i class="fa-regular fa-plus text-white ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import {MODAL_SIGNUP_DELETE, MODAL_SIGNUP_SUCCESS, OPEN_MODAL, REQUEST_CLIENT_ACCEPT} from "@/constants";
import axios from "axios";

export default {
  name: 'SignupInfo',
  components: {BaseModal},
  data: () => ({
    odoo_select_client: null,
    odoo: {
      clients: null,
    },
    odoo_create_url: process.env.VUE_APP_ODOO_URL
  }),
  mounted() {
    this.loadClients();
  },
  computed: {
    signup(){
      return this.$store.state.modalStore.data;
    },
    getOdooId(){
      if( this.odoo_select_client ){
        return this.odoo_select_client.id;
      } else {
        return null;
      }
    }
  },
  methods: {
    loadClients(refresh=false){
      axios.post('/odoo/company/clients'+((refresh)?'?fresh':'')).then((res) => {
        this.odoo.clients = Object.values(res.data);
      }).catch((error) => {
        if (error.response?.status === 401) {
          if (this.$route.path !== '/login') {
            store.dispatch(LOGOUT_USER, {}).then(() => {
                this.$router.push('/login');
            });
          }
        }
      });
    },
    refresh() {
      this.loadClients(true);
    },
    signupSuccess() {
      this.$store.dispatch(REQUEST_CLIENT_ACCEPT, {id: this.signup.id, odoo_id: this.getOdooId }).then((res)=>{
        this.$store.dispatch(OPEN_MODAL, {modal: MODAL_SIGNUP_SUCCESS, data: this.signup});
      }).catch((err)=>{

      });
    },
    signupDelete() {
      this.$store.dispatch(OPEN_MODAL, {modal: MODAL_SIGNUP_DELETE, data: this.signup});
    }
  }
}
</script>
