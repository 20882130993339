<template>
  <div class="text-sm flex flex-col gap-2">
    <div class="my-1">
      <label for="model" class="block text-vw-lightgray w-full"
        >Model <sup>*</sup></label
      >
      <!-- @change="reloadProductAttributes(product)" -->
      <!--  v-model="product" -->
      <!-- <option v-for="product in products" :value="product">{{ product.product_id[1] }}</option> -->
      <v-select
        v-on:input="changeProductSelected"
        :options="products"
        v-model="productSelected"
        label="optionLabel"
        :placeholder="$t('Zoek een product')"
        class="border-0 border-b-2 border-vw-modalgray"
      >
      </v-select>
    </div>

    <div v-if="!isAvailable && productSelected">
      <p class="py-4">
        {{ $t("We konden geen geldige attributen vinden voor dit product.") }}
      </p>
    </div>

    <div v-show="isAvailable">
      <div class="my-1">
        <label for="model" class="block text-vw-lightgray w-full"
          >{{ $t("Lengte") }} <sup>*</sup></label
        >
        <v-select
          v-on:input="changeAttributeSelected"
          v-model="selectedLength"
          :options="attributesLength"
          :placeholder="$t('Kies een lengte')"
          label="name"
          class="border-0 border-b-2 border-vw-modalgray"
        ></v-select>
        <span class="text-xs mt-2 text-[#ADACAE]"
          >{{ $t("Uitwendig") }}: 230cm</span
        >
      </div>
    </div>
    <div v-show="isAvailable">
      <div class="my-1">
        <label for="model" class="block text-vw-lightgray w-full"
          >{{ $t("Breedte") }} <sup>*</sup></label
        >
        <v-select
          v-on:input="changeAttributeSelected"
          v-model="selectedWidth"
          :options="attributesWidth"
          :placeholder="$t('Kies een breedte')"
          label="name"
          class="border-0 border-b-2 border-vw-modalgray"
        ></v-select>
        <span class="text-xs mt-2 text-[#ADACAE]"
          >{{ $t("Uitwendig") }}: 230cm</span
        >
      </div>
    </div>
    <div v-show="isAvailable">
      <div class="my-1">
        <label for="model" class="block text-vw-lightgray w-full"
          >{{ $t("Interior") }} <sup>*</sup></label
        >
        <v-select
          v-on:input="changeAttributeSelected"
          v-model="selectedInterior"
          :options="attributesInterior"
          :placeholder="$t('Kies een interieur')"
          label="name"
          class="border-0 border-b-2 border-vw-modalgray"
        ></v-select>
      </div>
    </div>

    <div
      v-show="findProductsToDeregisterOrReturn.length && type != 'extension'"
    >
      <div class="my-1">
        <label for="model" class="block text-vw-lightgray w-full"
          >{{ $t("Unieknummer") }} <sup>*</sup></label
        >
        <!-- v-on:input="changeAttributeSelected" -->
        <v-select
          v-on:input="changeAttributeSelectedLot"
          v-model="selectedProductToDeregisterOrReturn"
          :options="findProductsToDeregisterOrReturn"
          :placeholder="$t('Kies een lot')"
          :getOptionLabel="(product) => product.lot_id[1]"
          class="border-0 border-b-2 border-vw-modalgray"
        ></v-select>
      </div>
    </div>
    <div
      v-show="!findProductsToDeregisterOrReturn.length && type != 'extension'"
    >
      <div class="my-1">
        <label for="model" class="block text-vw-lightgray w-full"
          >{{ $t("Unieknummer") }} <sup>*</sup></label
        >
        {{
          $t(
            "We konden geen product vinden met een geldig lot nummer om af te melden."
          )
        }}
      </div>
    </div>

    <div v-if="debug">
      <!--<div class="my-1" v-if="this.productLotId">
      <label for="model" class="block text-vw-lightgray w-full">{{ $t('Unieknummer') }} <sup>*</sup></label>
      <input v-model="productLotId" readonly="true" class="border-0 border-b-2 border-vw-modalgray mb-2 p-1 text-sm text-vw-green w-full"/>
    </div>-->

      {{ this.possibleVariants }}<br />
      ---<br />
      {{ this.productToDeregister }}<br />
      ---<br />
      {{ this.findProductsToDeregisterOrReturn }}<br />
      ---<br />
      {{ this.selectedProductToDeregisterOrReturn }}
    </div>
  </div>
</template>
<script>
import {
  PRODUCT_ATTRIBUTE_INTERIOR_ID,
  PRODUCT_ATTRIBUTE_LENGTH_ID,
  PRODUCT_ATTRIBUTE_WIDTH_ID,
  PRODUCT_VARIANT_ATTRIBUTES,
} from "@/constants";

export default {
  name: "ProductStockFormPartRefactor",
  props: {
    type: null,
  },
  data: () => ({
    debug: false,
    productToDeregisterCache: null,
    productSelected: null,
    variantSelected: null,
    selectedInterior: null,
    selectedLength: null,
    selectedWidth: null,
    selectedProductToDeregisterOrReturn: null,
    productLotId: null,
    PRODUCT_ATTRIBUTE_WIDTH_ID: PRODUCT_ATTRIBUTE_WIDTH_ID,
    PRODUCT_ATTRIBUTE_LENGTH_ID: PRODUCT_ATTRIBUTE_LENGTH_ID,
    PRODUCT_ATTRIBUTE_INTERIOR_ID: PRODUCT_ATTRIBUTE_INTERIOR_ID,
    lotIdsTracker:[]
  }),
  mounted() {
    if (this.type == "deregister") {
      this.products.forEach((product) => {
        if (product.id == this.productToDeregister.product_tmpl_id[0]) {
          // Set the product as selected
          this.productToDeregisterCache = product;
          this.changeProductSelected(product);
          return;
        }
      });
    }
  },
  methods: {
    changeProductSelected(product) {
      this.productSelected = product;
      // console.log(product);
      this.selectedLength = null;
      this.selectedWidth = null;
      this.selectedInterior = null;
      this.productLotId = null;
      // console.log(this.selectedWidth);
      if (
        this.type == "deregister" &&
        this.productSelected == this.productToDeregisterCache
      ) {
        if (this.productToDeregister.lot_id !== undefined) {
          this.productLotId = this.productToDeregister.lot_id[1];
          // console.log(this.productToDeregister);
          console.log(this.productToDeregister);
          this.selectedProductToDeregisterOrReturn = this.productToDeregister;
          this.selectedProductToDeregisterOrReturn.productLableName =
            product.optionLabel;
        }
      }

      if (this.type == "return") {
      }

      this.$emit("change", true);
      this.$store.dispatch(PRODUCT_VARIANT_ATTRIBUTES, {
        variants: this.variantIds,
      });
    },
    changeAttributesByProductAttributes(attributes) {
      // console.log("====");
      // console.log(attributes);
      if (
        !this.selectedLength &&
        !this.selectedLength &&
        !this.selectedInterior
      ) {
        this.selectedLength = this.productAttributes[0].attribute.length;
        this.selectedWidth = this.productAttributes[0].attribute.width;
        this.selectedInterior = this.productAttributes[0].attribute.interior;
      } else {
        this.selectedLength = attributes.length;
        this.selectedWidth = attributes.width;
        this.selectedInterior = attributes.interior;
      }

      this.selectedProductToDeregisterOrReturn.selectedLength =
        this.selectedLength;
      this.selectedProductToDeregisterOrReturn.selectedWidth =
        this.selectedWidth;
      this.selectedProductToDeregisterOrReturn.selectedInterior =
        this.selectedInterior;
    },
    changeAttributeSelected(attribute) {
      this.selectedProductToDeregisterOrReturn.selectedLength =
        this.selectedLength;
      this.selectedProductToDeregisterOrReturn.selectedWidth =
        this.selectedWidth;
      this.selectedProductToDeregisterOrReturn.selectedInterior =
        this.selectedInterior;
      if (this.findProductsToDeregisterOrReturn.length !== this.lotIdsTracker.length) {
        this.selectedProductToDeregisterOrReturn = null;
        this.lotIdsTracker = this.findProductsToDeregisterOrReturn;
      }
      this.$emit("change", true);
      console.log("CHANGED")
    },
    changeAttributeSelectedLot(val) {
      if (val) {
        let productTemplate = this.$store.getters.products.find(
          (i) => val.product_tmpl_id[0] == i.id
        );
        val.product_id.splice(1, 0, productTemplate.optionLabel);
      }
      this.selectedProductToDeregisterOrReturn = val;
      this.changeAttributeSelected();
    },
  },
  computed: {
    productToDeregister() {
      return this.$store.getters.modalData;
    },
    findProductsToDeregisterOrReturn() {
      let productToDeregisterOrReturn = this.stock.filter((stockItem) => {
        if (this.possibleVariants.length && stockItem.available_quantity > 0) {
          return stockItem.product_id["0"] == this.possibleVariants[0].id;
        }
        return false;
      });
      return productToDeregisterOrReturn;
    },
    products() {
      // If this component is used by the Deregister modal,
      // we filter the template products based on the
      // current stock items that are displayed (currently in stock)
      // If this component is used by the Return modal, it
      // should also show the products in current stock
      if (this.type == "deregister" || this.type == "return") {
        let $product_ids = this.$store.getters.stockProductTemplateIds;
        let filtered_products = this.$store.getters.productsWithName.filter(
          (product) => {
            return $product_ids.indexOf(product.id) > -1;
          }
        );
        // console.log(filtered_products);
        return filtered_products;
      }

      return this.$store.getters.productsWithName;
    },
    variants() {
      return Object.values(this.$store.state.productStore.all_product_variants);
    },
    variantIds() {
      if (this.productSelected) {
        return this.productSelected.product_variant_ids;
      }
    },
    attributesInterior() {
      if (this.productAttributes) {
        let interior = [];
        this.productAttributes.map((attr) => {
          let found = false;
          interior.forEach((_interior) => {
            if (_interior.id == attr.attribute.interior.id) {
              found = true;
            }
          });
          if (!found) {
            interior.push(attr.attribute.interior);
          }
        });
        return interior;
      }
    },
    attributesWidth() {
      if (this.productAttributes) {
        let width = [];
        this.productAttributes.map((attr) => {
          let found = false;
          width.forEach((_width) => {
            if (_width.id == attr.attribute.width.id) {
              found = true;
            }
          });
          if (!found) {
            width.push(attr.attribute.width);
          }
        });
        return width;
      }
    },
    attributesLength() {
      if (this.productAttributes) {
        let length = [];
        this.productAttributes.map((attr) => {
          let found = false;
          length.forEach((_length) => {
            if (_length.id == attr.attribute.length.id) {
              found = true;
            }
          });
          if (!found) {
            length.push(attr.attribute.length);
          }
        });
        return length;
      }
    },
    productVariantAttributes() {
      if (this.$store.state.productStore.product_variant_attributes) {
        return this.$store.state.productStore.product_variant_attributes;
      }
    },
    productAttributes() {
      if (this.productSelected) {
        if (this.productVariantAttributes) {
          let productAttributes = Object.values(this.productVariantAttributes);
          return productAttributes.filter((productAttribute) => {
            if (
              productAttribute.attribute.interior != null &&
              productAttribute.attribute.width != null &&
              productAttribute.attribute.length != null
            ) {
              return true;
            }
          });
        }
      }
    },
    possibleVariants: function () {
      if (this.variants) {
        let possibleVariants = [];
        this.variants.map((variant) => {
          if (
            this.selectedWidth &&
            this.selectedLength &&
            this.selectedInterior
          ) {
            if (
              variant.product_template_attribute_value_ids.indexOf(
                this.selectedWidth.id
              ) > -1 &&
              variant.product_template_attribute_value_ids.indexOf(
                this.selectedLength.id
              ) > -1 &&
              variant.product_template_attribute_value_ids.indexOf(
                this.selectedInterior.id
              ) > -1
            ) {
              possibleVariants.push(variant);
            }
          }
        });
        return possibleVariants;
      }
    },
    isAvailable() {
      if (
        this.productSelected &&
        this.attributesInterior &&
        this.attributesWidth &&
        this.attributesLength
      ) {
        if (
          this.attributesInterior.length &&
          this.attributesWidth.length &&
          this.attributesLength.length
        ) {
          return true;
        }
      }
      return false;
    },
    stock() {
      return this.$store.state.stockStore.stock;
    },
  },
  watch: {
    productVariantAttributes(_new, _old) {
      if (this.type == "deregister") {
        if (
          this.productVariantAttributes[this.productToDeregister.product_id[0]]
        ) {
          // console.log(this.productVariantAttributes[this.productToDeregister.product_id[0]]);
          this.changeAttributesByProductAttributes(
            this.productVariantAttributes[
              this.productToDeregister.product_id[0]
            ].attribute
          );
        }
      }
    },
    possibleVariants(_new, _old) {
      if (this.type == "deregister") {
        this.possibleVariants;
      }
    },
  },
};
</script>
