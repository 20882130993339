<template>
    <BaseModal size="account">
        <div class="text-left">

            <form @submit.prevent="save">
                <AccountForm type="edit" :account="account"></AccountForm>

                <button class="btn w-full bg-vw-green text-white py-2 mt-6 rounded-lg" type="submit">{{ $t('Opslaan')
                }}</button>
            </form>
        </div>

    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import AccountForm from "@/components/modals/parts/AccountForm";
import { COMPANY_UPDATE_ACCOUNT } from "@/constants";

export default {
    name: 'EditAccount',
    components: { BaseModal, AccountForm },
    methods: {
        save() {
            this.$store.dispatch(COMPANY_UPDATE_ACCOUNT, { id: this.$route.params.id, data: this.account });
        }
    },
    computed: {
        account() {
            return this.$store.getters.modalData;
        }
    }
}
</script>
