import {
    FILTER_TICKET_COMPANY,
    FILTER_TICKET_ENDDATE,
    FILTER_TICKET_REQUESTER,
    FILTER_TICKET_STARTDATE,
    SEARCH,
    TICKETS
} from "@/constants";
import axios from "axios";

const state = {
    isLoading: false,
    isError: false,
    isMenuOpen: false,
    pageTitle: 'Title',
    allTickets: [],
    filters: {
        query: '',
        requester: [],
        company: [],
        startDate: '',
        endDate: '',
    }
};
const getters = {
    pageTitle: state => state.pageTitle,
    tickets: (state) => {

        let filtering = state.allTickets;

        // Search query
        if (state.filters.query != '') {
            filtering = filtering.filter((ticket) => {
                let found = 0;
                if (ticket.name_user.toLowerCase().indexOf(state.filters.query) > -1 ||
                    ticket.subject.toLowerCase().indexOf(state.filters.query) > -1 ||
                    ticket.ref.toLowerCase().indexOf(state.filters.query) > -1 ||
                    ticket.user.company_name.toLowerCase().indexOf(state.filters.query) > -1
                ) {
                    found++;
                }
                ticket.messages.forEach((message) => {
                    if (message.message.toLowerCase().indexOf(state.filters.query) > -1) {
                        found++;
                    }
                });
                return found;
            });
        }

        // Selectors
        if (state.filters.company.length > 0) {
            filtering = filtering.filter((ticket) => {
                return state.filters.company.includes(ticket.user.company_name);
            });
        }

        if (state.filters.requester.length > 0) {
            filtering = filtering.filter((ticket) => {
                return state.filters.requester.includes(ticket.name_user);
            });
        }

        // Date
        if (state.filters.endDate != '') {
            filtering = filtering.filter((ticket) => {
                var parts = ticket.date.split("-");
                return new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10)) <= Date.parse(state.filters.endDate);
            });
        }
        if (state.filters.startDate != '') {
            filtering = filtering.filter((ticket) => {
                var parts = ticket.date.split("-");
                return new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10)) >= Date.parse(state.filters.startDate);
            });
        }

        return filtering;
    },
    ticketRequesters: (state, getters) => {
        return getters.tickets.map((ticket) => {
            return ticket.name_user;
        }).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    },
    ticketCompanies: (state, getters) => {
        return getters.tickets.map((ticket) => {
            return ticket.user.company_name;
        }).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    },
    ticketUsers: (state, getters) => {
        return getters.tickets.map((ticket) => {
            /* return
            {
              id: ticket.user.id
              name: ticket.user.first_name + ' ' + ticket.user.last_name
            }; */
            return ticket.user;
        });
    },
};
const actions = {
    [SEARCH]({ commit }, payload) {
        commit(SEARCH, payload);
    },
    [TICKETS]({ commit }, payload = {}) {
        var params = '';
        if (Object.keys(payload).length > 0) {
            params = {
                user_id: payload.id
            }
        }
        axios.get('/tickets', {
            params
        }).then((response) => {
            commit(TICKETS, response.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [FILTER_TICKET_REQUESTER]({ commit }, payload) {
        console.log('FILTER_TICKET_REQUESTER', payload);
        commit(FILTER_TICKET_REQUESTER, payload);
    },
    [FILTER_TICKET_COMPANY]({ commit }, payload) {
        commit(FILTER_TICKET_COMPANY, payload);
    },
    [FILTER_TICKET_STARTDATE]({ commit }, payload) {
        commit(FILTER_TICKET_STARTDATE, payload);
    },
    [FILTER_TICKET_ENDDATE]({ commit }, payload) {
        commit(FILTER_TICKET_ENDDATE, payload);
    },
};
const mutations = {
    [TICKETS](state, payload) {
        state.allTickets = payload;
    },
    [SEARCH](state, payload) {
        state.filters.query = payload.toLowerCase();
    },
    [FILTER_TICKET_COMPANY](state, payload) {
        state.filters.company = payload;
    },
    [FILTER_TICKET_REQUESTER](state, payload) {
        console.log('FILTER_TICKET_REQUESTER (mutation)', payload);
        state.filters.requester = payload;
    },
    [FILTER_TICKET_ENDDATE](state, payload) {
        state.filters.endDate = payload;
    },
    [FILTER_TICKET_STARTDATE](state, payload) {
        state.filters.startDate = payload;
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
