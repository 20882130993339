import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';
import { LOGIN_JWT, LOGIN_USER } from "@/constants";

const { VUE_APP_BACKEND_URL } = process.env;

/* Base URL */
axios.defaults.baseURL = VUE_APP_BACKEND_URL;

/* Lazyloading routes */
const Login = () => import('../views/Auth/Login')
const Register = () => import('../views/Auth/Register')
const RegisterComplete = () => import('../views/Auth/RegisterComplete')
const PasswordForgot = () => import('../views/Auth/PasswordForgot')
const PasswordRequested = () => import('../views/Auth/PasswordRequested')
const PasswordSet = () => import('../views/Auth/PasswordSet')
const PasswordNew = () => import('../views/Auth/PasswordNew')
const Home = () => import('../views/Home')

const RequestDashboard = () => import('../views/Admin/Requests/Dashboard')
const RequestSignups = () => import('../views/Admin/Requests/Signups')
const RequestSignupShow = () => import('../views/Admin/Requests/SignupShow')
const RequestAddresses = () => import('../views/Admin/Requests/Addresses')
const RequestDeregister = () => import('../views/Admin/Requests/Deregister')
const RequestOrders = () => import('../views/Admin/Requests/Orders')
const RequestOrderDetail = () => import('../views/Admin/Requests/OrderDetail')
const RequestRetour = () => import('../views/Admin/Requests/Retour')
const RequestDepotExtension = () => import('../views/Admin/Requests/DepotExtension')
const RequestClientConnections = () => import('../views/Admin/Requests/Connections')

const Stock = () => import('../views/Admin/Stock/Stock')
const History = () => import('../views/Admin/History')

const Orders = () => import('../views/Order/Orders')
const OrderDetailPage = () => import('../views/Order/OrderDetailPage')
const OrderDelivery = () => import('../views/Order/OrderDelivery')
const OrderCartOverview = () => import('../views/Order/OrderCartOverview')
const OrderConfirmed = () => import('../views/Order/OrderConfirmed')
const OrderHistory = () => import('../views/Order/OrderHistory')
const OrderHistoryShow = () => import('../views/Order/OrderHistoryShow')

const Depots = () => import('../views/Depot/Depots')
const DepotSettings = () => import('../views/Depot/Settings')
const DepotManage = () => import('../views/Depot/Manage')

const Clients = () => import('../views/Client/Clients')
const ClientManage = () => import('../views/Client/Manage')
const ClientSettings = () => import('../views/Client/Settings')

const Tickets = () => import('../views/Ticket/Tickets')
const TicketShow = () => import('../views/Ticket/Show')
const TicketNew = () => import('../views/Ticket/New')

const Users = () => import('../views/User/Users')

Vue.use(VueRouter)
/*
function guard(to, from, next){
  if(userStore.state.isLoggedIn === true) {
    // or however you store your logged in state
    next(); // allow to enter route
  } else{
    next('/login'); // go to '/login';
  }
}
*/

/**
 * Meta keys:
 * - title: title of page (translation string)
 * - showCart: show cart bage on this page
 * - showFilter: show dedicated filter bar
 * */
const routes = [

  /** Auth **/
  { path: '/', name: 'home', component: Login },
  { path: '/login', name: 'login', component: Login },
  { path: '/register', name: 'register', component: Register },
  { path: '/password/forgot', name: 'passwordForgotregister', component: PasswordForgot, meta: { title: 'titles.password.forgot' } },
  { path: '/password/new', name: 'passwordNewregister', component: PasswordNew },
  { path: '/password/set', name: 'passwordSetregister', component: PasswordSet },
  { path: '/password/requested', name: 'passwordSetregister', component: PasswordRequested },
  { path: '/register/complete', name: 'registerCompleteregister', component: RegisterComplete },

  /** Requests **/
  /** @TODO: Languages **/
  { path: '/dashboard', component: RequestDashboard, meta: { title: 'Dashboard' } },

  { path: '/request/signups', component: RequestSignups, meta: { title: 'Registratie aanvragen' } },
  { path: '/request/signup/:id', component: RequestSignupShow, meta: { title: 'Registratie aanvraag' } },

  { path: '/request/address', component: RequestAddresses, meta: { title: 'Adres aanvragen' } },
  { path: '/request/deregister', component: RequestDeregister, meta: { showFilter: true, title: 'Depot afmeldingen' } },
  { path: '/request/orders', component: RequestOrders, meta: { title: 'Bestellingen' } },
  { path: '/request/orders/:id', component: RequestOrderDetail, meta: { title: 'Bestelling' } },
  { path: '/request/returns', component: RequestRetour, meta: { title: 'Retour aanvragen' } },
  { path: '/request/extensions', component: RequestDepotExtension, meta: { title: 'Depot uitbreiding aanvragen' } },
  { path: '/request/connections', component: RequestClientConnections, meta: { title: 'Klant toevoeging verzoek' } },
  { path: '/request/connections/:id', component: RequestClientConnections, meta: { title: 'Klant toevoeging verzoek' } },

  // Stock
  { path: '/request/stock/deregister', component: RequestDeregister, meta: { title: 'Depot afmeldingen' } },

  /** Home **/
  { path: '/home', name: 'home', component: Home, meta: { title: 'Welkom' } },

  /** Depots **/
  { path: '/depots', name: 'depots', component: Depots, meta: { showFilter: true, title: 'Depot overzicht', showAddDepot: true } },
  { path: '/depot/manage/:id', name: 'depot_manage', component: DepotManage, meta: { title: 'Beheer ' } },
  { path: '/depot/company/:id', name: 'depot_settings', component: DepotSettings, meta: { title: 'Instellingen' } },

  /** Clients */
  { path: '/clients', name: 'clients', component: Clients, meta: { showFilter: true, title: 'Klant overzicht' } },
  { path: '/clients/manage/:id', name: 'client_manage', component: ClientManage, meta: { title: 'Naam klant' } },
  { path: '/clients/setting/:id', name: 'client_setting', component: ClientSettings, meta: { title: 'Client Settings' } },

  /** User settings **/
  { path: '/settings', name: 'settings', component: DepotSettings, meta: { title: 'Instellingen' } },


  /** Orders **/
  { path: '/orders', name: 'orders', component: Orders, meta: { title: 'Bestellen', showCart: true, showFilter: true } },
  { path: '/orders/delivery', name: 'order_delivery', component: OrderDelivery, meta: { title: 'Bestellen', showCart: true } },
  { path: '/orders/cart', name: 'order_cart', component: OrderCartOverview, meta: { title: 'Bestellen', showCart: true } },
  { path: '/orders/confirmation', name: 'order_confirmation', component: OrderConfirmed, meta: { title: 'Bestelling voltooid', showCart: true } },
  { path: '/orders/product/:id', name: 'order_products', component: OrderDetailPage, meta: { title: 'Bestellen', showCart: true } },
  { path: '/order/history', name: 'order_history', component: OrderHistory, meta: { title: 'Besteloverzicht' } },
  { path: '/order/history/:id', name: 'order_detail', component: OrderHistoryShow, meta: { title: 'Besteloverzicht' } },

  /** Stock **/
  { path: '/stock', name: 'stock', component: Stock, meta: { title: 'Huidige voorraad', showFilter: true } },
  { path: '/history', name: 'history', component: History, meta: { title: 'Geschiedenis', showFilter: true } },


  /** Tickets **/
  { path: '/tickets', name: 'tickets', component: Tickets, meta: { showFilter: true, title: 'Tickets' } },
  { path: '/ticket/new', name: 'ticket_new', component: TicketNew, meta: { title: 'Tickets' } },
  { path: '/ticket/:id', name: 'ticket_show', component: TicketShow, meta: { title: 'Tickets' } },

  { path: '/users', name: 'users', component: Users, meta: { showFilter: false, title: 'Users' } },

  /** Admin **/
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/register/complete", "/password/forgot", "/password/new", "/password/set", "/password/requested"];

  const isPublicPage = publicPages.includes(to.path);
  const isLoggedIn = localStorage.getItem("vanwijk-loggedin") === "true";
  const jwtToken = JSON.parse(localStorage.getItem("vanwijk-jwt"));
  if (!isPublicPage && !isLoggedIn) {
    next('/login');
  }
  if (jwtToken?.access_token) {
    // If the JWT access token is present, set it as the default authorization header
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwtToken.access_token}`;
  }





  next();
});

export default router
