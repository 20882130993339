import {
    LOGIN_FAILED,
    GET_USER,
    ADD_USER,
    EDIT_USER,
    LOGIN_USER,
    REGISTER_USER,
    REGISTER_FAILED,
    LOGOUT_USER,
    REFRESH_USER,
    PASSWORD_RESET,
    PASSWORD_SET_NEW,
    LOGIN_RESET,
    IS_LOADING,
    PASSWORD_RESET_INTERNAL,
    LOGIN_AS,
    LOAD_SETTINGS,
    LOAD_SETTINGS_EMPTY,
    COMPANY_READ,
    RESET_PASSWORD_TEXT,
    CLOSE_MODAL
} from '../../constants.js'
import axios from "axios";
import router from "@/router";

const state = {
    user: null,
    isLoggedIn: false,
    error: false,
    errors: null,
    all_permissions: [],
    logged_in_as: false,
    userSettings: null,
    resetEmailText: '',
    admins:[],
    filters: {
        query: '',
        lines: [],
    }
};
const getters = {
    getUserType: state => state.user ? state.user.type : null,
    getErrors: state => state.errors ? state.errors : null,
    hasError: state => state.error ? state.error : false,
    isAuthenticated: state => state.isLoggedIn ? state.isLoggedIn : false,
    getPermissions: state => state.user ? state.user.all_permissions : [],
    getResetEmailText: state => state.resetEmailText,
    checkAccountType: (state) => {
        let account_types = [];
        if (state.user) {
            if (state.user.is_account) { account_types.push('account') }
            if (state.user.is_admin) { account_types.push('admin') }
            if (state.user.is_super_admin) { account_types.push('super-admin'); account_types.push('superadmin') }
            if (state.user.is_client) { account_types.push('client') }
            if (state.user.is_portal) { account_types.push('portal') }
        }
        return account_types;
    },
    isAdmin: (state) => {
        if (state.user) {
            if (state.user.is_admin || state.user.is_super_admin) {
                return true;
            }
        }
    },
    getUserId: (state) => {
        if (state.user) {
            return state.user.user_id;
        }
    },
    getFirstName: state => state.user ? state.user.first_name : false,
    getLastName: state => state.user ? state.user.last_name : false,
    user: state => state.user ? state.user : false,
    getCompanyId: state => state.user ? state.user.odoo_user_id : false,
    getAdmins:state => state.admins ? state.admins : [],
};
const actions = {
    [LOGIN_USER]({ commit }, payload) {

        axios.post('/login', {
            email: payload.email,
            password: payload.password
        }).then(response => {
            if (response.data.access_token) {
                localStorage.setItem('vanwijk-jwt', JSON.stringify(response.data));
                localStorage.setItem('vanwijk-loggedin', true);
                localStorage.setItem('userLog', JSON.stringify(response.data))
            }

            commit(LOGIN_USER, response.data);
        }).catch(error => {
            commit(LOGIN_FAILED, error.response.data);
            //setTimeout — LOGIN_FAILED
        });
    },
    [LOGIN_AS]({ commit }, payload) {
        axios.post('/login/as/' + payload, {
        }).then(response => {
            if (response.data.access_token) {
                localStorage.setItem('vanwijk-jwt-admin', localStorage.getItem('vanwijk-jwt'));
                localStorage.setItem('vanwijk-jwt', JSON.stringify(response.data));
                localStorage.setItem('vanwijk-loggedin', true);
            }

            commit(LOGIN_AS, response.data);
        }).catch(error => {
            commit(LOGIN_FAILED, error.response.data);
            //setTimeout — LOGIN_FAILED
        });
    },
    [LOGIN_RESET]({ commit }, payload) {
        localStorage.setItem('vanwijk-jwt', localStorage.getItem('vanwijk-jwt-admin'));
        localStorage.removeItem('vanwijk-jwt-admin');
        commit(LOGIN_RESET);
    },
    [LOGOUT_USER]({ commit }, payload) {
        return axios.post('/logout', payload).then(function (res) {

            localStorage.removeItem('vanwijk-jwt');
            localStorage.removeItem('vanwijk-loggedin');

            commit(LOGOUT_USER, payload);
            return res;
        }).catch((error) => {
            throw error;
        });
    },
    [LOAD_SETTINGS]({ commit, dispatch }, payload) {
        return axios.get('/user/' + payload.userId).then(response => {
            // console.log(" << ========== >>");
            // console.log(response.data.all_permissions);
            // console.log(" >> ========== <<");
            commit(LOAD_SETTINGS, response.data);
            // console.log(response.data);
            dispatch('COMPANY_READ', { id: response.data.id, type: response.data.odoo_organisation_type_id });
            // dispatch('COMPANY_READ', { id: response.data.odoo_user_id, type: response.data.odoo_organisation_type_id });
            return response;
        }).catch(error => {
            commit(LOAD_SETTINGS, null);
            throw error;
        });
    },
    [LOAD_SETTINGS_EMPTY]({ commit, dispatch }, payload) {
        commit(LOAD_SETTINGS_EMPTY, null);
        return true;
    },
    [REGISTER_USER]({ commit }, payload) {

        return axios.post('/register', payload).then(response => {
            commit(REGISTER_USER, response.data);
            return response;
        }).catch(error => {
            commit(REGISTER_FAILED, error.response.data);
            throw error;
        });

    },

    [REFRESH_USER]({ commit, dispatch }) {
        const key = JSON.parse(localStorage.getItem('vanwijk-jwt'));
        if (!key) {
            router.push('/login');
            return;
        }

        axios.post('/refresh')
            .then(res => {
                const item = {
                    access_token: res.data.access_token,
                    expires_in: new Date().getTime(),
                }
                localStorage.setItem('vanwijk-jwt', JSON.stringify(item));
                localStorage.setItem('logged-in-user', JSON.stringify(res.data));
                commit(LOGIN_USER, res.data);
            }).catch(err => {
                commit(LOGOUT_USER);
                router.push('/login');
            });
    },

    [PASSWORD_RESET]({ commit }, payload) {
        axios.post('/password/reset', { email: payload }).then((res) => {
            router.push('/password/requested?email=' + payload);
        }).catch((err) => {
            commit(LOGIN_FAILED, err.response.data);
            // Whoops something went wrong.
        })
    },

    [PASSWORD_RESET_INTERNAL]({ commit, dispatch }, payload) {
        axios.post('/password/reset', { email: payload }).then((res) => {
            if (res.status == 200) {
                // this.state.resetEmailText = "Mail send successfully";
                commit(RESET_PASSWORD_TEXT, "Mail sent successfully");
            }
            // this.$store.state.userStore.resetEmailText = res.data.message;
            // console.log(this.$store.state.userStore);
        }).catch((err) => {
            commit(LOGIN_FAILED, err.response.data);
            commit(RESET_PASSWORD_TEXT, "Something went wrong");
            // Whoops something went wrong.
        })
    },

    [PASSWORD_SET_NEW]({ commit }, payload) {
        axios.post('/password/new', payload).then((res) => {
            router.push('/password/set');
        }).catch((err) => {
            commit(LOGIN_FAILED, err.response.data);
        })
    },
    [ADD_USER]({ commit, dispatch }, payload) {
        console.log("nknk")
        dispatch('appStore/' + IS_LOADING, true, { root: true });

        axios.post('/user/store', payload)
            .then((res) => {
                dispatch('appStore/' + IS_LOADING, false, { root: true });
                dispatch(CLOSE_MODAL);
                dispatch(GET_USER);
                // dispatch(LOAD_SETTINGS, { userId: payload.company_id });
            })
            .catch((err) => {
                // dispatch('appStore/' + IS_ERROR, err.response.data, { root: true });
                // dispatch('appStore/' + IS_LOADING, false, { root: true });
                console.log(err);
            });
    },
    [EDIT_USER]({ commit, dispatch }, payload) {
        dispatch('appStore/' + IS_LOADING, true, { root: true });

        axios.post('/user/'+payload.id+'/update', payload)
            .then((res) => {
                dispatch('appStore/' + IS_LOADING, false, { root: true });
                dispatch(CLOSE_MODAL);
                dispatch(GET_USER);
                // dispatch(LOAD_SETTINGS, { userId: payload.company_id });
            })
            .catch((err) => {
                // dispatch('appStore/' + IS_ERROR, err.response.data, { root: true });
                // dispatch('appStore/' + IS_LOADING, false, { root: true });
                console.log(err);
            });
    },
    [GET_USER]({ commit, dispatch }, payload){
        return axios.get('/get-all-users',payload).then(response => {
            commit(GET_USER, response.data);
            // return response.data;
        }).catch(error => {
            throw error;
        });
    },
};
const mutations = {
    [LOGIN_USER](state, payload) {
        state.user = payload.user;
        state.isLoggedIn = true;
    },
    [LOGIN_AS](state, payload) {
        state.user = payload.user;
        state.logged_in_as = true;
        state.isLoggedIn = true;
    },
    [LOGIN_RESET](state, payload) {
        state.logged_in_as = false;
    },
    [LOGOUT_USER](state, payload) {
        localStorage.removeItem('vanwijk-jwt');
        localStorage.removeItem('vanwijk-jwt-admin');
        localStorage.removeItem('vanwijk-loggedin');
        state.user = null;
        state.isLoggedIn = false;
        state.logged_in_as = false;
    },
    [LOAD_SETTINGS](state, payload) {
        state.userSettings = payload;
    },
    [LOAD_SETTINGS_EMPTY](state, payload) {
        state.userSettings = null;
    },
    [LOGIN_FAILED](state, payload) {
        state.errors = payload;
        state.error = true;
        setTimeout(function () {
            state.error = false;
        }, 5000);
    },
    [REGISTER_FAILED](state, payload) {
        state.errors = payload;
        state.error = true;
        setTimeout(function () {
            state.error = false;
        }, 5000);
    },
    [REGISTER_USER](state, payload) {
        state.errors = null;
        state.error = false;
    },
    [GET_USER](state, payload) {
        state.admins = payload;
        state.errors = null;
        state.error = false;
    },
    [RESET_PASSWORD_TEXT](state, payload) {
        state.resetEmailText = payload;
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
