import {
    CLOSE_MODAL,
    OPEN_MODAL,
    COMPANY_ADD_ACCOUNT,
    COMPANY_ADDRESSESS,
    COMPANY_READ,
    COMPANY_UPDATE,
    COMPANY_UPDATE_ACCOUNT,
    ACCOUNT_CREATED_SUCCESS,
    COMPANY_WRITE,
    INVOICE_TYPE, IS_ERROR, IS_LOADING, LOAD_SETTINGS, SHIPPING_ADDRESSES, LOGOUT_USER
} from "@/constants";
import axios from "axios";

const state = {
    company: null,
    user: null,
};
const getters = {
    company_user: state => state.user,
    company: state => state.company,
    companyLocations: state => (state.company) ? state.company.children : '',
    companyAddressesRequests: state => (state.user) ? state.user : null,
    companyInvoiceAddress: state => (state.company) ? (state.company.invoice) ? state.company.invoice : [] : [],
    companyAccounts: state => (state.company) ? state.company.accounts : null,
};
const actions = {
    [COMPANY_READ]({ commit, state }, payload) {

        // Support "refresh" company
        if (typeof payload == "undefined") {
            payload = {
                id: state.company.id
            }
        }
        // Support passing company id directly
        if (typeof payload == 'number') {
            payload = {
                id: payload
            }
        }
        axios.post('/odoo/company/' + payload.id)
            .then((res) => {
                commit(COMPANY_READ, res.data);
            }).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    },
    [COMPANY_WRITE]({ commit }, payload) {
        axios.post('/odoo/push/company', { id: payload.id, data: payload.data })
            .then((res) => { console.log(res) }).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    },
    [COMPANY_UPDATE]({ commit }, payload) {
        return axios.post('/request/company/update/' + payload.id, payload.data).then((res) => {
            // Do something with result?
            // Commit
            return res;
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
            throw error;
        });
    },
    [COMPANY_ADD_ACCOUNT]({ commit, dispatch }, payload) {
        dispatch('appStore/' + IS_LOADING, true, { root: true });

        axios.post('/company/account/create', payload)
            .then((res) => {
                dispatch('appStore/' + IS_LOADING, false, { root: true });
                dispatch(CLOSE_MODAL);
                dispatch(OPEN_MODAL, { modal: ACCOUNT_CREATED_SUCCESS });

                // let user_id = payload.id;
                // console.log(payload);
                // if(!user_id){
                //     user_id = payload.data.parent_user_id || payload.data.id
                // }
                // console.log({user_id});
                // dispatch(COMPANY_READ,payload.company_id);
                dispatch(LOAD_SETTINGS, { userId: payload.company_id });
            })
            .catch((err) => {
                console.log(err);
                dispatch('appStore/' + IS_ERROR, err.response.data, { root: true });
                dispatch('appStore/' + IS_LOADING, false, { root: true });
                if (err.response?.status === 401) {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    })
                }
            });
    },
    [COMPANY_UPDATE_ACCOUNT]({ commit, dispatch }, payload) {
        let user_id = payload.id;
        if (!user_id) {
            user_id = payload.data.parent_user_id || payload.data.id
        }
        axios.post('/company/account/update', { company_id: payload.id, ...payload.data })
            .then((res) => {
                // console.log(res,payload);
                dispatch(CLOSE_MODAL);
                // console.log(user_id);
                // dispatch(COMPANY_READ,user_id);
            }).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    }
};
const mutations = {
    [COMPANY_READ](state, payload) {
        console.log(payload)
        state.company = payload;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
