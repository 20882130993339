<template>
  <BaseModal size="medium">
    <div class="text-left grid grid-cols-1 gap-4">
      <div class="my-4">
        <h2 class="text-2xl text-vw-gray futura-bold">
          {{ $t("Adres toevoegen") }}
        </h2>
      </div>

      <div v-if="errors">
        <p class="text-vw-red">
          {{
            $t(
              "Er ging iets mis bij het verwerken van de ingevulde informatie. Contacteer de systeembeheerder en probeer later opnieuw."
            )
          }}
        </p>
      </div>

      <div class="">
        <label class="text-vw-lightgray text-sm b-2">{{
          $t("Bestaand adres uit Odoo toevoegen")
        }}</label>
        <v-select
          v-if="odoo.addresses"
          v-model="odoo_select_address"
          label="name"
          :options="odoo.addresses"
          class="w-full border-0 border-b-2 border-vw-bordergray focus:border-vw-green text-vw-green"
        ></v-select>
        <div class="flex mt-2">
          <a
            href="#"
            class="text-sm mr-1 underline hover:text-vw-green"
            @click="refresh"
            >{{ $t("Lijst vernieuwen") }}</a
          >
          <a
            :href="
              odoo_create_url +
              '/web#menu_id=292&cids=1&action=442&model=res.partner&view_type=form'
            "
            class="text-sm ml-1 underline hover:text-vw-green"
            target="_blank"
            >{{ $t("Nieuw adres aanmaken") }}</a
          >
        </div>
      </div>

      <div class="flex justify-end">
        <a
          @click.stop="add()"
          class="bg-vw-green text-white px-6 py-2 rounded-lg futura-bold w-fit cursor-pointer"
          >{{ $t("Adres toevoegen") }}</a
        >
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import InputField from "@/components/form/InputField";
import axios from "axios";
import {MODAL_REFUSE_ADDRESS_REQUEST, MODAL_ADD_ADDRESS, MODAL_ACCEPT_ADDRESS_REQUEST, OPEN_MODAL,LOAD_SETTINGS} from "@/constants";

export default {
  name: 'AddAddress',
  components: {InputField, BaseModal},
  data: () => ({
    odoo_select_address: null,
    odoo: {
      addresses: null,
    },
    odoo_create_url: process.env.VUE_APP_ODOO_URL
  }),
  mounted() {
    this.loadAddresses();
  },
  methods: {
    loadAddresses(refresh=false){
      axios.post('/odoo/company/addresses'+((refresh)?'?fresh':'')).then((res) => {
        this.odoo.addresses = Object.values(res.data);
      }).catch((error) => {
        if (error.response?.status === 401) {
          if (this.$route.path !== '/login') {
            store.dispatch(LOGOUT_USER, {}).then(() => {
                this.$router.push('/login');
            });
          }
        }
      });
    },
    refresh() {
      this.loadAddresses(true);
    },
    add() {
      this.$store.dispatch(MODAL_ADD_ADDRESS, {
        id: this.odoo_select_address.id,
        user_id: parseInt(this.$route.params.id)
      }).then(() => {
        // this.loadAddresses(true);
        this.$store.dispatch(LOAD_SETTINGS,{userId:parseInt(this.$route.params.id)})
      });
    }
  }
}
</script>
