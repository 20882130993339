import {
    ATTRIBUTES_LOAD,
    FILTER_LINES,
    FILTER_INTERIORS,
    FILTER_SET_PAGE,
    FILTER_SET_PRODUCT_PAGE,
    PRODUCT_LOAD, PRODUCT_VARIANT_ATTRIBUTES,
    PRODUCTS_LOAD,
    STOCKS_DATA_LOAD,
    SEARCH, PRODUCT_VARIANTS, FILTER_PRODUCTS
} from "@/constants";
import axios from "axios";

const _filter_products = (state) => {
    if (state.products) {

        let products = state.products;

        /* Product filter: Lines */
        products = products.filter((product) => {
            let line_filter = false;

            if (state.filters.lines.length > 0) {
                line_filter = true;
                if (state.filters.lines.includes(product.x_studio_kist_lijn)) {
                    return true;
                }
            }

            if (!line_filter) {
                return true;
            }
        });

        /* Product filter: interior */
        products = products.filter((product) => {
            let interior_filter = false;
            let include_product = false;

            if (state.filters.interiors.length > 0) {
                interior_filter = true;
                product.variants.forEach((variant) => {
                    let partner_ref = variant.partner_ref;
                    state.filters.interiors.forEach((interior) => {
                        if (partner_ref.includes(interior)) {
                            include_product = true;
                        }
                    });
                });
            }

            if (!interior_filter) {
                return true;
            }

            if (include_product) {
                return true;
            }
        });

        /* Search query */
        if (state.filters.query.length > 0) {
            products = products.filter((product) => {
                return product['name'].toLowerCase().includes(state.filters.query.toLowerCase());
            });
        }
        /* Show selling products only */
        // products = products.filter((product) => {
        //     return product.x_studio_verkocht_in_portaal == true;
        // });

        //dispatch(FILTER_SET_PAGE, 1)

        return products;
    }
};

const state = {
    products: null,
    product: null,
    product_prices: null,
    variant_sizes: null,
    variant_interior: null,
    product_sizes: null,
    product_interior: null,
    product_variants: null,
    all_product_variants: null,
    product_variant_attributes: null,
    taxes: null,
    filters: {
        search: '',
        sort: 'asc',
        cat_ids: [],
        lines: [],
        interiors: [],
        products: [],
        query: '',
        display: 20,
        page: 1,
    }
};
const getters = {
    productFilter: state => {
        return state.filters;
    },
    taxes: state => state.taxes,
    products: state => state.products,
    product: state => state.product,
    productsWithName: (state) => {
        if (state.products) {
            let productsWithName = [];
            let stripText = '';
            state.products.forEach((product) => {
                product.optionLabel = product['name'];
                if (product['description']) {
                    stripText = product['description'].replace(/<\/?[^>]+(>|$)/g, "");
                    product.optionLabel += ' - ' + stripText;
                }
                if (product.x_studio_verkocht_in_portaal == true) {
                    productsWithName.push(product);
                }
            });
            return productsWithName;
        }
    },
    variant_sizes: state => state.variant_sizes,
    product_variants: state => state.product_variants,
    product_interiors: (state) => {
        if (state.variant_interior) {
            let interiors = [];
            Object.values(state.variant_interior).forEach((variant_interior) => {
                if (!interiors.includes(variant_interior.name) && typeof variant_interior.name == "string") {
                    interiors.push(variant_interior.name);
                }
            });
            return interiors;
        }
    },
    product_lines: (state) => {
        if (state.products) {

            let lines = [];

            state.products.forEach((product) => {
                if (!lines.includes(product.x_studio_kist_lijn) && typeof product.x_studio_kist_lijn == "string") {
                    lines.push(product.x_studio_kist_lijn);
                }
            });

            return lines;
        }
    },
    product_sizes: (state) => {
        if (state.variant_sizes) {
            let sizes = [];
            Object.values(state.variant_sizes).forEach((variant_size) => {
                if (!sizes.includes(variant_size.name) && typeof variant_size.name == "string") {
                    sizes.push(variant_size.name);
                }
            });
            return sizes;
        }
    },
    pages: (state) => {
        if (state.products) {
            return Math.ceil(state.products.length / state.filters.display);
        }
        return 0;
    },
    filter_products: (state) => {
        return _filter_products(state);
    },
    display_products: (state) => {
        console.log('display_products', state.products)
        if (state.products) {

            return _filter_products(state).filter((product, index) => {
                let from = (state.filters.page - 1) * state.filters.display;
                let until = (state.filters.page) * state.filters.display - 1;

                if (index >= from && index <= until) {
                    return true;
                }
            });
        }
    },
    product_variant_ids: (state) => {
        if (state.all_product_variants) {
            let ids = [];
            Object.values(state.all_product_variants).forEach((variant) => {
                ids.push(variant.id);
            });
            return ids;
        }
    }
};
const actions = {
    [SEARCH]({ commit }, payload) {
        commit(SEARCH, payload)
    },
    [FILTER_LINES]({ commit, dispatch }, payload) {
        dispatch(FILTER_SET_PAGE, { page: 1 });
        commit(FILTER_LINES, payload);
    },
    [FILTER_PRODUCTS]({ commit, dispatch }, payload) {
        dispatch(FILTER_SET_PAGE, { page: 1 });
        commit(FILTER_PRODUCTS, payload);
    },
    [FILTER_INTERIORS]({ commit, dispatch }, payload) {
        dispatch(FILTER_SET_PAGE, { page: 1 });
        commit(FILTER_INTERIORS, payload);
    },
    [FILTER_SET_PAGE]({ commit }, payload) {
        commit(FILTER_SET_PAGE, payload);
    },
    [PRODUCTS_LOAD]({ commit }, payload) {

        return axios.all([
            axios.get('/odoo/products'),
            axios.get('/odoo/productvariant/sizes'),
            axios.get('/odoo/productvariant/interior'),
            axios.get('/odoo/taxes'),
            axios.get('/odoo/products/prices')
        ])
            .then(axios.spread(
                (products, sizes, interior, taxes, prices) => {
                    commit(PRODUCTS_LOAD, [products.data, sizes.data, interior.data, taxes.data, prices.data]);
                }
            )).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    },
    [STOCKS_DATA_LOAD]({ commit }, payload) {

        return axios.all([
            axios.get('/odoo/products'),,
        ])
            .then(axios.spread(
                (products) => {
                    commit(STOCKS_DATA_LOAD, [products.data]);
                }
            )).catch((error) => {
                if (error.response?.status === 401) {
                    if (this.$route.path !== '/login') {
                        store.dispatch(LOGOUT_USER, {}).then(() => {
                            this.$router.push('/login');
                        });
                    }
                }
            });
    },
    [PRODUCT_VARIANT_ATTRIBUTES]({ commit }, payload) {
        axios.post('/odoo/products/attributes', payload).then((res) => {
            commit(PRODUCT_VARIANT_ATTRIBUTES, res.data);
        }
        ).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [PRODUCT_VARIANTS]({ commit }, payload) {
        axios.post('/odoo/product/variants', payload).then((res) => {
            commit(PRODUCT_VARIANTS, res.data);
        }
        ).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [PRODUCT_LOAD]({ state, dispatch, commit }, payload) {
        let result = axios.get('/odoo/products/attributes/' + payload).then((res) => {
            commit(ATTRIBUTES_LOAD, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });

        if (!state.products) {
            dispatch(PRODUCTS_LOAD).then(() => {
                let product = state.products.filter((product) => {
                    if (product.id == payload) {
                        commit(PRODUCT_LOAD, product)
                    }
                });
            });
        } else {
            state.products.filter((product) => {
                if (product.id == payload) {
                    commit(PRODUCT_LOAD, product)
                }
            });
        }

        return result;
    }
};
const mutations = {
    [SEARCH](state, payload) {
        state.filters.query = payload;
    },
    [FILTER_LINES](state, payload) {
        state.filters.lines = payload;
    },
    [FILTER_PRODUCTS](state, payload) {
        state.filters.products = payload;
    },
    [FILTER_INTERIORS](state, payload) {
        state.filters.interiors = payload;
    },
    [FILTER_SET_PAGE](state, payload) {
        state.filters.page = payload.page;
    },
    [PRODUCT_VARIANT_ATTRIBUTES](state, payload) {
        state.product_variant_attributes = payload;
    },
    [PRODUCT_VARIANTS](state, payload) {
        state.all_product_variants = payload;
    },
    [PRODUCTS_LOAD](state, payload) {
        state.products = payload[0];
        state.variant_sizes = payload[1];
        state.variant_interior = payload[2];
        state.taxes = payload[3];
        state.product_prices = payload[4];
    },
    [STOCKS_DATA_LOAD](state, payload) {
        state.products = payload[0];
    },
    [ATTRIBUTES_LOAD](state, payload) {
        state.product_variants = payload;
    },
    [PRODUCT_LOAD](state, payload) {
        state.product = payload;

        let sizes = [];
        Object.entries(state.variant_sizes).map((variantSize) => {
            payload.variants.map((variant) => {
                if (variant.id == variantSize[0]) {
                    sizes.push({ variant_id: variant.id, variant_size: variantSize[1] });
                }
            });
        });
        state.product_sizes = sizes;

        let _interior = [];
        state.product_interior = Object.entries(state.variant_interior).map((interior) => {
            payload.variants.map((variant) => {
                if (variant.id == interior[0]) {
                    _interior.push({ variant_id: variant.id, variant_interior: interior[1] });
                }
            })
        });
        state.product_interior = _interior;

    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
