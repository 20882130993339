export default {
  methods: {
    formatNumber(number, removeStyle=false){
      let formatted = Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number);
      if( removeStyle ){
        return formatted;
      }

      return formatted.slice(0,-2) + '<sup>' + formatted.slice(formatted.length-2) + '</sup>';
    }
  }
}
