<template>
    <div class="mt-4">
        <div class="grid grid-cols-6 text-sm items-center gap-4">
            <div class="col-span-2">
                <label class="futura-bold" for="name">{{ $t('Naam') }}</label>
            </div>
            <div class="col-span-4">
                <!-- <input type="text" name="name" id="name" class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1"> -->
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <input type="text" name="first_name" :class="hasError('first_name')" id="firstname"
                            v-model="user.first_name"
                            class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
                    </div>
                    <div>
                        <input type="text" name="last_name" id="lastname" :class="hasError('last_name')"
                            v-model="user.last_name"
                            class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
                    </div>
                </div>
            </div>

            <div class="col-span-2">
                <label class="futura-bold" for="function">{{ $t('Functie') }}</label>
            </div>
            <div class="col-span-4">
                <input type="text" name="function" id="function" :class="hasError('function')" v-model="user.function"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="my-4 border border-t-3 border-vw-bordergray col-span-6"></div>

            <div class="col-span-2" v-show="type != 'add'">
                <span class="futura-bold">{{ $t('Laatste login') }}</span>
            </div>
            <div class="col-span-4" v-show="type != 'add'">
                <span class="flex justify-start items-center">
                    {{ user.last_login_date }}
                    <span class="ml-2 flex text-vw-lightgray">
                        <i
                            class="fa-regular fa-clock text-vw-lightgray group-hover:text-white mr-1 md:mr-2 flex items-center"></i>
                        {{ user.last_login_time }}
                    </span>
                </span>
            </div>

            <div class="col-span-2">
                <span class="futura-bold">{{ $t('Actief') }}</span>
            </div>
            <div class="col-span-4">
                <span class="flex justify-between">
                    <span>
                        <label class="toggle" for="isActive">
                            <input type="checkbox" name="isActive" @change="updateuserStatus" :checked="user.active"
                                id="isActive">
                            <span class="slider"></span>
                        </label>
                    </span>
                    <a class="text-vw-red hover:cursor-pointer ml-2" v-if="type == 'edit'">{{ $t('Archiveren') }}</a>
                </span>
            </div>

            <div class="border border-t-3 border-vw-bordergray col-span-6"></div>

            <div class="col-span-2">
                <label for="email" class="w-1/4 futura-bold m-auto">{{ $t('E-mail') }}</label>
            </div>
            <div class="col-span-4">
                <input v-if="!user.editUser" type="email" name="email" id="email" :class="hasError('email')" v-model="user.email"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
                <input v-else type="email" name="email" id="email" :class="hasError('email')" v-model="user.email"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1" readonly>
            </div>

            <div class="col-span-2">
                <label for="password-" class="w-1/4 futura-bold m-auto">{{ $t('password') }}</label>
            </div>
            <div class="col-span-4">
                <input type="password" name="password" id="password-" :class="hasError('password')" v-model="user.password"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="col-span-2">
                <label for="phone" class="w-1/4 futura-bold m-auto">{{ $t('Telefoon') }}</label>
            </div>
            <div class="col-span-4">
                <input type="tel" name="phone" id="phone" :class="hasError('phone')" v-model="user.phone"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="col-span-2">
                <label for="birthday" class="w-1/4 futura-bold m-auto">{{ $t('Geb. datum') }}</label>
            </div>
            <div class="col-span-4">
                <input type="date" name="birthday" id="birthday" :class="hasError('date_of_birth')"
                    v-model="user.date_of_birth"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>



            <div class="col-span-6">
                <span class="futura-bold">{{ $t('Bevoegdheden') }}</span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('Gebruikerspagina') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="userPage">
                        <input type="checkbox" name="user_page" v-model="permission_user_page" id="userPage">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import InputField from "@/components/form/InputField";
import CheckboxField from "@/components/form/CheckboxField";
import { PASSWORD_RESET, PASSWORD_RESET_INTERNAL, permissions, MODAL_CONFIRMATION, OPEN_MODAL, CLOSE_MODAL } from "@/constants";

export default {
    name: 'UserForm',

    props: {
        type: String,
        user: null,
    },
    data: () => ({
        resetEmailText: true,
    }),
    components: { CheckboxField, InputField, BaseModal, ConfirmationModal, },
    mounted() {
        localStorage.removeItem('user')
        setTimeout(() => {
            this.resetEmailText = false
        }, 4000);
    },
    methods: {
        sendReset() {
            if (this.user.email) {
                this.$store.dispatch(PASSWORD_RESET_INTERNAL, this.user.email);
            }
        },
        updateuserStatus() {
            if (this.user.active) {
                this.user.active = 0;
            } else {
                this.user.active = 1;
            }
        },
        removePermission(permission) {
            if (this.user.all_permissions.length) {
                this.user.all_permissions.forEach((data, index) => {
                    if (data == permission) {
                        this.user.all_permissions.splice(index, 1);
                    }
                });
            }
        },
        hasError(field) {
            return {
                'border-b-red-600': (this.errors[field]),
            }
        },

    },
    computed: {
        errors() {
            return this.$store.state.appStore.errors;
        },
        permission_user_page: {
            get() {
                if (localStorage.getItem('user')) {
                    this.user = JSON.parse(localStorage.getItem('user'));
                }
                console.log(this.user);
                console.log(this.user.all_permissions.indexOf(permissions.user_page), '>>>>>>>>>>>>>>>');
                return (this.user.all_permissions.indexOf(permissions.user_page) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.user.all_permissions.push(permissions.user_page);
                } else {
                    this.removePermission(permissions.user_page);
                }
            }
        },
        permission_stock: {
            get() {
                if (localStorage.getItem('user')) {
                    this.user = JSON.parse(localStorage.getItem('user'));
                }
                return (this.user.all_permissions.indexOf(permissions.view_stock) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.user.all_permissions.push(permissions.view_stock);
                } else {
                    this.removePermission(permissions.view_stock);
                }
            }
        },
        permission_history: {
            get() {
                return (this.user.all_permissions.indexOf(permissions.view_history) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.user.all_permissions.push(permissions.view_history);
                } else {
                    this.removePermission(permissions.view_history);
                }
            }
        },
        permission_order: {
            get() {
                return (this.user.all_permissions.indexOf(permissions.view_order) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.user.all_permissions.push(permissions.view_order);
                } else {
                    this.removePermission(permissions.view_order);
                }
            }
        },
        permission_price: {
            get() {
                return (this.user.all_permissions.indexOf(permissions.view_base_price) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.user.all_permissions.push(permissions.view_base_price);
                } else {
                    this.removePermission(permissions.view_base_price);
                }
            }
        }
    }
}
</script>
