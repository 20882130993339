<template>
    <div class="grid grid-cols-1 text-left">
        <div class="my-1">
            <label for="model" class="block text-vw-lightgray w-full mb-2">Model <sup>*</sup></label>
            <select @change="reloadProductAttributes(product)" name="product" id="product" v-model="product"
                class="border-0 border-b-2 border-vw-modalgray focus:border-vw-green text-sm text-vw-green w-full p-1">
                <option v-for="product in products" :value="product">{{ product.product_id[1] }}</option>
            </select>
        </div>

        <div class="my-1" v-if="attributes">
            <label for="length" class="block text-vw-lightgray w-full mb-2">Inwendige lengte <sup>*</sup></label>
            <select v-if="attributes" disabled="true" name="length" id="length" v-model="length"
                class="border-0 border-b-2 border-vw-modalgray text-sm mb-2 p-1 text-vw-green w-full">
                <option v-for="length, index in attributes[PRODUCT_ATTRIBUTE_LENGTH_ID]" :value="length"
                    :selected="index == 0">
                    {{
                        length.display_name
                    }}
                </option>
            </select>
        </div>
        <div class="my-1" v-if="attributes">
            <label for="width" class="block text-vw-lightgray w-full mb-2">Inwendige breedte <sup>*</sup></label>
            <select name="width" disabled="true" id="width" v-model="width"
                class="border-0 border-b-2 border-vw-modalgray mb-2 p-1 text-sm text-vw-green w-full">
                <option v-for="width in attributes[PRODUCT_ATTRIBUTE_WIDTH_ID]" :value="width">{{
                    width.display_name
                }}
                </option>
            </select>
        </div>
        <div class="my-1" v-if="attributes">
            <label for="interior" class="bblock text-vw-lightgray w-full mb-2">Binnenbekleding <sup>*</sup></label>
            <select v-if="attributes" disabled="true" name="interior" id="interior" v-model="interior"
                class="border-0 border-b-2 border-vw-modalgray mb-2 text-sm p-1 text-vw-green w-full">
                <option v-for="interior in attributes[PRODUCT_ATTRIBUTE_INTERIOR_ID]" :value="interior">
                    {{ interior.display_name }}
                </option>
            </select>
        </div>

        <div class="my-1" v-show="this.$props.lotid != 'false'">
            <label for="model" class="block text-vw-lightgray w-full mb-2">Unieknummer <sup>*</sup></label>
            <input v-model="lot_id" readonly="true"
                class="border-0 border-b-2 border-vw-modalgray mb-2 p-1 text-sm text-vw-green w-full" />
        </div>
    </div>
</template>

<script>
import {
    PRODUCT_ATTRIBUTE_INTERIOR_ID,
    PRODUCT_LOAD,
    PRODUCT_VARIANT_ATTRIBUTES,
    PRODUCT_ATTRIBUTE_LENGTH_ID,
    PRODUCT_ATTRIBUTE_WIDTH_ID, OPEN_MODAL, MODAL_DEREGISTER_DEPOT, MODAL_RETURN_DEPOT, CLOSE_MODAL, CHANGE_MODAL_DATA
} from "@/constants";
import InputField from "@/components/form/InputField";

export default {
    name: 'ProductStockFormPart',
    components: { InputField },
    prop: [
        'product',
        'width',
        'length',
        'interior',
        'lotid',
    ],
    props: {
        lotid: String,
    },
    data: () => ({
        product: null,
        PRODUCT_ATTRIBUTE_WIDTH_ID: PRODUCT_ATTRIBUTE_WIDTH_ID,
        PRODUCT_ATTRIBUTE_LENGTH_ID: PRODUCT_ATTRIBUTE_LENGTH_ID,
        PRODUCT_ATTRIBUTE_INTERIOR_ID: PRODUCT_ATTRIBUTE_INTERIOR_ID,
        length: null,
        width: null,
        interior: null,
        lot_id: null,
        loaded: false,
    }),
    mounted() {
        this.loadProduct();
    },
    methods: {
        async loadProduct() {
            if (this.selectedProduct) {
                this.product = this.selectedProduct;
                if (this.product.lot_id !== undefined) {
                    this.lot_id = this.product.lot_id[1];
                } else {
                    this.lot_id = '';
                }
                this.$store.dispatch(PRODUCT_LOAD, this.product.product_tmpl_id[0]).then((res) => {
                });
            }
        },
        reloadProductAttributes(product) {
            this.$store.dispatch(CHANGE_MODAL_DATA, { product });
            // this.loadProduct();
            this.product = product;
            if (this.product.lot_id !== undefined) {
                this.lot_id = this.product.lot_id[1];
            } else {
                this.lot_id = '';
            }
            this.$store.dispatch(PRODUCT_LOAD, this.product.product_tmpl_id[0]).then((res) => {
                this.$forceUpdate();
            });
        },
        loadAttributes() {
            if (typeof this.variant[0] == "undefined") {
                return false;
            }

            let attributes_of_product = this.variant[0].product_template_attribute_value_ids;

            let findWidth = this.attributes[PRODUCT_ATTRIBUTE_WIDTH_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findWidth[0]) {
                this.width = findWidth[0];
            }

            let findLength = this.attributes[PRODUCT_ATTRIBUTE_LENGTH_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findLength[0]) {
                this.length = findLength[0];
            }

            let findInterior = this.attributes[PRODUCT_ATTRIBUTE_INTERIOR_ID].filter((item) => {
                return attributes_of_product.includes(item.id);
            });

            if (findInterior[0]) {
                this.interior = findInterior[0];
            }

            this.$emit('change', true);
        }
    },
    computed: {
        products() {
            return this.$store.state.stockStore.stock;
        },
        selectedProduct() {
            return this.$store.state.modalStore.data;
        },
        attributes() {
            return (this.$store.state.productStore.product_variants != null) ? this.$store.state.productStore.product_variants.attributes : null;
        },
        variant() {
            if (this.$store.state.productStore.product_variants != null) {
                return this.$store.state.productStore.product_variants.variants.filter((variant) => {
                    return variant.id == this.selectedProduct.product_id[0];
                });
            }
        }
    },
    watch: {
        product(_new, _old) {
        },
        variant(_new, _old) {
            this.loadAttributes();
        }
    }
    /*methods: {
      handleInput (e) {
        this.$emit('input', this.content)
      }
    }*/
}
</script>
