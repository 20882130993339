<template>
    <BaseModal status="success">
        <div>
            <div
                class="flex justify-center items-center border border-[#D1D1D1] text-vw-gray futura-bold p-2 rounded-xl m-auto w-fit">
                <i class="fa-solid fa-truck text-2xl mr-2 text-vw-gray" v-if="selected=='delivery'"></i>
                <i class="fa-solid fa-people-carry-box text-2xl mr-2 text-vw-gray" v-if="selected=='pickup'"></i>

                <select v-model="selected" @change="shippingOptions" class="border-0">
                    <option v-for="option in options" v-bind:value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="" v-if="selected === 'delivery'">
            <div class="my-8">
                <h3 class="text-2xl futura-bold">{{ $t('Bezorgadressen') }}</h3>
            </div>

            <div v-if="addresses">
                <div class="grid md:grid-cols-3 gap-2 md:gap-4">
                    <div class="bg-vw-bordergray rounded-xl text-sm text-vw-lightgray" v-for="adres in addresses">
                        <div class="px-4 py-2 flex justify-between text-left cursor-pointer rounded-xl h-24"
                            :class="{'text-vw-green shadow-vanwijk-item':adres.id===selectedDeliveryAddress}"
                            @click="selectDeliveryAddress(adres)">
                            <p>
                                {{ adres.address.display_name }}<br>
                                {{ adres.address.street_name }}
                                <span v-if="adres.address.street_number">{{ adres.address.street_number }}</span><br>
                                {{ adres.address.zip }} {{ adres.address.city }}
                            </p>
                            <i
                                class="fa-regular fa-trash-can text-vw-lightgray cursor-pointer relative top-0 right-0 m-1 text-xs hover:text-vw-red"></i>
                        </div>
                    </div>

                    <div @click="addAddress" class="bg-vw-bordergray rounded-xl cursor-pointer">
                        <div class="text-vw-green flex items-center justify-between px-16 py-2 h-24">
                            <p> {{$t('Adres toevoeging aanvragen')}}</p>
                            <i class="fa-solid fa-plus text-vw-green"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="text-center m-auto">
                <p>{{ $t('We konden geen adres vinden gekoppeld aan uw account.') }}</p>
            </div>

            <div class="grid md:grid-cols-3 gap-2 md:gap-4 my-8">
                <div class="text-sm text-vw-gray rounded-xl" v-for="option in getShippingOptions">
                    <div class="px-4 py-2 flex justify-between text-center cursor-pointer rounded-xl border-2"
                        @click="selectDelivery(option)"
                        :class="{'border-vw-green shadow-vanwijk-item':option.name === selectedDelivery, 'border-vw-gray':option.name !== selectedDelivery}">
                        <div class="text-center m-auto text-sm">
                            <p class="futura-bold text-base">{{ $t(option.name) }}</p>
                            <p class="text-vw-green py-1">{{ $t('Uiterlijke levering') }} {{ option.date }}</p>
                            <p class="futura-bold" v-html="formatNumber(option.cost)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="" v-if="selected === 'pickup'">
            <div class="my-8">
                <h3 class="text-2xl futura-bold">Ophaallocaties</h3>
            </div>

            <div class="grid md:grid-cols-3 gap-2 md:gap-4 my-8">
                <div class="text-sm text-vw-gray rounded-xl">
                    <div class="px-4 py-2 flex justify-between text-left cursor-pointer rounded-xl border-2">
                        <p class="">
                            Depot Yame<br>
                            Voorbeeldstraat 142<br>
                            1000 Brussel
                        </p>
                        <i class="fa-regular fa-trash-can text-vw-lightgray cursor-pointer relative top-0 right-0 m-1 text-xs hover:text-vw-red"></i>
                    </div>
                </div>
            </div>

            <div class="grid md:grid-cols-8 gap-4 my-8">
                <div class="flex items-center justify-center">
                    <div
                        class="w-8 h-8 p-2 border-2 border-vw-gray rounded-full flex items-center justify-center cursor-pointer">
                        <i class="fa-solid fa-chevron-left text-vw-gray"></i>
                    </div>
                </div>

                <div class="col-span-6 grid grid-cols-6 gap-4">
                    <div class="border-2 border-vw-gray rounded-xl text-center cursor-pointer hover:text-vw-green hover:border-vw-green">
                        <div class="p-4">
                            <p class="futura-bold text-base">ma</p>
                            <p class="text-sm">19 okt</p>
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-center">
                    <div
                        class="w-8 h-8 p-2 border-2 border-vw-gray rounded-full flex items-center justify-center cursor-pointer">
                        <i class="fa-solid fa-chevron-right text-vw-gray"></i>
                    </div>
                </div>
            </div>

            <div class="my-8">
                <div class="grid grid-cols-4 gap-2 shadow-vanwijk-item px-4 py-6 my-8 hover:bg-vw-lightgreen hover:text-white cursor-pointer">
                    <div class="col-span-3 text-left">
                        <input type="radio" name="delivery-time">
                        <label>10:00 - 12:00</label>
                    </div>
                    <div class="text-right">€ 100</div>
                </div>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import { MODAL_ADD_ADDRESS_REQUEST, OPEN_MODAL, SHIPPING_OPTIONS } from "@/constants";
export default {
    name: 'OrderDelivery',
    components: { BaseModal },
    data: () => ({
        selected: 'delivery',
        options: [
            { id: 0, name: 'Kies een optie' },
            { id: 'pickup', name: 'Zelf ophalen' },
            { id: 'delivery', name: 'Laten bezorgen' }
        ]
    }),
    mounted() {
        this.$store.dispatch(SHIPPING_OPTIONS, this.selected);
    },
    methods: {
        shippingOptions() {
            this.$store.dispatch(SHIPPING_OPTIONS, this.selected);
        },
        addAddress(event) {
            event.stopPropagation();
            this.$store.dispatch(OPEN_MODAL, MODAL_ADD_ADDRESS_REQUEST);
        },
    },
    computed: {
        getShippingOptions() {
            return this.$store.state.cartStore.shipping;
        }
    }
}
</script>