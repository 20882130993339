import {
    FILTER_INTERIORS,
    FILTER_LINES,
    FILTER_SET_PAGE_STOCK,
    FILTER_STOCK,
    PRODUCT_VARIANT_ATTRIBUTES,
    SEARCH,
    STOCK, STOCK_FILTER_INTERIORS, STOCK_FILTER_LINES,
    STOCK_LOCATIONS
} from "@/constants";
import axios from "axios";
const _filter_stocks = (state, getters) => {
    if (state.stock) {
        let products = state.stock;
        let products_list = getters.products;

        products_list.forEach((product) => {
            products.forEach((stock) => {
                if (product.id == stock.product_tmpl_id[0]) {
                    stock.x_studio_kist_lijn = product.x_studio_kist_lijn;
                }
            });
        });

        /* Product filter: Lines */
        products = products.filter((product) => {
            let line_filter = false;

            if (state.filters.lines.length > 0) {
                line_filter = true;
                if (state.filters.lines.includes(product.x_studio_kist_lijn)) {
                    return true;
                }
            }

            if (!line_filter) {
                return true;
            }
        });

        /* Search query */
        if (state.filters.query.length > 0) {
            products = products.filter((product) => {
                return product.product_tmpl_id['1'].toLowerCase().includes(state.filters.query.toLowerCase());
            });
        }

        return products;
    }
};

const state = {
    stock: null,
    stock_list: null,
    locations: null,
    attributes: null,
    result: null,
    filterStock: false,
    stock_list_by_template: null,
    filters: {
        search: '',
        query: '',
        sort: 'asc',
        cat_ids: [],
        lines: [],
        display: 20,
        page: 1,
    }
};
const getters = {

    getStock: (state) => state.stock,
    getStockList: (state) => {
        if (state.stock_list) {
            return Object.entries(state.stock_list);
        }
    },
    getStockListByTemplate: (state) => {
        if (state.stock_list_by_template) {
            return Object.entries(state.stock_list_by_template);
        }
    },
    display_stock: (state, getters) => {
        if (state.stock) {
            return _filter_stocks(state, getters).filter((product, index) => {
                let from = (state.filters.page - 1) * state.filters.display;
                let until = (state.filters.page) * state.filters.display - 1;

                if ((index) >= from && (index) <= until) {
                    return true;
                }
            });
        }
    },
    stockProductTemplateIds: (state) => {
        if (state.stock_list) {
            // Retrieve all template ids that are currently in stock
            let product_ids_in_stock = [];

            Object.values(state.stock_list).forEach((stock_item) => {
                stock_item.stock.forEach((stock) => {
                    product_ids_in_stock.push(stock.product_tmpl_id[0]);
                })
            });

            return product_ids_in_stock;
        }
    },
    filter_stocks: (state, getters) => {
        var cl = console.log;

        state.result = _filter_stocks(state, getters);
        cl('filter_stocks', state, getters, _filter_stocks(state, getters))
        return state.result;
    },
    stock_interiors: (state) => {
        if (state.variant_interior) {
            let interiors = [];
            Object.values(state.variant_interior).forEach((variant_interior) => {
                if (!interiors.includes(variant_interior.name) && typeof variant_interior.name == "string") {
                    interiors.push(variant_interior.name);
                }
            });
            return interiors;
        }
    },
    stock_lines: (state, getters) => {
        if (getters.products && state.stock) {

            let lines = [];

            // Our stock product_ids
            let stock_product_ids = [];

            state.stock.forEach((stock) => {
                stock_product_ids.push(stock.product_tmpl_id[0]);
            });

            getters.products.forEach((product) => {
                if (!lines.includes(product.x_studio_kist_lijn) && typeof product.x_studio_kist_lijn == "string") {
                    if (stock_product_ids.includes(product.id)) {
                        lines.push(product.x_studio_kist_lijn);
                    }
                }
            });

            return lines;
        }
    },
};
const actions = {
    [SEARCH]({ commit }, payload) {
        commit(SEARCH, payload)
    },
    [STOCK_FILTER_LINES]({ commit }, payload) {
        commit(STOCK_FILTER_LINES, payload);
    },
    [STOCK_FILTER_INTERIORS]({ commit, dispatch }, payload) {
        dispatch(FILTER_SET_PAGE_STOCK, { page: 1 });
        commit(STOCK_FILTER_INTERIORS, payload);
    },
    [FILTER_SET_PAGE_STOCK]({ commit }, payload) {
        commit(FILTER_SET_PAGE_STOCK, payload);
    },
    [STOCK]({ commit, dispatch }, payload) {
        axios.get('/odoo/stock/' + payload.id).then((res) => {
            commit(STOCK, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });

    },
    [STOCK_LOCATIONS]({ commit,state }) {
        state.locations = null;
        axios.get('/odoo/stock/locations').then((res) => {
            commit(STOCK_LOCATIONS, res.data);
        }).catch((error) => {
            if (error.response?.status === 401) {
                if (this.$route.path !== '/login') {
                    store.dispatch(LOGOUT_USER, {}).then(() => {
                        this.$router.push('/login');
                    });
                }
            }
        });
    },
    [FILTER_STOCK]({ commit, dispatch }, payload) {
        cosnole.log('FILTER_STOCK')
        commit(FILTER_STOCK, payload);
    }
};
const mutations = {
    [FILTER_STOCK](state, payload) {
        state.filterStock = payload;
    },
    [SEARCH](state, payload) {
        state.filters.query = payload;
    },
    [STOCK_FILTER_LINES](state, payload) {
        state.filters.lines = payload;
    },
    [FILTER_SET_PAGE_STOCK](state, payload) {
        state.filters.page = payload.page;
    },
    [STOCK_FILTER_INTERIORS](state, payload) {
        state.filters.interiors = payload;
    },
    [STOCK](state, payload) {
        state.stock = payload.stock;
        state.attributes = payload.attributes;
        state.stock_list = payload.stock_list;
        state.stock_list_by_template = payload.stock_list_by_template;
    },
    [STOCK_LOCATIONS](state, payload) {
        state.locations = payload;
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
