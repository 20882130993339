<template>
    <div
        class="z-50 border-4 border-vw-green items-left justify-center rounded-xl shadow-xl text-left px-4 py-2 text-xs absolute top-14 right-0 bg-white max-w-[600px] max-h-[60vh] overflow-y-scroll">
        <div class="flex flex-row gap-x-2 items-center justify-end">
            <div class="cursor-pointer text-vw-red text-xs mt-2" @click="resetFilters">{{ $t('Reset filters') }}</div>
        </div>
        <div class="block text-vw-gray text-sm">
            <form action="">
                <div class="flex items-center justify-between mt-4">
                    <span class="futura-bold mr-2">Datum</span>
                    <input v-model="startDate" type="date" name="filter" id="startdate"
                        class="border border-[#ADACAE] py-1 px-2">
                    <span class="text-gray futura-bold mx-2">-</span>
                    <input v-model="endDate" type="date" name="filter" id="enddate"
                        class="border border-[#ADACAE] py-1 px-2">
                </div>

                <div class="block mt-2" v-if="isAdmin">
                    <label for="requester" class="futura-bold">{{ $t('Aangevraagd door') }}</label>
                    <div class="border border-[#ADACAE] my-2 rounded-sm" id="group">
                        <div class="flex flex-row gap-2 items-center justify-start border-b border-[#ADACAE] px-2 py-1">
                            <i class="fa-solid fa-magnifying-glass text-vw-green"></i>
                            <input type="text" name="requester" id="requester" class="w-full border-none p-0 text-sm py-1">
                        </div>
                        <div class="border-dashed border-b border-[#ADACAE] px-2 py-1 min-h-[36px]">
                            <template v-for="requester in selected_requester">
                                <div class="inline-flex px-2 py-1 even:mx-1 rounded-lg gap-2 items-center bg-vw-green text-white"
                                    :key="requester.id">
                                    <p>{{ requester }}</p>
                                    <i class="fa-solid fa-xmark text-white cursor-pointer"></i>
                                </div>
                            </template>
                        </div>
                        <div class="h-20 overflow-y-scroll flex flex-col gap-1 p-2">
                            <div v-if="requesters.length > 0">
                                <div class="flex flex-row gap-2 items-center justify-start" v-for="requester in requesters"
                                    :key="requester.id">
                                    <input type="checkbox" v-model="selected_requester" name="requester" :id="requester"
                                        :value="requester" class="checked:bg-vw-green border border-[#ADACAE]">
                                    <label :for="requester">{{ requester }}</label>
                                </div>
                            </div>

                            <div v-else>
                                <div class="flex items-center justify-center">
                                    <span>{{ $t('Er zijn geen items om op te filteren') }}.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block mt-2" v-if="isAdmin">
                    <label for="company" class="futura-bold">{{ $t('Bedrijf') }}</label>
                    <div class="border border-[#ADACAE] my-2 rounded-sm" id="group">
                        <div class="flex flex-row gap-2 items-center justify-start border-b border-[#ADACAE] px-2 py-1">
                            <i class="fa-solid fa-magnifying-glass text-vw-green"></i>
                            <input type="text" name="company" id="company" class="w-full border-none p-0 text-sm py-1">
                        </div>
                        <div class="border-dashed border-b border-[#ADACAE] px-2 py-1 min-h-[36px]">
                            <template v-for="company in selected_company">
                                <div class="inline-flex px-2 py-1 even:mx-1 rounded-lg gap-2 items-center bg-vw-green text-white"
                                    :key="company.id">
                                    <p>{{ company }}</p>
                                    <i class="fa-solid fa-xmark text-white cursor-pointer"></i>
                                </div>
                            </template>
                        </div>
                        <div class="h-20 overflow-y-scroll flex flex-col gap-1 p-2">
                            <div v-if="companies.length > 0">
                                <div class="flex flex-row gap-2  items-center justify-start" v-for="company in companies"
                                    :key="company">
                                    <input type="checkbox" v-model="selected_company" name="company" :id="company"
                                        :value="company" class="checked:bg-vw-green border  border-[#ADACAE]">
                                    <label :for="company">{{ company }}</label>
                                </div>
                            </div>

                            <div v-else>
                                <div class="flex items-center justify-center">
                                    <span>{{ $t('Er zijn geen items om op te filteren') }}.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    CLOSE_MODAL,
    FILTER_LINES,
    FILTER_TICKET_COMPANY,
    FILTER_TICKET_ENDDATE,
    FILTER_TICKET_REQUESTER,
    FILTER_TICKET_STARTDATE, SEARCH
} from "@/constants";

export default {
    name: 'FilterTickets',
    methods: {
        resetFilters() {
            this.$store.dispatch(FILTER_TICKET_STARTDATE, '');
            this.$store.dispatch(FILTER_TICKET_ENDDATE, '');
            this.$store.dispatch(FILTER_TICKET_COMPANY, []);
            this.$store.dispatch(FILTER_TICKET_REQUESTER, []);
            this.$store.dispatch(SEARCH, '');
            this.$emit('clearfilters');
        }
    },
    data: () => ({
        selected_requester: [],
        selected_company: []
    }),
    computed: {
        isAdmin() {
            let userStore = this.$store.state.userStore;
            if (userStore.user) {
                return userStore.user.is_admin || userStore.user.is_super_admin;
            } else {
                let loggedInUser = JSON.parse(localStorage.getItem('logged-in-user'));
                return loggedInUser.user.is_admin || loggedInUser.user.is_super_admin;
            }
        },
        companies() {
            return this.$store.getters.ticketCompanies;
        },
        requesters() {
            return this.$store.getters.ticketRequesters;
        },
        requester: {
            get() {
                console.log('get requester value: ');
                return this.$store.state.ticketStore.filters.requester;
            },
            set(value) {
                console.log('ser requester value: ' + value);
                this.$store.dispatch(FILTER_TICKET_REQUESTER, value);
            }
        },
        company: {
            get() {
                return this.$store.state.ticketStore.filters.company;
            },
            set(value) {
                this.$store.dispatch(FILTER_TICKET_COMPANY, value);
            }
        },
        startDate: {
            get() {
                return this.$store.state.ticketStore.filters.startDate;
            },
            set(value) {
                this.$store.dispatch(FILTER_TICKET_STARTDATE, value);
            }
        },
        endDate: {
            get() {
                return this.$store.state.ticketStore.filters.endDate;
            },
            set(value) {
                this.$store.dispatch(FILTER_TICKET_ENDDATE, value);
            }
        }
    }
}
</script>
