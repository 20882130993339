import axios from "axios";
import {LOAD_PORTAL_CLIENTS} from "@/constants";

const state = {
  portalClients: [],
};
const getters = {
  getPortalClients: state => {
    if( state.portalClients ){

      state.portalClients = state.portalClients.map((client)=>{
        client.option_label = client.name + ' - ' + client.odoo?.display_name;
        return client;
      });

      return state.portalClients;
    }
  }
};
const actions = {
  [LOAD_PORTAL_CLIENTS]({commit}, payload){
    commit(LOAD_PORTAL_CLIENTS, payload);
  }
};
const mutations = {
  [LOAD_PORTAL_CLIENTS](state, payload){
    axios.get('/portal/clients/all').then((res)=>{
      state.portalClients = res.data;
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
