<template>
    <div class="mt-4">
        <div class="grid grid-cols-6 text-sm items-center gap-4">
            <div class="col-span-2">
                <label class="futura-bold" for="name">{{ $t('Naam') }}</label>
            </div>
            <div class="col-span-4">
                <!-- <input type="text" name="name" id="name" class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1"> -->
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <input type="text" name="firstname" :class="hasError('first_name')" id="firstname"
                            v-model="account.first_name"
                            class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
                    </div>
                    <div>
                        <input type="text" name="lastname" id="lastname" :class="hasError('last_name')"
                            v-model="account.last_name"
                            class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
                    </div>
                </div>
            </div>

            <div class="col-span-2">
                <label class="futura-bold" for="function">{{ $t('Functie') }}</label>
            </div>
            <div class="col-span-4">
                <input type="text" name="function" id="function" :class="hasError('function')" v-model="account.function"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="my-4 border border-t-3 border-vw-bordergray col-span-6"></div>

            <div class="col-span-2" v-show="type != 'add'">
                <span class="futura-bold">{{ $t('Laatste login') }}</span>
            </div>
            <div class="col-span-4" v-show="type != 'add'">
                <span class="flex justify-start items-center">
                    {{ account.last_login_date }}
                    <span class="ml-2 flex text-vw-lightgray">
                        <i
                            class="fa-regular fa-clock text-vw-lightgray group-hover:text-white mr-1 md:mr-2 flex items-center"></i>
                        {{ account.last_login_time }}
                    </span>
                </span>
            </div>

            <div class="col-span-2">
                <span class="futura-bold">{{ $t('Actief') }}</span>
            </div>
            <div class="col-span-4">
                <span class="flex justify-between">
                    <span>
                        <label class="toggle" for="isActive">
                            <input type="checkbox" name="isActive" @change="updateAccountStatus" :checked="(account.first_name!='') ? (account.active) ? 1 : 0 : 1"
                                id="isActive">
                            <span class="slider"></span>
                        </label>
                    </span>
                    <a class="text-vw-red hover:cursor-pointer ml-2" v-if="type == 'edit'">{{ $t('Archiveren') }}</a>
                </span>
            </div>

            <div class="border border-t-3 border-vw-bordergray col-span-6"></div>

            <div class="col-span-2">
                <label for="email" class="w-1/4 futura-bold m-auto">{{ $t('E-mail') }}</label>
            </div>
            <div class="col-span-4">
                <input type="email" name="email" id="email" :class="hasError('email')" v-model="account.email"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="col-span-2">
                <label for="phone" class="w-1/4 futura-bold m-auto">{{ $t('Telefoon') }}</label>
            </div>
            <div class="col-span-4">
                <input type="tel" name="phone" id="phone" :class="hasError('phone')" v-model="account.phone"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="col-span-2">
                <label for="birthday" class="w-1/4 futura-bold m-auto">{{ $t('Geb. datum') }}</label>
            </div>
            <div class="col-span-4">
                <input type="date" name="birthday" id="birthday" :class="hasError('date_of_birth')"
                    v-model="account.date_of_birth"
                    class="border-0 border-b-2 border-[#E1E1E1] w-full focus:border-vw-green text-sm p-1">
            </div>

            <div class="col-span-6" v-if="this.type == 'edit'">

                <a class="text-vw-red hover:cursor-pointer m-auto text-sm capitalize futura-bold" @click="sendEmail">{{
                    $t('Verzend wachtwoord reset instructies') }}</a>
                <div v-if="this.$store.getters.getResetEmailText !== ''" v-show="resetEmailText">
                    <p class="text-vw-green text-base">{{ this.$store.getters.getResetEmailText }}</p>
                </div>
            </div>

            <div class=" border border-t-3 border-vw-bordergray col-span-6">
            </div>

            <div class="col-span-6">
                <span class="futura-bold">{{ $t('Bevoegdheden') }}</span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('Voorraad') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="stock">
                        <input type="checkbox" name="stock" v-model="permission_stock" id="stock">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('Geschiedenis') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="history">
                        <input type="checkbox" name="history" value="1" v-model="permission_history" id="history">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('Bestellen') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="order">
                        <input type="checkbox" name="order" v-model="permission_order" id="order">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('instelling') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="tarifs">
                        <input type="checkbox" name="tarifs" v-model="permission_setting" id="tarifs">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>

            <div class="col-span-3 grid grid-cols-2 gap-2">
                <span>{{ $t('app access') }}</span>
                <span class="flex justify-end">
                    <label class="toggle" for="app_access">
                        <input type="checkbox" name="app_access" v-model="permission_app_access" id="app_access">
                        <span class="slider"></span>
                    </label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import InputField from "@/components/form/InputField";
import CheckboxField from "@/components/form/CheckboxField";
import { PASSWORD_RESET, PASSWORD_RESET_INTERNAL, permissions, MODAL_CONFIRMATION, OPEN_MODAL, CLOSE_MODAL } from "@/constants";

export default {
    name: 'AccountForm',

    props: {
        type: String,
        account: null,
    },
    data: () => ({
        resetEmailText: true,
    }),
    components: { CheckboxField, InputField, BaseModal, ConfirmationModal, },
    mounted() {

        localStorage.removeItem('account')
        setTimeout(() => {
            this.resetEmailText = false
        }, 4000);
    },
    methods: {
        sendReset() {
            if (this.account.email) {
                this.$store.dispatch(PASSWORD_RESET_INTERNAL, this.account.email);
            }
        },
        updateAccountStatus() {
            if (this.account.active) {
                this.account.active = 0;
            } else {
                this.account.active = 1;
            }
        },
        removePermission(permission) {
            if (this.account.all_permissions.length) {
                this.account.all_permissions.forEach((data, index) => {
                    if (data == permission) {
                        this.account.all_permissions.splice(index, 1);
                    }
                });
            }
        },
        hasError(field) {
            return {
                'border-b-red-600': (this.errors[field]),
            }
        },
        sendEmail() {
            localStorage.setItem('email', this.account.email);
            localStorage.setItem('account', JSON.stringify(this.account));
            this.$store.dispatch(CLOSE_MODAL);
            setTimeout(() => {
                this.$store.dispatch(OPEN_MODAL, { modal: MODAL_CONFIRMATION, data: {} });
            }, 500);
        }
    },
    computed: {
        errors() {
            return this.$store.state.appStore.errors;
        },
        permission_stock: {
            get() {
                if (localStorage.getItem('account')) {
                    this.account = JSON.parse(localStorage.getItem('account'));
                }
                return (this.account.all_permissions.indexOf(permissions.view_stock) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.view_stock);
                } else {
                    this.removePermission(permissions.view_stock);
                }
            }
        },
        permission_history: {
            get() {
                return (this.account.all_permissions.indexOf(permissions.view_history) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.view_history);
                } else {
                    this.removePermission(permissions.view_history);
                }
            }
        },
        permission_order: {
            get() {
                return (this.account.all_permissions.indexOf(permissions.view_order) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.view_order);
                } else {
                    this.removePermission(permissions.view_order);
                }
            }
        },
        permission_setting: {
            get() {
                return (this.account.all_permissions.indexOf(permissions.view_setting) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.view_setting);
                } else {
                    this.removePermission(permissions.view_setting);
                }
            }
        },
        permission_app_access: {
            get() {
                return (this.account.all_permissions.indexOf(permissions.app_access) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.app_access);
                } else {
                    this.removePermission(permissions.app_access);
                }
            }
        },
        permission_price: {
            get() {
                return (this.account.all_permissions.indexOf(permissions.view_base_price) > -1) ? true : false;
            },
            set(val) {
                if (val) {
                    this.account.all_permissions.push(permissions.view_base_price);
                } else {
                    this.removePermission(permissions.view_base_price);
                }
            }
        }
    }
}
</script>
