<template>
    <div class="fixed top-0 left-0 w-screen h-screen" style="background-color: rgba(0,0,0,0.40)">
        <div v-click-outside="outSideClick"
            class="absolute border-2 box-shadow-lg z-50 bg-white rounded-md mx-auto confirmation-popup"
            :class="bindClasses">
            <div>
                <div class="absolute right-3 top-2">
                    <i class="text-xl fa-solid fa-xmark text-[#ADACAE] hover:text-[#666666] hover:cursor-pointer"
                        @click="closeModal"></i>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { CLOSE_MODAL,CLOSE_ADD_CUSTOMER_MODAL } from "@/constants";
export default {
    name: 'BaseModal',
    props: {
        status: String,
        size: String,
        type: String,
    },
    methods: {
        closeModal() {
                this.$store.dispatch(CLOSE_MODAL);
        }
    },
    computed: {
        bindClasses() {
            return {
                'border border-vw-green border-4 flex shadow-md rounded-xl text-sm flex flex-col p-2 md:px-4 md:pt-3': this.status == 'success' || typeof this.status === 'undefined',
                'border border-vw-red border-4 flex shadow-md rounded-xl text-sm flex flex-col p-2 md:px-4 md:pt-3': this.status == 'error',
                // 'w-10/12 sm:w-1/2 lg:w-1/3 xl:w-1/5': typeof this.size === 'undefined',
                'w-10/12 sm:w-1/2 lg:w-1/3 xl:w-1/5': this.size == 'small' || typeof this.size === 'undefined',
                'w-10/12 sm:w-1/2 lg:w-1/3 2xl:w-1/4': this.size == 'account',
                'w-10/12 md:w-1/2 lg:w-1/3': this.size == 'medium',
                'overflow-y-auto': this.type == 'stock',
            }
        },
        checkSize() {

        },
        outSideClick(){
            return this.$store.getters.activeCustomerModal ? ()=>{} : this.closeModal;
        }
    }
}
</script>
