<template>
    <BaseModal size="small" status="success">
        <div class="flex justify-center flex-col items-center">
            <i class="fa-regular fa-circle-check text-vw-green text-4xl mb-4"></i>
            <h3 class="text-xl text-vw-green mb-2 futura-bold">{{ $t('Succesvol') }}</h3>
            <p class="my-2 text-vw-gray">{{ $t('Uw depot uitbreiding is succesvol aangevraagd')}}</p>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
    name: 'ExtendDepotSuccess',
    components: { BaseModal },
}
</script>
