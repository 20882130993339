import {
  IS_LOADING,
  GET_REQUEST_DEPOT_RETURN,
  POST_REQUEST_DEPOT_RETURN,
  CONFIRM_REJECT_REQUEST_DEPOT_RETURN,
  GET_HISTORY_DEPOT_RETURN,
  OPEN_MODAL, MODAL_RETURN_DELETE_SUCCESS
} from "@/constants";
import axios from "axios";

const state = {
  returnRequest:null,
  returnHistoryState:null,
};
const getters = {
  pageTitle: state => state.pageTitle,
  returnRequest: state => state.returnRequest ? state.returnRequest : null,
  returnHistory: state => state.returnHistoryState ? state.returnHistoryState.data : null,
};
const actions = {
  [GET_REQUEST_DEPOT_RETURN]({commit}){
    return axios.get('/request/return').then((res) => {
      commit(GET_REQUEST_DEPOT_RETURN, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [POST_REQUEST_DEPOT_RETURN]({commit}, payload){
    const formData = new FormData();
    if (payload.files.length) {
      Object.values(payload.files).forEach((file) => {
        formData.append('file[]', file);
      })
    }
    formData.append('product_id', payload.product_id);
    formData.append('product_data', payload.product_data);
    formData.append('reason', payload.reason);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.post('/request/return', formData, { headers }).then((res) => {
      commit(POST_REQUEST_DEPOT_RETURN, res.data);
      return res;
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
      throw error;
    });
  },
  [CONFIRM_REJECT_REQUEST_DEPOT_RETURN]({commit, dispatch}, payload){
    axios.post('/request/return/confirmOrReject', payload).then((res)=>{
        commit(CONFIRM_REJECT_REQUEST_DEPOT_RETURN, res.data);
        dispatch(OPEN_MODAL, MODAL_RETURN_DELETE_SUCCESS);
      }
    ).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
  [GET_HISTORY_DEPOT_RETURN]({commit}){
    return axios.get('/request/return/history').then((res) => {
      commit(GET_HISTORY_DEPOT_RETURN, res.data);
    }).catch((error) => {
      if (error.response?.status === 401) {
        if (this.$route.path !== '/login') {
          store.dispatch(LOGOUT_USER, {}).then(() => {
            this.$router.push('/login');
          });
        }
      }
    });
  },
};
const mutations = {
  [GET_REQUEST_DEPOT_RETURN](state, payload){
    state.returnRequest = payload;
  },
  [POST_REQUEST_DEPOT_RETURN](state, payload){
    state.POST_REQUEST_DEPOT_RETURN = payload;
  },
  [CONFIRM_REJECT_REQUEST_DEPOT_RETURN](state, payload){
    state.CONFIRM_REJECT_REQUEST_DEPOT_RETURN = payload;
  },
  [GET_HISTORY_DEPOT_RETURN](state, payload){
    state.returnHistoryState = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
