/** Login **/
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_JWT = "LOGIN_JWT";
export const LOGIN_AS = "LOGIN_AS";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGOUT_USER = 'LOGOUT_USER';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_INTERNAL = 'PASSWORD_RESET_INTERNAL';
export const PASSWORD_SET_NEW = 'PASSWORD_SET_NEW';
export const REGISTER_USER = 'REGISTER_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const RESET_PASSWORD_TEXT = 'RESET_PASSWORD_TEXT';

/** APP **/
export const IS_LOADING = "IS_LOADING";
export const IS_ERROR = "IS_ERROR";
export const USER = "USER";

/** Requests **/
export const REQUEST_LOAD_ALL = "REQUEST_LOAD_ALL";
export const REQUEST_SIGNUPS = "REQUEST_SIGNUPS";
export const REQUEST_LOAD = "REQUEST_LOAD";
export const REQUEST_SAVE = "REQUEST_SAVE";
export const REQUEST_ORDERS = "REQUEST_ORDERS";
export const REQUEST_CLIENTS = "REQUEST_CLIENTS";
export const REQUEST_CLIENT_REMOVE = "REQUEST_CLIENT_REMOVE";
export const REQUEST_CLIENT_ACCEPT = "REQUEST_CLIENT_ACCEPT";
export const LOAD_ADDRESSES = "LOAD_ADDRESSES";
export const REQUEST_DEREGISTERS = "REQUEST_DEREGISTERS";
export const REQUEST_DEREGISTER = "REQUEST_DEREGISTER";
export const REQUEST_DEREGISTER_HISTORY = "REQUEST_DEREGISTER_HISTORY";
export const REQUEST_DEREGISTER_ACCEPT = "REQUEST_DEREGISTER_ACCEPT";
export const REQUEST_DEREGISTER_DECLINE = "REQUEST_DEREGISTER_DECLINE";
export const REQUEST_RETURN_ACCEPT = "REQUEST_RETURN_ACCEPT";
export const REQUEST_RETURN_DECLINE = "REQUEST_RETURN_DECLINE";
export const REQUEST_DEREGISTER_SUBMITTED = "REQUEST_DEREGISTER_SUBMITTED";
export const GET_REQUEST_DEPOT_EXTENSION = "GET_REQUEST_DEPOT_EXTENSION";
export const GET_HISTORY_DEPOT_EXTENSION = "GET_HISTORY_DEPOT_EXTENSION";
export const GET_REQUEST_DEPOT_RETURN = "GET_REQUEST_DEPOT_RETURN";
export const GET_HISTORY_DEPOT_RETURN = "GET_HISTORY_DEPOT_RETURN";

export const POST_REQUEST_DEPOT_RETURN = "POST_REQUEST_DEPOT_RETURN";
export const CONFIRM_REJECT_REQUEST_DEPOT_RETURN = "CONFIRM_REJECT_REQUEST_DEPOT_RETURN";

export const POST_REQUEST_DEPOT_EXTENSION = "POST_REQUEST_DEPOT_EXTENSION";
export const CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION = "CONFIRM_REJECT_REQUEST_DEPOT_EXTENSION";
/** Company **/
export const COMPANY_READ = "COMPANY_READ";
export const COMPANY_WRITE = "COMPANY_WRITE";
export const COMPANY_UPDATE = "COMPANY_UPDATE";
export const COMPANY_ADDRESS_REMOVE = "COMPANY_ADDRESS_REMOVE";
//export const COMPANY_ADDRESSESS = "COMPANY_ADDRESSESS";
export const COMPANY_UPDATE_ACCOUNT = 'COMPANY_UPDATE_ACCOUNT';
export const COMPANY_ADD_ACCOUNT = 'COMPANY_ADD_ACCOUNT';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const GET_USER = 'GET_USER';

/** Errors **/
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REGISTER_FAILED = "REGISTER_FAILED";

/** Modals actions **/
export const OPEN_MODAL = "OPEN_MODAL";
export const OPEN_ADD_CUSTOMER_MODAL = "OPEN_ADD_CUSTOMER_MODAL";
export const CHANGE_MODAL_DATA = "CHANGE_MODAL_DATA";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ADD_CUSTOMER_MODAL = "CLOSE_ADD_CUSTOMER_MODAL";

/** Products **/
export const PRODUCTS_LOAD = "PRODUCTS_LOAD";
export const STOCKS_DATA_LOAD = "STOCKS_DATA_LOAD";
export const PRODUCT_LOAD = "PRODUCT_LOAD";
export const PRODUCT_VARIANT_ATTRIBUTES = "PRODUCT_VARIANT_ATTRIBUTES";
export const PRODUCT_VARIANTS = "PRODUCT_VARIANTS";
export const ATTRIBUTES_LOAD = "ATTRIBUTES_LOAD";

export const PRODUCT_ATTRIBUTE_LENGTH_ID = 7;
export const PRODUCT_ATTRIBUTE_WIDTH_ID = 8;
export const PRODUCT_ATTRIBUTE_INTERIOR_ID = 9;

/** Filters **/
export const FILTER = 'FILTER';
export const FILTER_LINES = 'FILTER_LINES';
export const STOCK_FILTER_LINES = 'STOCK_FILTER_LINES';
export const FILTER_INTERIORS = 'FILTER_INTERIORS';
export const FILTER_SIZES = 'FILTER_SIZES';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const STOCK_FILTER_INTERIORS = 'STOCK_FILTER_INTERIORS';
export const FILTER_SET_PAGE = "FILTER_SET_PAGE";
export const FILTER_SET_PAGE_STOCK = "FILTER_SET_PAGE_STOCK";
export const FILTER_SET_PAGE_DEREGISTER = "FILTER_SET_PAGE_DEREGISTER";

export const FILTER_TICKET_COMPANY = "FILTER_TICKET_COMPANY";
export const FILTER_TICKET_REQUESTER = "FILTER_TICKET_REQUESTER";
export const FILTER_TICKET_STARTDATE = "FILTER_TICKET_STARTDATE";
export const FILTER_TICKET_ENDDATE = "FILTER_TICKET_ENDDATE";

export const FILTER_STOCK = "FILTER_STOCK";

export const SEARCH = "SEARCH";

/** Tickets **/
export const TICKETS = "TICKETS";

/** Clients **/
export const LOAD_CLIENTS = 'LOAD_CLIENTS';

export const LOAD_PORTAL_CLIENTS = 'LOAD_PORTAL_CLIENTS';

/** Stock **/
export const STOCK = 'STOCK';
export const USERS = 'USER';
export const STOCK_LOCATIONS = 'STOCK_LOCATIONS';

/** Cart **/
export const CART_ADD = "CART_ADD";
export const CART_TOTAL = "CART_TOTAL";
export const CART_REMOVE = "CART_REMOVE";
export const CART_SET_DELIVERY = "CART_SET_DELIVERY";
export const CART_SET_PICKUP = "CART_SET_PICKUP";
export const CART_UPDATE = "CART_UPDATE";
export const CART_ORDER = "CART_ORDER";
export const CART_SET_NOTE = "CART_SET_NOTE";
export const CART_REORDER = "CART_REORDER";

/** Pagination **/
export const PAGINATION_SET_PAGE = "PAGINATION_SET_PAGE";
export const PAGINATION_SET_STORE = "PAGINATION_SET_STORE";
export const PAGINATION_SET_DISPLAY_COUNT = "PAGINATION_SET_DISPLAY_COUNT";

/** Orders **/
export const ORDERS = "ORDERS";
export const ORDER_LOAD = "ORDER_LOAD";


/** Delivery **/
export const SHIPPING_OPTIONS = "SHIPPING_OPTIONS";
export const SHIPPING_ADDRESSES = "SHIPPING_ADDRESSES";
export const SHIPPING_SELECT_DELIVERY = "SHIPPING_SELECT_DELIVERY";
export const SHIPPING_SELECT_DELIVERY_ADDRESS = "SHIPPING_SELECT_DELIVERY_ADDRESS";

export const SHIPPING_SELECT_PICKUP_LOCATION = "SHIPPING_SELECT_PICKUP_LOCATION";
export const SHIPPING_SELECT_PICKUP_DATE = "SHIPPING_SELECT_PICKUP_DATE";
export const SHIPPING_SELECT_PICKUP_HOUR = "SHIPPING_SELECT_PICKUP_HOUR";

export const RESET_SHIPPING = 'RESET_SHIPPING';

/** Modals **/
export const MODAL_ADD_ADDRESS = 'AddAddress';
export const MODAL_ADD_ADDRESS_REQUEST = 'AddAddressRequest';
export const MODAL_HANDLE_ADDRESS_REQUEST = 'HandleAddressRequest';
export const MODAL_CONFIRM_REMOVE_ADDRESS = 'ConfirmRemoveAddress';
export const MODAL_ADD_ADDRESS_REQUEST_COMPLETE = 'AddAddressRequestComplete';
export const MODAL_REFUSE_ADDRESS_REQUEST = 'RefuseAddressRequest';
export const MODAL_ACCEPT_ADDRESS_REQUEST = 'AcceptAddressRequest';
export const MODAL_ADD_ACCOUNT = 'AddAccount';
export const MODAL_ADD_USER = 'AddUser';
export const MODAL_CONFIRMATION = 'ConfirmationModal';
export const MODAL_EDIT_ACCOUNT = 'EditAccount';
export const MODAL_ADD_CLIENT = 'AddClientAdmin';
export const MODAL_ADD_CLIENT_REQUEST = 'AddClient';
export const MODAL_ADD_CLIENT_REQUEST_ = 'AddClient_';
export const CLIENT_RESET = 'CLIENT_RESET';
export const MODAL_REMOVE_CLIENT = 'RemoveClient';
export const MODAL_REMOVE_CLIENT_CONFIRM = 'RemoveClientConfirm';
export const MODAL_HISTORY_DETAIL = 'HistoryDetail';
//export const MODAL_EDIT_CLIENT = 'EditClient';
export const MODAL_EXTEND_ORDER = 'ExtendOrder';

export const MODAL_DEREGISTER_DEPOT = 'DeregisterDepot';
export const MODAL_DEREGISTER_SUBMITTED = "DeregisterSubmitted";
export const MODAL_EXTEND_DEPOT = 'ExtendDepot';
export const MODAL_EXTEND_DEPOT_SUCCESS = 'ExtendDepotSuccess';
export const MODAL_EXTEND_DEPOT_ACCEPT = 'ExtendDepotAccept';
export const MODAL_EXTEND_DEPOT_REFUSE = 'ExtendDepotRefuse';
export const MODAL_RETURN_DEPOT = 'ReturnDepot';
export const MODAL_RETURN_DEPOT_SUCCESS = 'ReturnDepotSuccess';
export const MODAL_RETURN_ACCEPT = 'ReturnAccept';
export const MODAL_RETURN_DELETE = 'ReturnDelete';
export const MODAL_RETURN_DELETE_SUCCESS = 'ReturnDeleteSuccess';

export const MODAL_DEREGISTER_DELETED = "DeregisterDelete";
export const MODAL_DEREGISTER_ACCEPT = "DeregisterAccept";
export const MODAL_ORDER_DELIVERY = "OrderDeliveryModal";
export const MODAL_ORDER_RETOUR = "OrderRetour";

export const MODAL_SIGNUP_INFO = "SignupInfo";
export const MODAL_SIGNUP_SUCCESS = "SignupAccept";
export const MODAL_SIGNUP_DELETE = "SignupDelete";

/** Depots **/
export const LOAD_DEPOTS = "LOAD_DEPOTS";

/** Settings **/
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_SETTINGS_EMPTY = 'LOAD_SETTINGS_EMPTY';

/** Internal **/
export const PAGE_TITLE = 'PAGE_TITLE';
export const STORAGE_URL = 'http://backend-vanwijk.lndo.site:8000/storage/';
export const TYPE_DEPOT = 22;
export const TYPE_DEPOT_SECOND = 17;

/** Roles & Permissions **/
export const permissions = {
    'view_dashboard': 'view dashboard',
    'view_history': 'view history',
    'view_sale_price': 'view sale price',
    'view_base_price': 'view base price',
    'view_ticket': 'view ticket',
    'view_stock': 'view stock',
    'view_order': 'view order',
    'view_setting': 'view setting',
    'user_page': 'user page',
    'app_access': 'app access',

    // Global permissions
    "tickets": "tickets",
    "history": "history",
    "orders": "orders",
    "stock": "stock",

    // Roles
    "van_wijk": 'van-wijk',
    "super_admin": 'super-admin',
    "portal": "portal",
    "portal_account": "portal_account",
    "portal_client": "portal_client"
}

/** Definiations **/
export const INVOICE_TYPE = 'invoice';

export const ROLE_VANWIJK = ['view dashboard'];
export const ROLE_COMPANY = [];
export const ROLE_COMPANY_USER = [];
export const ROLE_COMPANY_CLIENT = [];


// Success modal (account created)
export const ACCOUNT_CREATED_SUCCESS = 'AccountCreated'