<template>
  <div
    class="fixed top-0 left-0 w-screen h-screen"
    style="background-color: rgba(0, 0, 0, 0.4)"
  >
    <div
      class="absolute border-2 box-shadow-lg z-50 bg-white rounded-md mx-auto confirmation-popup"
      :class="bindClasses"
      v-click-outside="outSideClick"
    >
      <div>
        <div class="absolute right-3 top-2">
          <i
            class="text-xl fa-solid fa-xmark text-[#ADACAE] hover:text-[#666666] hover:cursor-pointer"
            @click="closeModal"
          ></i>
        </div>
        <div class="text-left grid grid-cols-1 gap-y-3 md:gap-y-6">
          <h2 class="text-xl md:text-2xl text-vw-gray futura-bold my-2">
            {{ $t("Klant toevoeging aanvragen") }}
          </h2>
          <div class="grid md:grid-cols-2 gap-y-6 gap-x-6 md:gap-x-24">
            <div>
              <label for="company" class="text-xs text-vw-lightgray">{{
                $t("Klantnaam")
              }}</label>
              <input
                v-model="client.name"
                type="text"
                name="company"
                id="company"
                class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green focus:border-vw-green p-1"
              />
            </div>
            <div>
              <label for="website" class="text-xs text-vw-lightgray">{{
                $t("Website")
              }}</label>
              <input
                v-model="client.website"
                type="url"
                name="website"
                id="website"
                class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green focus:border-vw-green p-1"
              />
            </div>
            <div>
              <label for="city" class="text-xs text-vw-lightgray">{{
                $t("Plaats")
              }}</label>
              <input
                v-model="client.city"
                type="text"
                name="city"
                id="city"
                class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green focus:border-vw-green p-1"
              />
            </div>
            <div>
              <label for="zipcode" class="text-xs text-vw-lightgray">{{
                $t("Postcode")
              }}</label>
              <input
                v-model="client.zip"
                type="text"
                name="zipcode"
                id="zipcode"
                class="w-full border-0 border-b-2 border-vw-modalgray text-vw-green focus:border-vw-green p-1"
              />
            </div>
          </div>
          <div class="flex justify-start md:justify-end">
            <button
              @click="addClient"
              type="submit"
              class="bg-vw-green text-white px-8 py-3 rounded-lg futura-bold w-full md:w-fit"
            >
              {{ $t("Aanvragen") }}
              <i class="fa-solid fa-plus text-white ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseModal from "./BaseModal.vue";
import {
  MODAL_ADD_CLIENT_REQUEST,
  MODAL_DEREGISTER_DEPOT,
  CLOSE_ADD_CUSTOMER_MODAL,
  LOAD_CLIENTS,
} from "@/constants";

export default {
  name: "AddClient",
  components: { BaseModal },
  data: () => ({
    client: {},
    status: "success",
    size: "medium",
  }),
  mounted() {
    // Emit an event when the child component is mounted
    this.$emit("openDepotModal", {
      data: this.$store.getters.modalData,
      modal: MODAL_DEREGISTER_DEPOT,
    });
  },
  computed: {
    bindClasses() {
      console.log(this.status);
      return {
        "border border-vw-green border-4 flex shadow-md rounded-xl text-sm flex flex-col p-2 md:px-4 md:pt-3":
          this.status == "success" || typeof this.status === "undefined",
        "border border-vw-red border-4 flex shadow-md rounded-xl text-sm flex flex-col p-2 md:px-4 md:pt-3":
          this.status == "error",
        // 'w-10/12 sm:w-1/2 lg:w-1/3 xl:w-1/5': typeof this.size === 'undefined',
        "w-10/12 sm:w-1/2 lg:w-1/3 xl:w-1/5":
          this.size == "small" || typeof this.size === "undefined",
        "w-10/12 sm:w-1/2 lg:w-1/3 2xl:w-1/4": this.size == "account",
        "w-10/12 md:w-1/2 lg:w-1/3": this.size == "medium",
        "overflow-y-auto": this.type == "stock",
      };
    },
    outSideClick() {
      return () => this.$store.dispatch(CLOSE_ADD_CUSTOMER_MODAL);
    },
  },
  methods: {
    addClient() {
      this.$store
        .dispatch(MODAL_ADD_CLIENT_REQUEST, {
          user_id: this.$store.state.userStore.user.id,
          company_id: this.$route.params.id,
          ...this.client,
          stock: this.$store.getters.modalData,
        })
        .then(() => {
          if (this.$route.path == "/clients") {
            this.$store.dispatch(LOAD_CLIENTS);
          }
        });
    },
    closeModal() {
      this.$store.dispatch(CLOSE_ADD_CUSTOMER_MODAL);
    },
  },
};
</script>
